import React from 'react';

import CenterContent from '@/CenterContent';

import { Container, DocumentContainer } from './Document.style';

type Props = {
  children: React.ReactNode;
};

const Document = ({ children }: Props) => (
  <Container>
    <CenterContent>
      <DocumentContainer>{children}</DocumentContainer>
    </CenterContent>
  </Container>
);

export default React.memo(Document);
