/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from '#styled-system';

export const ButtonContainer = styled.Pressable<
  ColorProps & LayoutProps & SpaceProps
>`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 2px;
  ${color};
  ${layout};
  ${space};
`;

export const IconContainer = styled.View<LayoutProps>`
  display: inline-flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  ${layout};
`;

export const LabelContainer = styled.View`
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-color: rgba(255, 255, 255, 0.3);
  border-start-width: 1px;
`;

export const Label = styled(Text)`
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

export default null;
