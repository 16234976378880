export enum TagType {
  General = 'General',
  UserTagUser = 'UserTagUser',
}

/**
 * Described how two tags appeared together.
 */
export enum TagRelationship {
  SavedFilter = 'SavedFilter',
  TaggedTag = 'TaggedTag',
  Translation = 'Translation',
}

export default null;
