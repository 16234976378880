/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from 'expo-next-react-navigation';
import React from 'react';

import Tag from '@/Tag';
import { LinkConfigs, TagItem } from '#interfaces';
import { getObjectJoinString } from '#utils';

import { TagsContainer } from './TagCloud.style';

type Props<T extends TagItem> = {
  tags: T[];
  getLinkProps?: (input: T) => LinkConfigs;
  onTagPress?: (input: T) => void;
};

/**
 * Render tag cloud.
 *
 * You can pass `onPress` function to make it take the action on press or `getLinkProps` function
 * to create `Link` props from the tag item and wrap `Tag` component with a `Link` component.
 */
const TagCloud = ({
  tags,
  getLinkProps,
  onTagPress,
}: Props<TagItem>): React.ReactElement => {
  const handlePress = React.useCallback(
    (tag: TagItem) => () => onTagPress?.(tag),
    [onTagPress],
  );

  return (
    <TagsContainer>
      {tags.map((tag) => {
        if (!tag) return null;

        const tagComponent = (
          <Tag key={tag.id} onPress={handlePress(tag)}>
            {tag.label}
          </Tag>
        );

        return getLinkProps ? (
          <Link key={tag.id} {...getLinkProps(tag)} isText={false}>
            {tagComponent}
          </Link>
        ) : (
          tagComponent
        );
      })}
    </TagsContainer>
  );
};

export default React.memo(
  TagCloud,
  (prev, next) =>
    getObjectJoinString(prev.tags, ['id']) ===
    getObjectJoinString(next.tags, ['id']),
);
