export enum ChannelType {
  Temporary = 'Temporary',
  Persistent = 'Persistent',
}

export enum ChannelContentType {
  Post = 'Post',
  Notification = 'Notification',
}

export enum ChannelStatKey {
  PostCount = 'PostCount',
  AvgTimePerTenContents = 'AvgTimePerTenContents',
  SubscriberCount = 'SubscriberCount',
}

export enum FilterObjectType {
  Tag = 'Tag',
  User = 'User',
}

export default null;
