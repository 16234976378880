import React, { CSSProperties } from 'react';
import { Dimensions } from 'react-native';
import { Context } from 'react-responsive';

import { DeviceType } from '#enum';
import useBreakpoints, { BreakpointContext } from '#hooks/useBreakpoints';

export type ResponsiveContext = [
  BreakpointContext[0],
  BreakpointContext[1] & { sidebarType: 'sidebar' | 'drawer' },
];

const defaultDeviceWidth = Object.freeze({
  [DeviceType.Mobile]: 480,
  [DeviceType.Tablet]: 767,
  [DeviceType.Desktop]: 1080,
});

export const ResponsiveContext = React.createContext<ResponsiveContext>([
  <K extends keyof CSSProperties>(
    values: CSSProperties[K][],
  ): CSSProperties[K] => values[0],
  {} as ResponsiveContext[1],
]);

/**
 * Provided the responsive ability to `react` and `react-native` application.
 *
 * In app, you can use the `useResponsive` hook to get both the value picker function
 * or current `mediaQuery` values.
 *
 * You can also use the `useMediaQuery` hook directly to get any custom values too.
 */
const ResponsiveProvider = ({
  children,
  deviceType,
}: {
  children: React.ReactElement;
  deviceType?: DeviceType;
}): React.ReactElement => {
  const deviceWidth = React.useRef<number | undefined>(
    deviceType ? defaultDeviceWidth[deviceType] : undefined,
  );
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const deviceProps =
    isFirstLoad && deviceWidth.current
      ? { width: deviceWidth.current }
      : undefined;

  React.useEffect(() => {
    if (isFirstLoad) {
      const { width } = Dimensions.get('window');
      deviceWidth.current = width;
      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  const responsiveTools = useBreakpoints(deviceProps);
  const sidebarType = responsiveTools[1].xl ? 'sidebar' : 'drawer';

  const contextValue = React.useMemo(
    () =>
      [
        responsiveTools[0],
        { ...responsiveTools[1], sidebarType },
      ] as ResponsiveContext,
    [responsiveTools, sidebarType],
  );

  return (
    <Context.Provider value={{ ...deviceProps }}>
      <ResponsiveContext.Provider value={contextValue}>
        {children}
      </ResponsiveContext.Provider>
    </Context.Provider>
  );
};

export default ResponsiveProvider;
