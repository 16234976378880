/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type UpdateByIdPostInput = {
    status?: UpdateByIdDocumentStatusInput | null;
    version?: string | null;
    type?: string | null;
    title?: string | null;
    content?: string | null;
    previewContent?: Array<string | null> | null;
    plainContent?: string | null;
    featureCommentIds?: Array<string | null> | null;
    vote?: UpdateByIdPostVoteInput | null;
    count?: UpdateByIdPostCountInput | null;
    tagCollections?: UpdateByIdPostTagCollectionsInput | null;
    featuredScore?: number | null;
    wilsonScore?: number | null;
    hotScore?: number | null;
    scoreInfo?: UpdateByIdPostScoreInfoInput | null;
    createdDate?: string | null;
    createdByUserId?: string | null;
    lastModifiedDate?: string | null;
    lastModifiedByUserId?: string | null;
    settings?: Array<UpdateByIdKeyValueInput | null> | null;
    resource?: Array<UpdateByIdKeyValueInput | null> | null;
    meta?: Array<UpdateByIdKeyValueInput | null> | null;
};
export type UpdateByIdDocumentStatusInput = {
    value?: EnumDocumentStatusValue | null;
    reason?: string | null;
    timestamp?: string | null;
};
export type UpdateByIdPostVoteInput = {
    upvotes?: number | null;
    downvotes?: number | null;
};
export type UpdateByIdPostCountInput = {
    shared?: number | null;
    tagged?: number | null;
    taggedTags?: number | null;
    totalComments?: number | null;
    activeRootComments?: number | null;
};
export type UpdateByIdPostTagCollectionsInput = {
    taggedTags?: Array<UpdateByIdTagItemInput | null> | null;
};
export type UpdateByIdTagItemInput = {
    tagId?: string | null;
    value?: number | null;
    date?: string | null;
};
export type UpdateByIdPostScoreInfoInput = {
    lastUpdatedDate?: string | null;
};
export type UpdateByIdKeyValueInput = {
    key?: string | null;
    value?: unknown | null;
};
export type UpdatePostMutationVariables = {
    postId: string;
    newPost: UpdateByIdPostInput;
};
export type UpdatePostMutationResponse = {
    readonly postUpdateById: {
        readonly record: {
            readonly id: string;
            readonly version: string | null;
            readonly title: string;
            readonly previewContent: ReadonlyArray<string | null> | null;
            readonly content: string;
            readonly lastModifiedDate: string | null;
            readonly settings: ReadonlyArray<{
                readonly key: string;
                readonly value: unknown | null;
            } | null> | null;
            readonly meta: ReadonlyArray<{
                readonly key: string;
                readonly value: unknown | null;
            } | null> | null;
        } | null;
    } | null;
};
export type UpdatePostMutation = {
    readonly response: UpdatePostMutationResponse;
    readonly variables: UpdatePostMutationVariables;
};



/*
mutation UpdatePostMutation(
  $postId: MongoID!
  $newPost: UpdateByIdPostInput!
) {
  postUpdateById(_id: $postId, record: $newPost) {
    record {
      id
      version
      title
      previewContent
      content
      lastModifiedDate
      settings {
        key
        value
      }
      meta {
        key
        value
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newPost"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "postId"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "newPost"
      }
    ],
    "concreteType": "UpdateByIdPostPayload",
    "kind": "LinkedField",
    "name": "postUpdateById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "previewContent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModifiedDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "settings",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "meta",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePostMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdatePostMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6afa21f17cd60445139b29daa31da98e",
    "id": null,
    "metadata": {},
    "name": "UpdatePostMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePostMutation(\n  $postId: MongoID!\n  $newPost: UpdateByIdPostInput!\n) {\n  postUpdateById(_id: $postId, record: $newPost) {\n    record {\n      id\n      version\n      title\n      previewContent\n      content\n      lastModifiedDate\n      settings {\n        key\n        value\n      }\n      meta {\n        key\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b60d6d10fb2a3139915216d9b4035118';
export default node;
