export enum BlockType {
  // This is used to represent a normal text block (paragraph).
  Unstyled = 'unstyled',
  HeaderOne = 'header-one',
  HeaderTwo = 'header-two',
  HeaderThree = 'header-three',
  HeaderFour = 'header-four',
  HeaderFive = 'header-five',
  HeaderSix = 'header-six',
  UnorderedListItem = 'unordered-list-item',
  OrderedListItem = 'ordered-list-item',
  Blockquote = 'blockquote',
  Code = 'code-block',
  // This represents a "custom" block, not for rich text, with arbitrary content.
  Atomic = 'atomic',
}

export enum EntityType {
  Link = 'LINK',
  Image = 'IMAGE',
  HorizontalRule = 'HORIZONTAL_RULE',
}

// See https://github.com/facebook/draft-js/blob/master/src/model/immutable/DefaultDraftInlineStyle.js
export enum InlineStyle {
  Bold = 'BOLD',
  Italic = 'ITALIC',
  Code = 'CODE',
  Underline = 'UNDERLINE',
  Strikethrough = 'STRIKETHROUGH',
  Mark = 'MARK',
  Quotation = 'QUOTATION',
  Small = 'SMALL',
  Sample = 'SAMPLE',
  Insert = 'INSERT',
  Delete = 'DELETE',
  Keyboard = 'KEYBOARD',
  Superscript = 'SUPERSCRIPT',
  Subscript = 'SUBSCRIPT',
}

export default null;
