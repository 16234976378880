import { compose, system } from '@styled-system/core'; // eslint-disable-line

import { Config } from '#interfaces/StyledSystem';

const configs: { [key: string]: Config } = {};

configs.borderStartConfig = {
  borderStartWidth: {
    property: 'borderStartWidth',
    scale: 'borderWidths',
  },
  borderStartColor: {
    property: 'borderStartColor',
    scale: 'colors',
  },
};

configs.borderEndConfig = {
  borderEndWidth: {
    property: 'borderEndWidth',
    scale: 'borderWidths',
  },
  borderEndColor: {
    property: 'borderEndColor',
    scale: 'colors',
  },
};

configs.borderRadiusConfig = {
  borderTopStartRadius: {
    property: 'borderTopStartRadius',
    scale: 'radii',
  },
  borderTopEndRadius: {
    property: 'borderTopEndRadius',
    scale: 'radii',
  },
  borderBottomStartRadius: {
    property: 'borderBottomStartRadius',
    scale: 'radii',
  },
  borderBottomEndRadius: {
    property: 'borderBottomEndRadius',
    scale: 'radii',
  },
};

export const borderStart = system(configs.borderStartConfig);
export const borderEnd = system(configs.borderEndConfig);
export const borderRadius = system(configs.borderRadiusConfig);
export const border = compose(borderStart, borderEnd, borderRadius);
export default border;
