/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type withIntlQueryVariables = {
    viewerId?: string | null;
    fetchViewer: boolean;
};
export type withIntlQueryResponse = {
    readonly viewer?: {
        readonly details: {
            readonly locale: string | null;
        } | null;
    } | null;
};
export type withIntlQuery = {
    readonly response: withIntlQueryResponse;
    readonly variables: withIntlQueryVariables;
};



/*
query withIntlQuery(
  $viewerId: MongoID
  $fetchViewer: Boolean!
) {
  viewer(_id: $viewerId) @include(if: $fetchViewer) {
    details {
      locale
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchViewer"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewerId"
},
v2 = [
  {
    "condition": "fetchViewer",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "_id",
            "variableName": "viewerId"
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDetails",
            "kind": "LinkedField",
            "name": "details",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locale",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "withIntlQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "withIntlQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4fc99010ac792f45ea5bc1859f0da404",
    "id": null,
    "metadata": {},
    "name": "withIntlQuery",
    "operationKind": "query",
    "text": "query withIntlQuery(\n  $viewerId: MongoID\n  $fetchViewer: Boolean!\n) {\n  viewer(_id: $viewerId) @include(if: $fetchViewer) {\n    details {\n      locale\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '79e73bddf6a77f6ae39a679107f39e4e';
export default node;
