/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card } from 'react-native-paper';
import styled from 'styled-components/native';

export const Container = styled.View`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

export const ExtraContainer = styled(Card)`
  position: absolute;
  top: 36px;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  min-width: 38px;
  min-height: 38px;
  border-radius: 4px;
`;

export default null;
