import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { IInformedConsent } from '#database/models';
import { CBMutationConfig } from '#interfaces';
import { UpdateUserDetailsMutation } from '~/UpdateUserDetailsMutation.graphql';

const mutation = graphql`
  mutation UpdateUserDetailsMutation(
    $userDetailsId: MongoID!
    $locale: String
    $timezone: String
    $appLanguage: String
    $preferedContentLanguages: [String]
    $acceptedInformedConsents: [UpdateByIdInformedConsentInput]
  ) {
    userDetailsUpdateById(
      _id: $userDetailsId
      record: {
        locale: $locale
        timezone: $timezone
        appLanguage: $appLanguage
        preferedContentLanguages: $preferedContentLanguages
        acceptedInformedConsents: $acceptedInformedConsents
      }
    ) {
      record {
        id
        locale
        timezone
        appLanguage
        preferedContentLanguages
        acceptedInformedConsents {
          type
          version
        }
      }
    }
  }
`;

const updateUserDetails = (
  environment: Environment,
  args: {
    userDetailsId: string;
    timezone?: string;
    locale?: string;
    appLanguage?: string;
    preferedContentLanguages?: string[];
    acceptedInformedConsents?: IInformedConsent[];
  },
  config?: CBMutationConfig<UpdateUserDetailsMutation>,
): Disposable | null => {
  const variables = args;

  return commitMutation<UpdateUserDetailsMutation>(environment, {
    ...config,
    mutation,
    variables,
  });
};

export default updateUserDetails;
