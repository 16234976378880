import React from 'react';
import { Pressable } from 'react-native';

import MenuItemIcon from '@/MenuItemIcon';

import {
  Container,
  IconContainer,
  LabelContainer,
  LabelText,
} from './MenuItem.style';

type Props = {
  children: React.ReactNode;
  isText?: boolean;
  icon?: React.ComponentProps<typeof MenuItemIcon>['name'] | React.ReactElement;
  onPress?: () => void;
  onLongPress?: () => void;
};

const MenuItem = ({
  children,
  isText = true,
  icon,
  onPress,
  onLongPress,
}: Props): React.ReactElement => {
  const menuIcon =
    typeof icon === 'string' ? <MenuItemIcon name={icon} /> : icon;
  const label =
    isText || typeof children === 'string' ? (
      <LabelText>{children}</LabelText>
    ) : (
      children
    );
  const component = (
    <Container>
      {menuIcon && <IconContainer>{menuIcon}</IconContainer>}
      <LabelContainer>{label}</LabelContainer>
    </Container>
  );

  return onPress || onLongPress ? (
    <Pressable onPress={onPress} onLongPress={onLongPress}>
      {component}
    </Pressable>
  ) : (
    component
  );
};

export default React.memo(MenuItem);
