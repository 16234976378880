/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AddRemovePostTagsMutationVariables = {
    userId: string;
    postId: string;
    allTags: Array<string>;
    addedTags: Array<string>;
    removedTags: Array<string>;
    tagRelationship: string;
    currentDate?: string | null;
    taggedAction?: string | null;
    updateRelatedTags?: boolean | null;
    postAddTaggedTags?: boolean | null;
    postUpdateTaggedTags?: boolean | null;
};
export type AddRemovePostTagsMutationResponse = {
    readonly taggedPostAddAndRemoveTags: {
        readonly id: string;
        readonly userId: string;
        readonly postId: string;
        readonly tags: ReadonlyArray<{
            readonly id: string;
            readonly slug: string;
            readonly label: string;
        } | null>;
    } | null;
    readonly postAddTaggedTags?: {
        readonly id: string;
        readonly tagCollections: {
            readonly taggedTags: ReadonlyArray<{
                readonly tagId: string | null;
            } | null> | null;
        } | null;
    };
    readonly postUpdateTaggedTags?: {
        readonly id: string;
        readonly tagCollections: {
            readonly taggedTags: ReadonlyArray<{
                readonly tagId: string | null;
            } | null> | null;
        } | null;
    };
    readonly updateRelatedTags?: ReadonlyArray<{
        readonly id: string;
    }> | null;
    readonly userDetailsUpdateTaggedDate: {
        readonly _id: string;
        readonly lastTaggedDate: string | null;
    } | null;
};
export type AddRemovePostTagsMutation = {
    readonly response: AddRemovePostTagsMutationResponse;
    readonly variables: AddRemovePostTagsMutationVariables;
};



/*
mutation AddRemovePostTagsMutation(
  $userId: MongoID!
  $postId: MongoID!
  $allTags: [MongoID!]!
  $addedTags: [MongoID!]!
  $removedTags: [MongoID!]!
  $tagRelationship: String!
  $currentDate: Date
  $taggedAction: String
  $updateRelatedTags: Boolean = false
  $postAddTaggedTags: Boolean = false
  $postUpdateTaggedTags: Boolean = false
) {
  taggedPostAddAndRemoveTags(userId: $userId, postId: $postId, addedTags: $addedTags, removedTags: $removedTags) {
    id
    userId
    postId
    tags {
      id
      slug
      label
    }
  }
  postAddTaggedTags(_id: $postId, tags: $addedTags) @include(if: $postAddTaggedTags) {
    id
    tagCollections {
      taggedTags {
        tagId
      }
    }
  }
  postUpdateTaggedTags(_id: $postId, addedTags: $addedTags, removedTags: $removedTags, taggedAction: $taggedAction) @include(if: $postUpdateTaggedTags) {
    id
    tagCollections {
      taggedTags {
        tagId
      }
    }
  }
  updateRelatedTags(allTags: $allTags, addedTags: $addedTags, removedTags: $removedTags, relationship: $tagRelationship) @include(if: $updateRelatedTags) {
    id
  }
  userDetailsUpdateTaggedDate(userId: $userId, date: $currentDate) {
    _id
    lastTaggedDate
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addedTags"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allTags"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentDate"
},
v3 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "postAddTaggedTags"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postId"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "postUpdateTaggedTags"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removedTags"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagRelationship"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taggedAction"
},
v9 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "updateRelatedTags"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v11 = {
  "kind": "Variable",
  "name": "addedTags",
  "variableName": "addedTags"
},
v12 = {
  "kind": "Variable",
  "name": "removedTags",
  "variableName": "removedTags"
},
v13 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "kind": "Variable",
  "name": "_id",
  "variableName": "postId"
},
v16 = [
  (v14/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "PostTagCollections",
    "kind": "LinkedField",
    "name": "tagCollections",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagItem",
        "kind": "LinkedField",
        "name": "taggedTags",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tagId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v17 = [
  {
    "alias": null,
    "args": [
      (v11/*: any*/),
      {
        "kind": "Variable",
        "name": "postId",
        "variableName": "postId"
      },
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "concreteType": "TaggedPost",
    "kind": "LinkedField",
    "name": "taggedPostAddAndRemoveTags",
    "plural": false,
    "selections": [
      (v14/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "currentDate"
      },
      (v13/*: any*/)
    ],
    "concreteType": "UserDetails",
    "kind": "LinkedField",
    "name": "userDetailsUpdateTaggedDate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastTaggedDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "condition": "postAddTaggedTags",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          (v15/*: any*/),
          {
            "kind": "Variable",
            "name": "tags",
            "variableName": "addedTags"
          }
        ],
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "postAddTaggedTags",
        "plural": false,
        "selections": (v16/*: any*/),
        "storageKey": null
      }
    ]
  },
  {
    "condition": "postUpdateTaggedTags",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          (v15/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "Variable",
            "name": "taggedAction",
            "variableName": "taggedAction"
          }
        ],
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "postUpdateTaggedTags",
        "plural": false,
        "selections": (v16/*: any*/),
        "storageKey": null
      }
    ]
  },
  {
    "condition": "updateRelatedTags",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          (v11/*: any*/),
          {
            "kind": "Variable",
            "name": "allTags",
            "variableName": "allTags"
          },
          {
            "kind": "Variable",
            "name": "relationship",
            "variableName": "tagRelationship"
          },
          (v12/*: any*/)
        ],
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "updateRelatedTags",
        "plural": true,
        "selections": [
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddRemovePostTagsMutation",
    "selections": (v17/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v10/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddRemovePostTagsMutation",
    "selections": (v17/*: any*/)
  },
  "params": {
    "cacheID": "8641042def955109e974d844168cfd4f",
    "id": null,
    "metadata": {},
    "name": "AddRemovePostTagsMutation",
    "operationKind": "mutation",
    "text": "mutation AddRemovePostTagsMutation(\n  $userId: MongoID!\n  $postId: MongoID!\n  $allTags: [MongoID!]!\n  $addedTags: [MongoID!]!\n  $removedTags: [MongoID!]!\n  $tagRelationship: String!\n  $currentDate: Date\n  $taggedAction: String\n  $updateRelatedTags: Boolean = false\n  $postAddTaggedTags: Boolean = false\n  $postUpdateTaggedTags: Boolean = false\n) {\n  taggedPostAddAndRemoveTags(userId: $userId, postId: $postId, addedTags: $addedTags, removedTags: $removedTags) {\n    id\n    userId\n    postId\n    tags {\n      id\n      slug\n      label\n    }\n  }\n  postAddTaggedTags(_id: $postId, tags: $addedTags) @include(if: $postAddTaggedTags) {\n    id\n    tagCollections {\n      taggedTags {\n        tagId\n      }\n    }\n  }\n  postUpdateTaggedTags(_id: $postId, addedTags: $addedTags, removedTags: $removedTags, taggedAction: $taggedAction) @include(if: $postUpdateTaggedTags) {\n    id\n    tagCollections {\n      taggedTags {\n        tagId\n      }\n    }\n  }\n  updateRelatedTags(allTags: $allTags, addedTags: $addedTags, removedTags: $removedTags, relationship: $tagRelationship) @include(if: $updateRelatedTags) {\n    id\n  }\n  userDetailsUpdateTaggedDate(userId: $userId, date: $currentDate) {\n    _id\n    lastTaggedDate\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a619a1b2246643417722666182e2b0c5';
export default node;
