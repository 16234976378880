import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentBlock,
  RawDraftEntity,
} from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';

import uploadImage from '#aws/s3/uploadImage';
import { IKeyValue } from '#database/models';
import { BlockType, ImageSize, MetaContentMedia, MetaKey } from '#enum';
import { compactKVArray, isBase64Image, isJson, isUrl } from '#utils';

const isProduction = process.env.NODE_ENV === 'production';

export const getPlainText = (content?: EditorState): string =>
  content
    ?.getCurrentContent()
    .getPlainText()
    .replace(/[\s\n]+/gm, ' ')
    .trim() ?? '';

export const convertEditorStateToJSON = (editorState: EditorState): string =>
  JSON.stringify(convertToRaw(editorState.getCurrentContent()));

export const convertEditorStateToJSONWithAssets = async (
  editorState: EditorState,
  options?: { removeEmptyBlocks?: boolean; imageSizes?: ImageSize[] },
): Promise<[string, IKeyValue[] | undefined, IKeyValue[] | undefined]> => {
  const o = { removeEmptyBlocks: true, ...options };
  const json = convertEditorStateToJSON(editorState);
  const plainContent = getPlainText(editorState);
  if (!o?.removeEmptyBlocks) return [json, undefined, undefined];

  const object = JSON.parse(json);
  const filteredBlocks = object.blocks.filter((block: RawDraftContentBlock) =>
    typeof block === 'object' && block !== null
      ? block.text.trim() || block.type !== BlockType.Unstyled
      : true,
  );

  const { entityMap } = object;
  const base64Images = Object.entries(entityMap as RawDraftEntity).reduce(
    (res, [key, entity]) => {
      if (
        entity.type === 'image' &&
        entity.data.src &&
        (isBase64Image(entity.data.src) || isUrl(entity.data.src))
      ) {
        const imageUri = entity.data.src;
        return [
          ...res,
          {
            key,
            uri: imageUri,
          },
        ];
      }
      return res;
    },
    [] as { key: string; uri: string }[],
  );

  const uploadedImageUrls = await Promise.all(
    base64Images.map((img) => {
      if (isBase64Image(img.uri))
        return uploadImage(img.uri, o.imageSizes ?? [ImageSize.Original], {
          bucket: isProduction ? 'userAsset' : 'dev',
          prefix: 'upload/',
        }).then((uri) => ({
          key: img.key,
          uri,
        }));
      if (isUrl(img.uri))
        return {
          key: img.key,
          uri: [img.uri],
        };
      return undefined;
    }),
  );

  uploadedImageUrls.forEach((img) => {
    if (img?.uri?.length) entityMap[img.key].data.src = img.uri?.[0];
  });

  const resource = [
    ...(uploadedImageUrls.length
      ? [
          {
            key: 'awsS3',
            value: {
              bucket: isProduction ? 'userAsset' : 'dev',
              objectKeys: uploadedImageUrls.map((img) => {
                const url = img?.uri?.[0];
                const objectKey = url?.replace(
                  /^.*cloudfront\.net\/([^?]+)(\?.*)?/,
                  '$1',
                );
                return url !== objectKey ? objectKey : undefined;
              }),
            },
          },
        ]
      : []),
  ];

  // TODO: support content language detection
  const metaAttr = compactKVArray([
    {
      key: MetaKey.ContentMedia,
      value: [
        uploadedImageUrls.length ? MetaContentMedia.Image : undefined,
        plainContent ? MetaContentMedia.Text : undefined,
      ],
    },
    { key: MetaKey.ContentLanguages, value: '' },
  ]);

  return [
    JSON.stringify({ blocks: filteredBlocks, entityMap }),
    metaAttr,
    resource,
  ];
};

export const convertStringToEditorState = (
  string: unknown,
  decorator?: CompositeDecorator,
): EditorState | undefined => {
  if (typeof string === 'string') {
    const rawData = markdownToDraft(string);
    const contentState = convertFromRaw(rawData);
    return EditorState.createWithContent(contentState, decorator);
  }

  return undefined;
};

export const convertJSONToEditorState = (
  json?: unknown,
  decorator?: CompositeDecorator,
): EditorState | undefined => {
  const parsedJson = isJson(json, true);

  if (parsedJson)
    return EditorState.createWithContent(convertFromRaw(parsedJson), decorator);

  return undefined;
};

export const createEditorState = (
  content?: unknown,
  decorator?: CompositeDecorator,
): EditorState => {
  if (!content) return EditorState.createEmpty(decorator);

  const jsonDraft = convertJSONToEditorState(content, decorator);
  if (jsonDraft) return jsonDraft;

  const stringDraft = convertStringToEditorState(content, decorator);
  if (stringDraft) return stringDraft;

  return EditorState.createEmpty(decorator);
};

export default { convertEditorStateToJSON, convertJSONToEditorState };
