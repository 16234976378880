/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

import { AvailableLocale } from '#interfaces/Locales';

export type Context = {
  viewerContext?: FBT.IntlViewerContext;
  isRTL?: boolean;
  locale?: AvailableLocale;
  gender?: FBT.IntlVariations;
  setLocale: (locale?: AvailableLocale) => void;
  setGender: (gender?: FBT.IntlVariations) => void;
};

const LocaleContext = createContext<Context>({
  setLocale: (): void => {},
  setGender: (): void => {},
});

export default LocaleContext;
