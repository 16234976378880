import { Button } from 'react-native-paper';
import styled from 'styled-components/native';

import Text from '@/Text';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from '#styled-system';

export const PaperButton = styled(Button)<
  ColorProps & LayoutProps & SpaceProps
>`
  ${layout};
  ${space};
  ${color};
`;

export const Label = styled(Text)<LayoutProps & SpaceProps>`
  letter-spacing: 0.5px;
  ${layout};
  ${space};
`;

export default null;
