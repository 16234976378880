/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { border, BorderProps, color, ColorProps } from '#styled-system';

export const Button = styled.Pressable<ColorProps & BorderProps>`
  margin-end: 6px;
  padding: 6px 10px 4px;
  border: solid 1px ${(props: any) => props.theme.colors.blackOpac10};
  border-radius: 1000px;
  ${color};
  ${border};
`;

export const Label = styled(Text)<ColorProps>`
  color: ${(props: any) => props.theme.colors.text2};
  font-weight: bold;
  font-size: 12px;
  ${color};
`;
