import React from 'react';

import TaggedTagsBox from '@/TaggedTagsBox';
import { TagItem } from '#interfaces';
import { compareTags, createTagLabel, createTagSlug } from '#utils';

export type TaggedTags = TagItem[] | null | undefined;

export type ControlledTaggedTags = {
  initialTags?: TaggedTags;
  selectedTags?: TaggedTags;
};

type Props = {
  initSelectedTags?: TaggedTags;
  canCreateNewTag?: boolean;
  onTagChange?: (args: ControlledTaggedTags) => void;
} & React.ComponentProps<typeof TaggedTagsBox>;

/**
 * This component will render initSelectedTags first then handle tag changes in
 * the component state.
 */
const ControlledTaggedTagsBox = ({
  initSelectedTags,
  onTagChange,
  canCreateNewTag,
  ...props
}: Props): React.ReactElement => {
  const callOnTagChange = React.useRef(false);
  const initialSelectedTags = React.useRef<TaggedTags>();
  const [selectedTags, setSelectedTags] = React.useState<TaggedTags>();

  const handleNewTagPress = React.useCallback((label: string) => {
    callOnTagChange.current = true;
    const slug = createTagSlug(label);
    setSelectedTags((prev) => [
      ...(prev ?? []),
      { id: slug, label: createTagLabel(label), slug },
    ]);
  }, []);

  const handleSelectedTagPress = React.useCallback((tag: TagItem) => {
    callOnTagChange.current = true;
    setSelectedTags((prev) => prev?.filter((t) => tag?.id !== t?.id));
  }, []);

  const handleSuggestedTagPress = React.useCallback(
    (tag: TagItem) => {
      callOnTagChange.current = true;
      if (tag)
        setSelectedTags((prev) =>
          !selectedTags ||
          selectedTags?.every((t) => t?.id !== tag?.id && t?.slug !== tag?.slug)
            ? [...(prev ?? []), tag]
            : prev,
        );
    },
    [selectedTags],
  );

  React.useEffect(() => {
    if (callOnTagChange.current) {
      onTagChange?.({
        initialTags: initialSelectedTags.current,
        selectedTags,
      });
      callOnTagChange.current = false;
    }
  }, [onTagChange, selectedTags]);

  React.useEffect(() => {
    // if `initSelectedTags` props change, update the `ref` and `state` values
    if (!compareTags(initSelectedTags, initialSelectedTags.current)) {
      initialSelectedTags.current = initSelectedTags;
      setSelectedTags(initSelectedTags);
    }

    return () => {
      initialSelectedTags.current = initSelectedTags;
      setSelectedTags(initSelectedTags);
    };
  }, [initSelectedTags]);

  return (
    <TaggedTagsBox
      selectedTags={selectedTags ?? []}
      onNewTagPress={canCreateNewTag ? handleNewTagPress : undefined}
      onSelectedTagPress={handleSelectedTagPress}
      onSuggestedTagPress={handleSuggestedTagPress}
      {...props}
    />
  );
};

export default React.memo(
  ControlledTaggedTagsBox,
  (prev, next) =>
    compareTags(prev.initSelectedTags, next.initSelectedTags) &&
    compareTags(prev.suggestedTags, next.suggestedTags) &&
    compareTags(prev.searchableItems, next.searchableItems) &&
    prev.searchButtonComponent === next.searchButtonComponent &&
    prev.selectedTagsFooter === next.selectedTagsFooter,
);
