/* stylelint-disable selector-max-type */
import React from 'react';
import { Platform, UIManager } from 'react-native';
import { graphql, useFragment } from 'react-relay';

import FeedbackButton from '@/FeedbackButton';
import { AppStateProvider } from '#hooks/useAppState';
import { CommentGlobalProvider } from '#hooks/useCommentGlobal';
import { Root_viewer$key } from '~/Root_viewer.graphql';

import AuthProvider from './AuthProvider';
import DrawerProvider from './DrawerProvider';
import ModalProvider from './ModalProvider';
import PlatformProvider from './PlatformProvider';
import ScrollProvider from './ScrollProvider';

const viewerFragment = graphql`
  fragment Root_viewer on User {
    ...AuthProvider_viewer
    ...FeedbackButton_viewer
  }
`;

type Props = {
  children: React.ReactNode;
  viewer?: Root_viewer$key | null;
  screenName?: string;
  disableFeedback?: boolean;
};

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

/**
 * Provide all necessary layers.
 */
const Root = ({
  children,
  viewer,
  screenName,
  disableFeedback,
}: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer ?? null);

  return (
    <AppStateProvider>
      <ScrollProvider>
        <PlatformProvider>
          <AuthProvider viewer={viewerData}>
            <ModalProvider>
              <DrawerProvider>
                <CommentGlobalProvider>
                  {children}
                  {viewerData && !disableFeedback && (
                    <FeedbackButton
                      screenName={screenName}
                      viewer={viewerData}
                    />
                  )}
                </CommentGlobalProvider>
              </DrawerProvider>
            </ModalProvider>
          </AuthProvider>
        </PlatformProvider>
      </ScrollProvider>
    </AppStateProvider>
  );
};

export default Root;
