/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TagSearchQueryVariables = {
    text?: string | null;
};
export type TagSearchQueryResponse = {
    readonly searchTags: ReadonlyArray<{
        readonly id: string;
        readonly label: string;
        readonly slug: string;
    }>;
};
export type TagSearchQuery = {
    readonly response: TagSearchQueryResponse;
    readonly variables: TagSearchQueryVariables;
};



/*
query TagSearchQuery(
  $text: String
) {
  searchTags(text: $text) {
    id
    label
    slug
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      }
    ],
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "searchTags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7a70904adf5114e6930b96cc4e558b9",
    "id": null,
    "metadata": {},
    "name": "TagSearchQuery",
    "operationKind": "query",
    "text": "query TagSearchQuery(\n  $text: String\n) {\n  searchTags(text: $text) {\n    id\n    label\n    slug\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ea6e6257b25ac6d405d881932135c8ea';
export default node;
