import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import { PressableProps } from 'react-native';

import {
  ButtonContainer,
  IconContainer,
  Label,
  LabelContainer,
} from './SocialButton.style';

const socials: Record<
  'google' | 'facebook' | 'twitter',
  {
    color: string;
    icon: React.ComponentProps<typeof FontAwesome>['name'];
  }
> = {
  google: {
    color: '#DB4437',
    icon: 'google',
  },
  facebook: {
    color: '#4267B2',
    icon: 'facebook',
  },
  twitter: {
    color: '#1da1f2',
    icon: 'twitter',
  },
};

type Props = {
  name: keyof typeof socials;
  size?: number;
  iconSize?: number;
} & PressableProps;

const SocialButton = ({ name, size = 48, iconSize = 24, ...props }: Props) => {
  const social = socials[name];
  return social ? (
    <ButtonContainer bg={social.color} {...props}>
      <IconContainer width={size} height={size}>
        <FontAwesome name={social.icon} size={iconSize} color="white" />
      </IconContainer>
      <LabelContainer>
        <Label fontSize={iconSize * (5 / 6)}>{name}</Label>
      </LabelContainer>
    </ButtonContainer>
  ) : null;
};

export default React.memo(SocialButton);
