import React from 'react';
import isEqual from 'react-fast-compare';
import { Animated, Pressable } from 'react-native';

import useTheme from '#hooks/useTheme';
import { DrawerProps } from '#layers/DrawerProvider';

import { DrawerContainer, MainContainer, Mask } from './Drawer.style';

type Props = {
  children: React.ReactNode;
  isOpen?: boolean;
  disabledIsOpenState?: boolean;
} & Omit<DrawerProps, 'content'>;

/**
 * Sidebar in drawer style, for using with smaller screen size.
 */
const Drawer = ({
  children,
  width,
  height,
  maxWidth,
  maxHeight,
  drawerPosition,
  drawerStyles,
  opacity,
  isOpen,
  disabledIsOpenState,
  dismissable,
  onDismiss,
}: Props): React.ReactElement => {
  const isVertital = drawerPosition === 'top' || drawerPosition === 'bottom';
  const offset = isVertital ? height : width;
  const animDuration = 250; // ms

  const closed = React.useMemo(
    () => ({
      zIndex: -1,
      offsetAnim: (offset ?? 320) * -1, // px
      opacAnim: 1,
    }),
    [offset],
  );

  const opened = React.useMemo(
    () => ({
      zIndex: 100,
      offsetAnim: 0,
      opacAnim: 0,
    }),
    [],
  );

  const { colors } = useTheme();
  const isOpenedRef = React.useRef(false);
  const [isOpenState, setIsOpenState] = React.useState(isOpen);
  const zIndexAnim = React.useRef(new Animated.Value(closed.zIndex)).current;
  const opacAnim = React.useRef(new Animated.Value(closed.opacAnim)).current;
  const offsetAnim = React.useRef(new Animated.Value(closed.offsetAnim))
    .current;
  const isOpened = disabledIsOpenState ? isOpen : isOpenState;

  const backgroundColor = opacAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [
      opacity
        ? `rgba(0, 0, 0, ${opacity})`
        : colors?.backdrop ?? 'rgba(0, 0, 0, 0.6)',
      'rgba(0, 0, 0, 0)',
    ],
  });

  const s = React.useMemo(
    () => ({
      mainContainer: {
        zIndex: zIndexAnim,
        flex: '1 1 auto',
        flexDirection: isVertital ? 'column' : 'row',
      },
      pressable: { flexGrow: 1 },
      mask: { backgroundColor },
      drawerContainer: {
        [drawerPosition ?? 'start']: offsetAnim,
        width: width ?? '100%',
        height: height ?? '100%',
        maxWidth,
        maxHeight,
        ...drawerStyles,
      },
      drawerContainerContentContainer: { flex: 1 },
    }),
    [
      backgroundColor,
      drawerPosition,
      drawerStyles,
      height,
      isVertital,
      maxHeight,
      maxWidth,
      offsetAnim,
      width,
      zIndexAnim,
    ],
  );

  const handlePress = React.useCallback(() => {
    // Use `dismissable !== false` to allow `undefined` value act as `true`
    if (dismissable !== false) {
      if (!disabledIsOpenState) setIsOpenState(false);
      if (disabledIsOpenState) onDismiss?.();
    }
  }, [disabledIsOpenState, dismissable, onDismiss]);

  React.useEffect(() => {
    if (isOpened) {
      Animated.sequence([
        Animated.timing(zIndexAnim, {
          toValue: opened.zIndex,
          duration: 1,
          useNativeDriver: true,
        }),
        Animated.parallel([
          Animated.timing(offsetAnim, {
            toValue: opened.offsetAnim,
            duration: animDuration,
            useNativeDriver: true,
          }),
          Animated.timing(opacAnim, {
            toValue: opened.opacAnim,
            duration: animDuration,
            useNativeDriver: true,
          }),
        ]),
      ]).start(() => {
        isOpenedRef.current = true;
      });
    } else {
      Animated.sequence([
        Animated.parallel([
          Animated.timing(offsetAnim, {
            toValue: closed.offsetAnim,
            duration: animDuration,
            useNativeDriver: true,
          }),
          Animated.timing(opacAnim, {
            toValue: closed.opacAnim,
            duration: animDuration,
            useNativeDriver: true,
          }),
        ]),
        Animated.timing(zIndexAnim, {
          toValue: closed.zIndex,
          duration: 1,
          useNativeDriver: true,
        }),
      ]).start(() => {
        if (isOpenedRef.current) {
          isOpenedRef.current = false;
          onDismiss?.();
        }
      });
    }
  }, [
    closed.opacAnim,
    closed.offsetAnim,
    closed.zIndex,
    isOpened,
    onDismiss,
    opacAnim,
    opened.opacAnim,
    opened.offsetAnim,
    opened.zIndex,
    offsetAnim,
    zIndexAnim,
  ]);

  return (
    <MainContainer style={s.mainContainer}>
      <Pressable style={s.pressable} onPress={handlePress}>
        <Mask style={s.mask} />
      </Pressable>
      <DrawerContainer
        showsVerticalScrollIndicator={false}
        style={s.drawerContainer}
        contentContainerStyle={s.drawerContainerContentContainer}
      >
        {children}
      </DrawerContainer>
    </MainContainer>
  );
};

export default React.memo(
  Drawer,
  (prev, next) =>
    isEqual(prev.children, next.children) &&
    prev.width === next.width &&
    prev.height === next.height &&
    prev.maxWidth === next.maxWidth &&
    prev.maxHeight === next.maxHeight &&
    prev.drawerPosition === next.drawerPosition &&
    prev.isOpen === next.isOpen,
);
