import styled from 'styled-components/native';

import { layout, LayoutProps, space, SpaceProps } from '#styled-system';

const CenterContent = styled.View<LayoutProps & SpaceProps>`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  ${layout};
  ${space};
`;

export default CenterContent;
