import React from 'react';
import { TextProps } from 'react-native';

import { TypographyProps } from '#styled-system';

import { Header, HeaderContainer } from './HeaderText.style';

type Props = {
  children: string;
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  mt?: string | number;
  mb?: string | number;
} & TextProps &
  TypographyProps;

const HeaderText = ({ children, size, mt, mb, ...props }: Props) => {
  const [fontSize, marginTop, marginBottom] = React.useMemo(() => {
    switch (size) {
      case 1:
        return ['24px', '28px', '8px'];
      case 2:
        return ['22px', '26px', '7px'];
      case 3:
        return ['18px', '22px', '5px'];
      case 4:
        return ['16px', '20px', '4px'];
      case 5:
        return ['12px', '16px', '2px'];
      case 6:
        return ['10px', '14px', '1px'];
      default:
        return ['24px', '28px', '8px'];
    }
  }, [size]);

  return (
    <HeaderContainer mt={mt ?? marginTop} mb={mb ?? marginBottom}>
      <Header fontSize={fontSize} {...props}>
        {children}
      </Header>
    </HeaderContainer>
  );
};

export default React.memo(HeaderText);
