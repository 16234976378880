const getObjectJoinString = <T extends Record<string, unknown>>(
  arr?: (string | number | T)[] | null,
  keys?: (keyof T)[],
): string | undefined => {
  if (!arr?.length) return '';

  if (arr.every((item) => typeof item === 'string' || typeof item === 'number'))
    return arr.join(':::');

  return keys
    ?.map((key) => arr.map((item) => `${key}:${(item as T)?.[key]}`).join('::'))
    .join(':::');
};

export default getObjectJoinString;
