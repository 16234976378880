import { IntlViewerContext } from 'fbt';
import FbtHooks from 'fbt/lib/FbtHooks';
import FbtTranslations from 'fbt/lib/FbtTranslations';
import { I18nManager } from 'react-native';

import locales from '#i18n/locales';
import { AvailableLocale, TranslatedFbts } from '#interfaces';
import Fbt from '#storage/Fbt';

const setAppLocale = async (
  locale: AvailableLocale,
  translations?: TranslatedFbts | null,
  doReload?: boolean,
): Promise<void> => {
  const translationDict: FBT.Translations = {};

  Object.entries(translations ?? {}).forEach(([k, v]) => {
    if (v) translationDict[k] = v;
  });

  FbtTranslations.registerTranslations(translationDict);
  FbtHooks.register({
    getViewerContext: () => ({
      locale,
    }),
  });
  if (IntlViewerContext) IntlViewerContext.locale = locale;
  Fbt.setSelectedLocale(locale);
  I18nManager.forceRTL(!!translations && locales[locale].rtl);

  // TODO: update this outdated code
  // if (doReload) {
  //   const { Updates } = await import('expo');
  //   Updates.reloadFromCache();
  // }

  if (typeof document !== 'undefined') {
    document.documentElement.lang = locales[locale]?.bcp47;
  }
};

export default setAppLocale;
