/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const Title = styled(Text)`
  display: flex;
  justify-content: center;
  color: ${(props: any) => props.theme.colors.text};
  font-weight: 500;
  font-size: 22px;
`;

export const ContentContainer = styled.View`
  flex: 1 1 auto;
  margin: 20px 16px;
`;

export const FieldContainer = styled.View`
  margin-top: 12px;
`;

export const Field = styled.View`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
`;

export const FieldContent = styled(Text)`
  margin-start: 4px;
`;

export const ButtonContainer = styled.View`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default null;
