import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Link } from 'expo-next-react-navigation';
import fbt from 'fbt';
import { signOut } from 'next-auth/client';
import React from 'react';
import { Pressable } from 'react-native';
import { Appbar, IconButton } from 'react-native-paper';

import LabelSwitch from '@/LabelSwitch';
import MenuItem from '@/MenuItem';
import useFbt from '#hooks/useFbt';
import useHeaderIconSize from '#hooks/useHeaderIconSize';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';

import { Menu } from './HeaderViewerSettings.style';

// eslint-disable-next-line no-unused-expressions
fbt;

const HeaderViewerSettings = (): React.ReactElement => {
  useFbt();

  const [visible, setVisible] = React.useState(false);
  const { colors, isDarkTheme, toggleDarkTheme } = useTheme();
  const [, { xs }] = useResponsive();
  const iconSize = useHeaderIconSize();

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  const settingsButton = React.useMemo(
    () =>
      xs ? (
        <Appbar.Action
          icon={() => (
            <MaterialCommunityIcons
              name="cog"
              size={iconSize}
              color={colors?.accent}
            />
          )}
          onPress={openMenu}
        />
      ) : (
        <IconButton
          icon="cog"
          color={colors?.accent}
          size={iconSize}
          onPress={openMenu}
        />
      ),
    [colors?.accent, iconSize, xs],
  );

  const handleSignOut = React.useCallback(() => signOut(), []);

  const changeThemeSwitch = React.useMemo(
    () => (
      <LabelSwitch
        label={fbt('Dark mode', 'switch on and off the dark mode')}
        value={isDarkTheme}
      />
    ),
    [isDarkTheme],
  );

  // TODO: make `face-profile` dynamic based on viewer gender
  return (
    <Menu visible={visible} onDismiss={closeMenu} anchor={settingsButton}>
      <Link routeName="Home" web={{ path: '/profile' }} isText={false}>
        <MenuItem icon="face-profile" isText>
          <fbt desc="menu navigation">User settings</fbt>
        </MenuItem>
      </Link>
      <MenuItem icon="brightness-4" onPress={toggleDarkTheme}>
        {changeThemeSwitch}
      </MenuItem>
      <Pressable onPress={handleSignOut}>
        <Link routeName="Home" web={{ path: '/' }} isText={false}>
          <MenuItem icon="logout-variant" isText>
            <fbt desc="logout button">Logout</fbt>
          </MenuItem>
        </Link>
      </Pressable>
    </Menu>
  );
};

export default React.memo(HeaderViewerSettings, () => true);
