import { logEvent } from 'expo-firebase-analytics';

import { FullPostProps, TaggedAction } from '#interfaces/Analytics';

import formatter from '../../helpers/eventPropsFormatter';

type Props = FullPostProps & TaggedAction;

export const postTagged = (props: Props): Promise<void> =>
  logEvent('app:post_tagged', formatter(props));

export default postTagged;
