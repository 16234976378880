/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const ListContainer = styled.View`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-vertical: 4px;
`;

export const DotText = styled(Text)`
  padding-end: 4px;
  color: ${(props: any) => props.theme.colors.text};
  font-weight: bolder;
  line-height: 120%;
`;

export const ListText = styled(Text)`
  padding-end: 4px;
  color: ${(props: any) => props.theme.colors.text};
  line-height: 120%;
`;

export default null;
