import nookies from 'nookies';

import { Theme } from '#enum';
import { NookiesDestroy, NookiesGet, NookiesSet } from '#interfaces';

const themeKey = 'theme:mode';

export const getTheme = (ctx: NookiesGet, viewerId?: string | null): string => {
  const cookies = nookies.get(ctx);
  return cookies[`${themeKey}:${viewerId ?? ''}`];
};

export const setTheme = (
  ctx: NookiesSet,
  theme: Theme,
  viewerId?: string | null,
): void => {
  nookies.set(ctx, `${themeKey}:${viewerId ?? ''}`, theme, {
    maxAge: 9999999999,
    httpOnly: false,
    secure: !__DEV__,
    sameSite: 'lax',
    path: '/',
  });
};

export const deleteTheme = (
  ctx: NookiesDestroy,
  viewerId?: string | null,
): void => {
  nookies.destroy(ctx, `${themeKey}:${viewerId ?? ''}`);
};

export default null;
