import fbt from 'fbt';
import React from 'react';
import { Appbar } from 'react-native-paper';
import { graphql, useFragment } from 'react-relay';

import Avatar from '@/Avatar';
import getImageBySize from '#database/utils/getImageBySize';
import { ImageSize } from '#enum';
import useFbt from '#hooks/useFbt';
import useHeaderIconSize from '#hooks/useHeaderIconSize';
import useResponsive from '#hooks/useResponsive';
import useSession from '#hooks/useSession';
import useTheme from '#hooks/useTheme';
import { HeaderViewerInfo_viewer$key } from '~/HeaderViewerInfo_viewer.graphql';

import {
  FullName,
  FullNameContainer,
  ViewerContainer,
} from './HeaderViewerInfo.style';

const viewerFragment = graphql`
  fragment HeaderViewerInfo_viewer on User {
    id
    displayName
    fullName
    avatar {
      sizes {
        key
        url
      }
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: HeaderViewerInfo_viewer$key | null;
};

/**
 * Display viewer basic information, full name and profile picture.
 */
const HeaderViewerInfo = ({ viewer }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const [session] = useSession();
  const { colors } = useTheme();

  const imageUri = getImageBySize(viewerData?.avatar, [
    ImageSize.Thumbnail,
    ImageSize.Avatar,
  ]);
  const userName =
    viewerData?.displayName ??
    viewerData?.fullName ??
    session?.user?.displayName ??
    session?.user?.name ??
    fbt('Anonymous', 'user have no name');

  const avatarSize = useHeaderIconSize(imageUri);
  const [, { ltLg }] = useResponsive();

  const icon = (
    <Avatar
      userId={viewerData?.id}
      imageUri={imageUri}
      size={avatarSize}
      color={colors?.accent}
    />
  );

  return ltLg ? (
    <Appbar.Action icon={(): React.ReactElement => icon} />
  ) : (
    <ViewerContainer>
      {icon}
      <FullNameContainer>
        <FullName>{userName}</FullName>
      </FullNameContainer>
    </ViewerContainer>
  );
};

export default React.memo(HeaderViewerInfo);
