/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteTaggedPostMutationVariables = {
    userId: string;
    postId: string;
};
export type DeleteTaggedPostMutationResponse = {
    readonly taggedPostRemoveOne: {
        readonly recordId: string | null;
    } | null;
};
export type DeleteTaggedPostMutation = {
    readonly response: DeleteTaggedPostMutationResponse;
    readonly variables: DeleteTaggedPostMutationVariables;
};



/*
mutation DeleteTaggedPostMutation(
  $userId: MongoID!
  $postId: MongoID!
) {
  taggedPostRemoveOne(filter: {userId: $userId, postId: $postId}) {
    recordId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "postId",
            "variableName": "postId"
          },
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "RemoveOneTaggedPostPayload",
    "kind": "LinkedField",
    "name": "taggedPostRemoveOne",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recordId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTaggedPostMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteTaggedPostMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "234c9c6a820c407099d6d732aa06d826",
    "id": null,
    "metadata": {},
    "name": "DeleteTaggedPostMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteTaggedPostMutation(\n  $userId: MongoID!\n  $postId: MongoID!\n) {\n  taggedPostRemoveOne(filter: {userId: $userId, postId: $postId}) {\n    recordId\n  }\n}\n"
  }
};
})();
(node as any).hash = '519e9c85ebc0fbacebb5d0531d9e9e57';
export default node;
