import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { CBMutationConfig } from '#interfaces';
import { CreateTagsMutation } from '~/CreateTagsMutation.graphql';

const mutation = graphql`
  mutation CreateTagsMutation($labels: [String!]!) {
    tagForceCreateMany(labels: $labels) {
      id
      label
      slug
    }
  }
`;

const createTags = (
  envionment: Environment,
  labels: string[],
  config?: CBMutationConfig<CreateTagsMutation>,
): Disposable => {
  const variables = { labels };

  return commitMutation<CreateTagsMutation>(envionment, {
    ...config,
    mutation,
    variables,
  });
};

export default createTags;
