import { FeaturedSubCommentType } from '#enum';
import { SortGetCommentsCommentEnum } from '~~';

const mapper = {
  [SortGetCommentsCommentEnum.FeaturedScore]: FeaturedSubCommentType.Featured,
  [SortGetCommentsCommentEnum.WilsonScore]: FeaturedSubCommentType.Best,
  [SortGetCommentsCommentEnum.HotScore]: FeaturedSubCommentType.Hot,
  [SortGetCommentsCommentEnum.Newest]: FeaturedSubCommentType.Newest,
  [SortGetCommentsCommentEnum.Oldest]: FeaturedSubCommentType.Oldest,
  [FeaturedSubCommentType.Featured]: SortGetCommentsCommentEnum.FeaturedScore,
  [FeaturedSubCommentType.Best]: SortGetCommentsCommentEnum.WilsonScore,
  [FeaturedSubCommentType.Hot]: SortGetCommentsCommentEnum.HotScore,
  [FeaturedSubCommentType.Newest]: SortGetCommentsCommentEnum.Newest,
  [FeaturedSubCommentType.Oldest]: SortGetCommentsCommentEnum.Oldest,
};

/**
 * Map the comment related enum value to another enum value.
 */
const getCommentEnum = (
  enumValue?:
    | SortGetCommentsCommentEnum
    | Exclude<FeaturedSubCommentType, FeaturedSubCommentType.Controversial>,
): SortGetCommentsCommentEnum | FeaturedSubCommentType | undefined => {
  if (enumValue) return mapper[enumValue];
};

export default getCommentEnum;
