import { dequal } from 'dequal';
import { ReadonlyDeep } from 'type-fest';

import { TagItem } from '#interfaces';

type Tag = TagItem | null | undefined;
type Tags = ReadonlyDeep<Tag[] | null>;
type ReadonlyTags = ReadonlyDeep<Tag[] | null>;

const sortTags = (tags?: Tags | ReadonlyTags) =>
  tags?.map((tag) => `${tag?.id}:${tag?.slug}:${tag?.label}`).sort();

const compareTags = (
  aTags?: Tags | ReadonlyTags,
  bTags?: Tags | ReadonlyTags,
): boolean => dequal(sortTags(aTags), sortTags(bTags));

export default compareTags;
