/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateNewPostButton_viewer = {
    readonly id: string;
    readonly " $refType": "CreateNewPostButton_viewer";
};
export type CreateNewPostButton_viewer$data = CreateNewPostButton_viewer;
export type CreateNewPostButton_viewer$key = {
    readonly " $data"?: CreateNewPostButton_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CreateNewPostButton_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateNewPostButton_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'd220d30f09f3b02cc102c3b56489e63a';
export default node;
