import { logEvent } from 'expo-firebase-analytics';

import { Theme } from '#enum';

import formatter from '../../helpers/eventPropsFormatter';

type Props = {
  newTheme: Theme;
};

export const userThemeChanged = (props: Props): Promise<void> =>
  logEvent('app:user_themeChanged', formatter(props));

export default userThemeChanged;
