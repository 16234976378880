// only use `VERCEL_URL` when not in production
const vercelDeploymentUrl =
  process.env.NODE_ENV !== 'production' && process.env.VERCEL_URL;

/**
 * Create a URL string using `.env` variables for base url.
 *
 * @param {string} url url string, if full url was provided, it will ignore `base` parameter
 * @param {string | undefined} base base url string, if left blank, it will use `env` variables
 * @returns {string} formatted URL string
 */
const createUrl = (url: string, base?: string): string => {
  const baseUrl =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (base || vercelDeploymentUrl || process.env.BASE_URL) ??
    'http://localhost:3000';
  const formattedBase = /^https?:\/\/.*/.test(baseUrl)
    ? baseUrl
    : `https://${baseUrl}`;

  return new URL(url, formattedBase).href;
};

export default createUrl;
