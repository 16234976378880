/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState } from 'draft-js';

import { TagItem, UserTaggedAction } from '#interfaces';
import { PostForm_post } from '~/PostForm_post.graphql';

export type FormValues = Omit<
  Partial<PostForm_post>,
  'title' | 'content' | 'userTaggedTags' | 'settings' | ' $refType'
> & {
  title?: string;
  content?: EditorState;
  tags?: {
    suggestedTags?: TagItem[];
    selectedTags?: TagItem[];
  };
  // settings?: PostSettings;
  // meta?: { key: string; value: any }[] | null;
};

export const getUserTaggedAction = (
  prevTagCount?: number,
  newTagCount?: number,
): UserTaggedAction | undefined => {
  if (!prevTagCount && newTagCount) return 'tagged';
  if (prevTagCount && !newTagCount) return 'untagged';
};

// export type PostSettingsArray<K = keyof PostSettings> = (
//   | (K extends keyof PostSettings
//       ? { key: K; value: PostSettings[K] }
//       : never)
//   | null
// )[];

// export const formatSettingsArrayToObject = (
//   settings?: PostSettingsArray,
// ): PostSettings =>
//   settings?.reduce(
//     (obj, setting) =>
//       setting ? { ...obj, [setting.key]: setting.value } : obj,
//     {} as { [K in PostSettingKey]: PostSettings[K] },
//   ) ?? {};

// export const formatSettingsObjectToArray = (
//   settings: PostSettings,
// ): PostSettingsArray =>
//   Object.entries(settings).map(([key, value]) => ({
//     key,
//     value,
//   })) as PostSettingsArray;
