import { Environment, RecordSource } from 'relay-runtime';

import createEnvironment from './createEnvironment';

let relayEnvironment: Environment;

/**
 * Steal from https://github.com/vercel/next.js/blob/canary/examples/with-relay-modern/lib/relay.js.
 */
const initEnvironment = (initialRecords = {}): Environment => {
  // Create a network layer from the fetch function
  const environment = relayEnvironment ?? createEnvironment(initialRecords);

  // If your page has Next.js data fetching methods that use Relay, the initial records
  // will get hydrated here
  if (initialRecords) {
    environment.getStore().publish(new RecordSource(initialRecords));
  }
  // For SSG and SSR always create a new Relay environment
  if (typeof window === 'undefined') return environment;
  // Create the Relay environment once in the client
  if (!relayEnvironment) relayEnvironment = environment;

  return relayEnvironment;
};

export default initEnvironment;
