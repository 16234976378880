/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

export const EditorContainer = styled.View`
  color: ${(props: any) => props.theme.colors.text};
  /* stylelint-disable-next-line */
  cursor: text;
`;

export const FooterContainer = styled.View`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  background-color: ${(props: any) => props.theme.colors.blackOpac04};
`;

/**
 * Contain visible toolbar items
 */
export const FirstContainer = styled.View`
  display: inline-flex;
  flex: 1 1 auto;
`;

/**
 * Contain dropdown toolbar items
 */
export const SecondContainer = styled.View`
  display: inline-flex;
  flex: 0 0 auto;
`;

/**
 * DraftJS Editor blank space expander
 */
export const EditorFiller = styled.Pressable`
  flex: 1 1 auto;
  /* stylelint-disable-next-line react-native/css-property-no-unknown */
  cursor: text;
`;

export default null;
