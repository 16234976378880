import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet } from 'react-native';

import useTheme from '#hooks/useTheme';

type IconList = React.ComponentProps<typeof MaterialCommunityIcons>['name'];

type Props = {
  name: IconList;
};

const s = StyleSheet.create({
  icon: { margin: 'auto' },
});

const MenuItemIcon = ({ name }: Props): React.ReactElement => {
  const { colors } = useTheme();

  return (
    <MaterialCommunityIcons
      name={name}
      size={22}
      color={colors?.text}
      style={s.icon}
    />
  );
};

export default React.memo(MenuItemIcon);
