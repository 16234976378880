import { DefaultSession } from 'next-auth';
import { useSession as useNextSession } from 'next-auth/client';
import { useDeepCompareMemo } from 'use-deep-compare';

import { SessionUser } from '#interfaces';

type FormattedSession =
  | (DefaultSession & { user: SessionUser })
  | null
  | undefined;

const useSession = (): [FormattedSession, boolean] => {
  const session = useNextSession() as [FormattedSession, boolean];
  const memSession = useDeepCompareMemo(() => session, [session]);

  return memSession;
};

export default useSession;
