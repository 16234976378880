/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const ButtonContainer = styled.View`
  flex-direction: row;
  margin-horizontal: 16px;
`;

export const Button = styled(Text)`
  margin: auto 8px;
  color: ${(props: any) => props.theme.colors.accent};
  font-weight: bold;
  letter-spacing: 0.5px;
`;

export default null;
