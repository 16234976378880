import React from 'react';
import { StyleSheet } from 'react-native';
import { graphql, useFragment } from 'react-relay';

import Drawer from '@/Drawer';
import Header from '@/Header';
import Sidebar from '@/Sidebar';
import useDrawer from '#hooks/useDrawer';
import useResponsive from '#hooks/useResponsive';
import useSidebar from '#hooks/useSidebar';
import { PageLayout_viewer$key } from '~/PageLayout_viewer.graphql';

import {
  HeaderContainer,
  LayoutContainer,
  MainContainer,
  PageContainer,
} from './PageLayout.style';

const viewerFragment = graphql`
  fragment PageLayout_viewer on User {
    ...Header_viewer
  }
`;

type Props = {
  children: React.ReactNode;
  sidebar?: React.ReactElement;
  viewer: PageLayout_viewer$key | null;
};

const s = StyleSheet.create({
  sidebarContentContainer: {
    flexGrow: 1,
  },
});

/**
 * A Template component for `Next.js` pages. It will render `sidebar` as a
 * sticky component at the left most (LTR) of the screen.
 */
const PageLayout = ({
  children,
  sidebar,
  viewer,
}: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  const [, { sidebarType }] = useResponsive();
  const { isOpen, setIsOpen, setHasSidebar } = useSidebar();
  const { drawerComponent } = useDrawer();
  const isDrawer = sidebarType === 'drawer';
  const sidebarComponent = React.useMemo(
    () =>
      sidebar &&
      (isDrawer ? (
        <Drawer
          dismissable
          isOpen={isOpen}
          disabledIsOpenState
          width={320}
          onDismiss={() => setIsOpen(false)}
        >
          <Sidebar contentContainerStyle={s.sidebarContentContainer}>
            {sidebar}
          </Sidebar>
        </Drawer>
      ) : (
        <Sidebar contentContainerStyle={s.sidebarContentContainer}>
          {sidebar}
        </Sidebar>
      )),
    [isDrawer, isOpen, setIsOpen, sidebar],
  );

  React.useEffect(() => {
    let isMounted = true;

    if (sidebar && isMounted) setHasSidebar(!!sidebar);

    return () => {
      isMounted = false;
    };
  }, [sidebar, setHasSidebar]);

  return (
    <PageContainer>
      <HeaderContainer>
        <Header viewer={viewerData} />
      </HeaderContainer>
      <LayoutContainer>
        {sidebarComponent}
        {drawerComponent}
        <MainContainer borderStartWidth={isDrawer || !sidebar ? 0 : undefined}>
          {children}
        </MainContainer>
      </LayoutContainer>
    </PageContainer>
  );
};

export default PageLayout;
