import Fuse from 'fuse.js';
import React from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';
import { useDebouncedCallback } from 'use-debounce';
import { useDeepCompareMemo } from 'use-deep-compare';

import RespondableItem from '@/RespondableItem';
import SearchBox from '@/SearchBox';
import { LinkConfigs, TagItem } from '#interfaces';
import { createTagLabel, createTagSlug } from '#utils';
import { shortuuid } from '#utils/shortuuid';
import { TagSearchQuery } from '~/TagSearchQuery.graphql';

type SearchBoxItem = { id: string } & React.ComponentProps<
  typeof RespondableItem
>;

type Props = {
  searchableItems?: TagItem[];
  resultLimit?: number;
  onTagPress?: (tag: TagItem) => void;
  getLinkProps?: (tag: TagItem) => LinkConfigs;
  onNewTagPress?: (label: string) => void;
} & React.ComponentProps<typeof SearchBox>;

/**
 * Query the tags collection and render it to the list.
 * You only need to pass the sole prop, `onTagPress`, to let it know what action to take
 * when those tag was press/click.
 */
const TagSearch = ({
  searchableItems,
  resultLimit = 5,
  onTagPress,
  getLinkProps,
  onNewTagPress,
  ...props
}: Props): React.ReactElement => {
  const environment = useRelayEnvironment();

  const [text, setText] = React.useState('');
  const [tags, setTags] = React.useState<SearchBoxItem[]>();

  const items = useDeepCompareMemo(() => {
    const newTag =
      onNewTagPress &&
      (tags?.every((tag) => tag.slug !== createTagSlug(text)) || !tags)
        ? [
            {
              id: shortuuid(),
              label: createTagLabel(text),
              onPress: () => {
                onNewTagPress?.(text);
                setText('');
              },
            },
          ]
        : null;
    const formattedResultLimit = newTag?.length ? resultLimit - 1 : resultLimit;

    return [...(tags?.slice(0, formattedResultLimit) ?? []), ...(newTag ?? [])];
  }, [onNewTagPress, resultLimit, tags, text]);

  const fuse = searchableItems
    ? new Fuse(searchableItems, {
        keys: ['label', 'slug'],
        threshold: 0.3,
        distance: 50,
      })
    : undefined;

  const handleTagPress = React.useCallback(
    (tag: TagItem) => {
      onTagPress?.(tag);
      setText('');
    },
    [onTagPress],
  );

  const handleChange = React.useCallback((value: string) => setText(value), []);

  const debouncedSearch = useDebouncedCallback((search: string) => {
    if (!searchableItems?.length) {
      if (search.length >= 2)
        fetchQuery<TagSearchQuery>(
          environment,
          graphql`
            query TagSearchQuery($text: String) {
              searchTags(text: $text) {
                id
                label
                slug
              }
            }
          `,
          { text: search },
        ).subscribe({
          next: (data) => {
            setTags(
              data.searchTags.length
                ? (data.searchTags as TagItem[])
                : undefined,
            );
          },
        });
      else setTags(undefined);
    } else {
      const searchResults = fuse
        ?.search<TagItem>(search)
        .map((result) => result.item);

      setTags(searchResults?.length ? searchResults : undefined);
    }
  }, 150);

  React.useEffect(() => {
    debouncedSearch(text);
  }, [debouncedSearch, text]);

  return (
    <SearchBox
      value={text}
      onChangeText={handleChange}
      items={items}
      onItemPress={handleTagPress}
      getLinkProps={getLinkProps}
      {...props}
    />
  );
};

export default React.memo(TagSearch);
