/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${(props: any) => props.theme.colors.surface};
  border: solid 1px ${(props: any) => props.theme.colors.light};
  border-radius: 4px;
`;

export const HelperContainer = styled.View`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  margin: 6px auto;
`;

export default null;
