import constate from 'constate';
import { useMemo, useState } from 'react';

const useAppStateHook = () => {
  const [isPostSortable, setIsPostSortable] = useState<boolean>();
  const [communityCount, setCommunityCount] = useState<number>();

  const contextValue = useMemo(
    () => ({
      isPostSortable,
      setIsPostSortable,
      communityCount,
      setCommunityCount,
    }),
    [communityCount, isPostSortable],
  );

  return contextValue;
};

const [AppStateProvider, useAppState] = constate(useAppStateHook);

export { AppStateProvider };
export default useAppState;
