import React from 'react';
import isEqual from 'react-fast-compare';
import { ScrollViewProps } from 'react-native';

import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';

import { Container } from './Sidebar.style';

type Props = {
  children: React.ReactNode;
} & ScrollViewProps;

const Sidebar = ({ children, ...props }: Props) => {
  const [, { sidebarType }] = useResponsive();
  const { componentSizes } = useTheme();

  return (
    <Container
      height={
        sidebarType === 'drawer'
          ? undefined
          : `calc(100vh - ${componentSizes?.headerHeight}px)`
      }
      {...props}
    >
      {children}
    </Container>
  );
};

export default React.memo(Sidebar, (prev, next) =>
  isEqual(prev.children, next.children),
);
