import { convertToRaw, EditorState as DraftEditorState } from 'draft-js';
import fbt from 'fbt';
import * as z from 'zod';

import { EntityType } from '#enum';
import { getPlainText } from '#lib/draft-js/converter';
import { EditorState } from '#z';

// eslint-disable-next-line no-unused-expressions
fbt;

const tagItem = z.object({
  id: z.string(),
  label: z.string(),
  slug: z.string(),
});

export const schema = z.object({
  id: z.string().optional(),
  version: z.string().uuid().nullable().optional(),
  title: z.string().optional(),
  content: EditorState.refine(
    (val: DraftEditorState) =>
      // check if content is length enough or contain any media block
      getPlainText(val).length >= 3 ||
      Object.values(convertToRaw(val.getCurrentContent()).entityMap).find(
        (entity) => entity.type === EntityType.Image,
      ),
    {
      message: fbt('Content cannot leave empty', 'error message'),
    },
  ),
  tags: z
    .object({
      suggestedTags: z.array(tagItem).optional(),
      selectedTags: z.array(tagItem).optional(),
    })
    .optional(),
  createdByUserId: z.string().optional(),
});

export default null;
