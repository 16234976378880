let stepHeight: number[] = [];

export const getLineHeight = (
  minLines: number,
  initHeight: number,
  newHeight: number,
  loop = 0,
): [number, number] => {
  if (newHeight % (initHeight / minLines) === 0)
    return [initHeight / minLines, loop];
  return getLineHeight(minLines, initHeight - 1, newHeight - 1, loop + 1);
};

/**
 * Create array of textarea step height by using `minLines`, `maxLines` and `lineHeight`
 * then return the most optimal height of the provided content height.
 *
 * This function uses as a helper function to create the auto expanding textarea.
 */
const getOptimalTextareaHeight = (
  inputHeight: number,
  minLines: number,
  maxLines: number,
  lineHeight?: number,
  extraHeight?: number,
): number => {
  const len = stepHeight.length;
  if (!len && lineHeight && extraHeight) {
    const lineArray = [...new Array(maxLines - minLines + 1)].map(
      (_, i) => extraHeight + (i + minLines) * lineHeight,
    );
    stepHeight = lineArray.reverse();
  }

  const optimalHeight = stepHeight.find((h) => h <= inputHeight);

  if (inputHeight <= stepHeight[len - 1]) return stepHeight[len - 1];
  return optimalHeight ?? stepHeight[0];
};

export default getOptimalTextareaHeight;
