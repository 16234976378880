import debug from 'debug';

type Log = {
  [key: string]: debug.Debugger;
};

const defaultLog: Log = Object.freeze({
  next: debug('next'),
  relay: debug('relay'),
  test: debug('test'),
});

export const logFile = (file: string): Log => {
  if (!file) return defaultLog;

  const res: Log = {};
  Object.entries(defaultLog).forEach(([k, v]) => {
    res[k] = v.extend(`file:${file}`);
  });

  return res;
};

export default defaultLog;
