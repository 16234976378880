export enum ImageSize {
  Original = 'original',
  Avatar = 'avatar',
  Thumbnail = 'thumbnail',
  Medium = 'medium',
  PX720 = 'px720',
  PX1080 = 'px1080',
}

export default null;
