import { useContext, useEffect, useMemo, useState } from 'react';

import LocaleContext, { Context } from '#i18n/utils/LocaleContext';

const useFbt = (): Context => {
  const { locale, gender, isRTL, setLocale, setGender } = useContext(
    LocaleContext,
  );
  const [localeState, setLocaleState] = useState(locale);
  const [genderState, setGenderState] = useState(gender);

  useEffect(() => {
    if (locale !== localeState) setLocaleState(locale);
    if (gender !== genderState) setGenderState(gender);
  }, [gender, genderState, locale, localeState]);

  const contextValue = useMemo(
    () => ({ locale, gender, isRTL, setLocale, setGender }),
    [gender, isRTL, locale, setGender, setLocale],
  );

  return contextValue;
};

export default useFbt;
