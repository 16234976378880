/* eslint-disable @typescript-eslint/no-explicit-any */
import { Appbar } from 'react-native-paper';
import styled from 'styled-components/native';

import { margin, MarginProps } from '#styled-system';

export const HeaderContainer = styled.View`
  z-index: 99;
  flex: 1 1 auto;
`;

export const AppbarHeader = styled(Appbar.Header)`
  height: ${(props: any) => `${props.theme.componentSizes?.headerHeight}px`};
  background-color: ${(props: any) => props.theme.colors.primary};
`;

export const Logo = styled(Appbar.Content)<MarginProps>`
  padding-top: 4px;
  ${margin};
`;

export default null;
