import React from 'react';

type SetStateBoolean = React.Dispatch<React.SetStateAction<boolean>>;

export type SidebarContext = {
  isOpen?: boolean;
  hasSidebar?: boolean;
  setIsOpen: SetStateBoolean;
  setHasSidebar: SetStateBoolean;
};

type Props = {
  children: React.ReactNode;
};

export const SidebarContext = React.createContext<SidebarContext>({
  setIsOpen: () => null,
  setHasSidebar: () => null,
});

const SidebarProvider = ({ children }: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasSidebar, setHasSidebar] = React.useState(false);

  const contextValue = React.useMemo(
    () => ({ isOpen, hasSidebar, setIsOpen, setHasSidebar }),
    [hasSidebar, isOpen],
  );

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
