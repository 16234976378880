/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PageLayout_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"Header_viewer">;
    readonly " $refType": "PageLayout_viewer";
};
export type PageLayout_viewer$data = PageLayout_viewer;
export type PageLayout_viewer$key = {
    readonly " $data"?: PageLayout_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PageLayout_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageLayout_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Header_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '9667b286bee7c8b4b559fd5a25a0ea1a';
export default node;
