import Color from 'color';
import { dequal } from 'dequal';
import React from 'react';
import { IconButton } from 'react-native-paper';

import useTheme from '#hooks/useTheme';

type Props = Omit<React.ComponentProps<typeof IconButton>, 'icon'> & {
  isActive?: boolean;
  iconComponent?: React.ReactElement;
};

const RoundIconButton = ({
  isActive,
  iconComponent,
  color,
  style,
  ...props
}: Props): React.ReactElement => {
  const { colors } = useTheme();

  const iconColor = isActive ? color : colors?.light;
  const backgroundColor = isActive
    ? `${Color(color).fade(0.85)}`
    : 'transparent';

  const customIcon = iconComponent
    ? { icon: () => React.cloneElement(iconComponent, { color: iconColor }) }
    : {};

  return (
    <IconButton
      icon=""
      {...props}
      {...customIcon}
      style={[{ backgroundColor, borderRadius: 1000 }, style]}
    />
  );
};

export default React.memo(
  RoundIconButton,
  (prev, next) =>
    dequal(prev.style, next.style) &&
    prev.color === next.color &&
    prev.isActive === next.isActive,
);
