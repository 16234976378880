/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const ViewerContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-end: 8px;
  margin-start: 14px;
`;

export const FullName = styled(Text)`
  color: ${(props: any) => props.theme.colors.accent};
  font-weight: 500;
  font-size: 14px;
`;

export const FullNameContainer = styled.View`
  justify-content: center;
  margin-start: 8px;
`;

export default null;
