/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FeedbackButton_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackForm_viewer">;
    readonly " $refType": "FeedbackButton_viewer";
};
export type FeedbackButton_viewer$data = FeedbackButton_viewer;
export type FeedbackButton_viewer$key = {
    readonly " $data"?: FeedbackButton_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackButton_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackButton_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedbackForm_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'a836871d26bd30ef9abbdaf47ae34651';
export default node;
