import { NextPage, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { getSession } from 'next-auth/client';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import {
  fetchQuery,
  GraphQLTaggedNode,
  LocalQueryRenderer,
  RelayEnvironmentProvider,
  Variables,
} from 'react-relay';
import { OperationType } from 'relay-runtime';

import isValidViewerId from '#database/utils/isValidViewerId';
import useSession from '#hooks/useSession';
import { SessionUser } from '#interfaces';
import { initEnvironment } from '#relay/utils';

export interface WithDataOptions {
  query: GraphQLTaggedNode;
  variables?: (query: ParsedUrlQuery, viewerId?: string | null) => Variables;
}

interface WithDataProps<T extends OperationType> {
  composedInitialProps: unknown;
  queryResponse: T['response'];
  relayStoreRecords: unknown;
  viewerId?: string;
}

const withData = <T extends OperationType>(
  ComposedComponent: NextPage<T['response']>,
  options: WithDataOptions,
): NextPage<WithDataProps<T>> => {
  const WithData: NextPage<WithDataProps<T>> = (
    dataProps: WithDataProps<T>,
  ) => {
    const router = useRouter();

    const {
      composedInitialProps,
      queryResponse,
      relayStoreRecords,
      viewerId: ssrViewerId,
    } = dataProps;

    const [session] = useSession();
    const viewerId = session?.user?.id ?? ssrViewerId;

    const environment = initEnvironment(
      relayStoreRecords as Record<string, unknown>,
    );

    if (typeof window === 'undefined')
      return (
        <RelayEnvironmentProvider environment={environment}>
          <ComposedComponent
            {...composedInitialProps}
            {...(queryResponse as Record<string, unknown>)}
          />
        </RelayEnvironmentProvider>
      );

    return (
      <LocalQueryRenderer
        query={options.query}
        environment={environment}
        variables={{
          ...(options.variables?.(router.query, viewerId) ?? {}),
          viewerId,
          fetchViewer: isValidViewerId(viewerId),
        }}
        render={({ props }) => (
          <ComposedComponent
            {...composedInitialProps}
            {...(props as Record<string, unknown>)}
          />
        )}
      />
    );
  };

  WithData.displayName = `WithData(${ComposedComponent.displayName})`;

  WithData.getInitialProps = async (ctx: NextPageContext) => {
    // Evaluate the composed component"s getInitialProps()
    let composedInitialProps: unknown;
    if (ComposedComponent.getInitialProps)
      composedInitialProps = await ComposedComponent.getInitialProps(ctx);

    const session = await getSession(ctx);
    const viewerId = (session?.user as SessionUser)?.id;

    const environment = initEnvironment();
    const queryResponse = await fetchQuery<T>(environment, options.query, {
      ...(options.variables?.(ctx.query, viewerId) ?? {}),
      viewerId,
      fetchViewer: isValidViewerId(viewerId),
    }).toPromise();
    const relayStoreRecords = environment.getStore().getSource().toJSON();

    return {
      composedInitialProps,
      queryResponse,
      relayStoreRecords,
      viewerId,
    };
  };

  return WithData;
};

export default withData;
