/* stylelint-disable selector-max-type */
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-max-id */
/* stylelint-disable selector-pseudo-class-whitelist */
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import DateInput from '@/DateInput/theme';
import useTheme from '#hooks/useTheme';
import SidebarProvider from '#layers/SidebarProvider';
import draftJsBlockStyles, {
  darkDraftJSBlockStyles,
} from '#lib/draft-js/blockStyles';
import d from '#styles/themes/dark';
import l from '#styles/themes/main';
import { fonts } from '#styles/variables';

type Props = {
  children: React.ReactNode;
};

const GlobalStyles = createGlobalStyle`
  ${normalize};
  ${draftJsBlockStyles};

  html,
  body,
  body > div:first-child,
  div#__next,
  div#__next > div {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: ${fonts.based};
  }
`;

const LightTheme = createGlobalStyle`
  ${DateInput('light')}
  
  body {
    background-color: ${l.colors.pageBackground};
  }
`;

const DarkTheme = createGlobalStyle`
  ${DateInput('dark')}
  
  body {
    background-color: ${d.colors.pageBackground};
  }
  ${darkDraftJSBlockStyles}
`;

/**
 * This layer will provided the website/Next.js specific layers
 */
const PlatformProvider = ({ children }: Props): React.ReactElement => {
  const { isDarkTheme } = useTheme();
  return (
    <SidebarProvider>
      <GlobalStyles />
      {isDarkTheme ? <DarkTheme /> : <LightTheme />}
      {children}
    </SidebarProvider>
  );
};

export default PlatformProvider;
