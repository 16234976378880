/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { color, ColorProps, typography, TypographyProps } from '#styled-system';

const Text = styled.Text<TypographyProps & ColorProps>`
  font-family: '${(props: any) => props.theme.fonts.regular}';
  ${typography};
  ${color};
`;

export default Text;
