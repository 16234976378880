/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  margin-horizontal: 6px;
`;

export const TypeContainer = styled.View`
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.View`
  width: 60%;
  margin: 35px auto 0;
`;

export const Title = styled(Text)`
  align-self: flex-start;
  margin: 24px 6px 6px;
  color: ${(props: any) => props.theme.colors.text2};
  font-weight: bold;
  font-size: 18px;
`;

export const Hint = styled(Text)`
  margin: 8px 6px 0;
  color: ${(props: any) => props.theme.colors.gray};
  font-size: 13px;
  line-height: 150%;
`;

export default null;
