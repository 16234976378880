import { logEvent } from 'expo-firebase-analytics';

import formatter from '../../helpers/eventPropsFormatter';

type Props = {
  prevLanguage?: string | null;
  newLanguage?: string | null;
};

export const userAppLanguageChanged = (props: Props): Promise<void> =>
  logEvent('app:user_appLanguageChanged', formatter(props));

export default userAppLanguageChanged;
