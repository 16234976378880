import fbt from 'fbt';
import { signIn } from 'next-auth/client';
import React from 'react';

import CookiePolicy from '@/CookiePolicy';
import ModalCard from '@/ModalCard';
import ModalCloseButton from '@/ModalCloseButton';
import PrivacyPolicy from '@/PrivacyPolicy';
import SocialButton from '@/SocialButton';
import TermsOfService from '@/TermsOfService';
import useFbt from '#hooks/useFbt';

import {
  ButtonContainer,
  FooterContainer,
  FooterText,
  HeaderContainer,
  HeaderText,
  Link,
} from './SignUpLoginModal.style';

// eslint-disable-next-line no-unused-expressions
fbt;

enum Document {
  Privacy,
  Terms,
  Cookies,
}

type Props = {
  visible?: boolean;
  onDismiss?: () => void;
};

const SignUpLoginModal = ({ visible, onDismiss }: Props) => {
  useFbt();

  const [activeDoc, setActiveDoc] = React.useState<Document>();

  return (
    <>
      <ModalCard visible={visible ?? false} width="360px" onDismiss={onDismiss}>
        <HeaderContainer>
          <fbt desc="login form hint text">
            <HeaderText>Connect using...</HeaderText>
          </fbt>
        </HeaderContainer>
        <ButtonContainer>
          <SocialButton name="google" onPress={() => signIn('google')} />
        </ButtonContainer>
        <ButtonContainer>
          <SocialButton name="facebook" onPress={() => signIn('facebook')} />
        </ButtonContainer>
        <FooterContainer>
          <fbt desc="login form hint text">
            <FooterText>
              By continue, you agree to the
              <Link onPress={() => setActiveDoc(Document.Terms)}>
                Terms of Service
              </Link>{' '}
              and
              <Link onPress={() => setActiveDoc(Document.Privacy)}>
                Privacy Policy
              </Link>
              , including
              <Link onPress={() => setActiveDoc(Document.Cookies)}>
                Cookie Use
              </Link>
              .
            </FooterText>
          </fbt>
        </FooterContainer>
      </ModalCard>
      <ModalCard
        visible={activeDoc !== undefined}
        theme={{ colors: { backdrop: 'transparent' } }}
        onDismiss={() => setActiveDoc(undefined)}
      >
        <ModalCloseButton onPress={() => setActiveDoc(undefined)} />
        {activeDoc === Document.Privacy && <PrivacyPolicy noDocMargin />}
        {activeDoc === Document.Terms && <TermsOfService noDocMargin />}
        {activeDoc === Document.Cookies && <CookiePolicy noDocMargin />}
      </ModalCard>
    </>
  );
};

export default React.memo(SignUpLoginModal);
