/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { border, BorderProps } from '#styled-system';

export const PageContainer = styled.View`
  flex: 1 1 auto;
  flex-direction: column;
`;

export const HeaderContainer = styled.View`
  z-index: 99;
  flex: 1 1 auto;
`;

export const LayoutContainer = styled.View`
  flex: 9999 9999 auto;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${(props: any): string => props.theme.colors.surface};
`;

export const MainContainer = styled.View<BorderProps>`
  flex: 1 1 auto;
  align-items: center;
  height: 100%;
  background-color: ${(props: any): string =>
    props.theme.colors.pageBackground};
  border-start-color: ${(props: any): string => props.theme.colors.blackOpac05};
  border-start-width: 1px;
  ${border}
`;

export default LayoutContainer;
