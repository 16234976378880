import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { Image } from 'react-native';

import { ImageSize } from '#enum';
import { imageSize } from '#lib/variables/imageSize';
import { shortuuid } from '#utils/shortuuid';

type ImageData = {
  name: string;
  uri: string;
  base64?: string;
  fileFormat: SaveFormat;
};

// helpers
const resize = (size: number, width: number, height: number) => {
  const isPortrait = height > width;
  return {
    resize: isPortrait
      ? { width: Math.min(size, width) }
      : { height: Math.min(size, height) },
  };
};

// main
const createImage = async (
  imageUri: string,
  sizes: ImageSize[],
  fileFormat: SaveFormat = SaveFormat.JPEG,
): Promise<(ImageData | undefined)[]> => {
  const name = shortuuid();

  const getImageSize: Promise<{ width: number; height: number }> = new Promise(
    (resolve, reject) => {
      Image.getSize(
        imageUri,
        (width, height) => {
          resolve({ width, height });
        },
        (error) => reject(error),
      );
    },
  );
  const { width, height } = await getImageSize;

  const transformImage = async (
    type: ImageSize,
    size: number,
    compress = 0.8,
  ) => {
    const res = await manipulateAsync(imageUri, [resize(size, width, height)], {
      compress,
      format: fileFormat,
      base64: true,
    });
    const base64 = res.base64 ?? res.uri;
    return {
      name: `${name}_${type}`,
      uri: res.uri,
      base64: /^data:image\/\w+;base64,/.test(base64) ? base64 : undefined,
      fileFormat,
    };
  };

  return Promise.all(
    sizes.map((size) => {
      const img = imageSize?.[size];
      if (img) return transformImage(size, img.size, img.quality);
      return undefined;
    }),
  );
};

export default createImage;
