/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  min-width: 128px;
  min-height: 48px;
  padding-horizontal: 12px;
`;

export const IconContainer = styled.View`
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  margin: auto 6px;
`;

export const LabelContainer = styled.View`
  flex: 19 19 auto;
  align-items: flex-start;
  justify-content: center;
  min-width: 96px;
  max-width: 264px;
  min-height: ${(props: any) => props.theme.componentSizes?.menuItemHeight};
  margin-horizontal: 8px;
`;

export const LabelText = styled(Text)`
  color: ${(props: any) => props.theme.colors.text0};
  font-size: 16px;
  line-height: 115%;
`;

export default null;
