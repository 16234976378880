/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { border, BorderProps, color, ColorProps } from '#styled-system';
import getInputMaxWidth from '#styles/helpers/getInputMaxWidth';

export const CustomTextInput = styled.TextInput<ColorProps & BorderProps>`
  display: block;
  width: 100%;
  max-width: ${(props: any): string => getInputMaxWidth(props.size)};
  min-height: 34px;
  padding: 6px 10px;
  color: ${(props: any): string => props.theme.colors.text};
  font-weight: 400;
  font-family: '${(props: any) => props.theme.fonts.regular}';
  line-height: 150%;
  background-color: ${(props: any): string => props.theme.colors.surface};
  border: solid 1px ${(props: any): string => props.theme.colors.light};
  border-radius: 4px;
  ${color};
  ${border};
`;

export default null;
