import styled from 'styled-components/native';

export const Container = styled.View`
  display: flex;
`;

export const SearchBoxContainer = styled.View`
  z-index: 99;
  flex-direction: row;
`;

export const SearchButtonContainer = styled.View`
  start: -42px;
  margin: auto;
`;

export default null;
