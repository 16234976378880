import fbt from 'fbt';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, Paragraph } from 'react-native-paper';

import Button from '@/Button';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';

import {
  ButtonContainer,
  Container,
  ContentContainer,
  Field,
  FieldContainer,
  FieldContent,
  Title,
} from './InformedConsentForm.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  onConfirm: () => void;
};

const InformedConsentForm = ({ onConfirm }: Props): React.ReactElement => {
  useFbt();

  const { colors } = useTheme();
  const [isSubmittable, setIsSumittable] = React.useState(true);
  const { watch, control, setValue, handleSubmit } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      termsOfService: true,
      privacyPolicy: true,
    },
  });

  const termsOfService = watch('termsOfService');
  const privacyPolicy = watch('privacyPolicy');

  const onSubmit = React.useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  React.useEffect(() => {
    if (termsOfService && privacyPolicy) {
      setIsSumittable(true);
    } else {
      setIsSumittable(false);
    }
  }, [privacyPolicy, termsOfService, watch]);

  return (
    <Container>
      <Title>
        <fbt desc="form title">Terms of Service & Privacy Policy</fbt>
      </Title>
      <ContentContainer>
        <Paragraph>
          <fbt desc="alert message">
            We have updted Lpaydat&apos;s Terms of Service & Privacy Policy.
            These changes are effective for all users as of March 19th, 2021.
            You are required to read and aggree to these policies before
            continuing te use Lpaydat.
          </fbt>
        </Paragraph>
        <Paragraph>
          <FieldContainer>
            <Controller
              control={control}
              name="termsOfService"
              render={({ value }) => (
                <Field>
                  <Checkbox
                    color={colors?.red2}
                    uncheckedColor={colors?.disabled}
                    status={value ? 'checked' : 'unchecked'}
                    onPress={() => setValue('termsOfService', !value)}
                  />
                  <FieldContent>
                    <fbt desc="field content">
                      I&apos;ve read and agree with Lpaydat&apos;s Terms of
                      Service
                    </fbt>
                  </FieldContent>
                </Field>
              )}
            />
            <Controller
              control={control}
              name="privacyPolicy"
              render={({ value }) => (
                <Field>
                  <Checkbox
                    color={colors?.red2}
                    uncheckedColor={colors?.disabled}
                    status={value ? 'checked' : 'unchecked'}
                    onPress={() => setValue('privacyPolicy', !value)}
                  />
                  <FieldContent>
                    <fbt desc="field content">
                      I&apos;ve read and agree with Lpaydat&apos;s Privacy
                      Policy
                    </fbt>
                  </FieldContent>
                </Field>
              )}
            />
          </FieldContainer>
        </Paragraph>
      </ContentContainer>
      <ButtonContainer>
        <Button
          mode="contained"
          color={colors?.blue2}
          style={{ width: '90%' }}
          labelStyle={{ color: colors?.white }}
          onPress={handleSubmit(onSubmit)}
          disabled={!isSubmittable}
        >
          <fbt desc="button text">Accept</fbt>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default InformedConsentForm;
