/* eslint-disable @typescript-eslint/no-explicit-any */
import { Disposable, Environment } from 'react-relay';

import { ObjectType } from '#enum';
import { CBMutationConfig, UserTaggedAction } from '#interfaces';
import { AddRemovePostTagsMutation } from '~/AddRemovePostTagsMutation.graphql';

// eslint-disable-next-line import/no-cycle
import addRemovePostTags from './AddRemovePostTags';

export enum ObjectOperation {
  AddTaggedTags,
  UpdateTaggedTags,
}

export type BasedArgs = {
  userId: string;
  allTags: string[];
  addedTags: string[];
  removedTags: string[];
  taggedAction?: UserTaggedAction; // TODO: should exclude post owner
  objectOperation?: ObjectOperation;
};

const addRemoveTaggedTags = (
  environment: Environment,
  args: {
    objectType: ObjectType;
    objectId: string;
  } & BasedArgs,
  config?: CBMutationConfig<AddRemovePostTagsMutation>,
): Disposable | null => {
  const { objectType, objectId, ...mutationVariables } = args;

  if (objectType === ObjectType.Post) {
    return addRemovePostTags(
      environment,
      {
        postId: objectId,
        ...mutationVariables,
      },
      config,
    );
  }

  return null;
};

export default addRemoveTaggedTags;
