import { logEvent } from 'expo-firebase-analytics';

import formatter from '../../helpers/eventPropsFormatter';

type Props = {
  formName: 'postForm' | 'feedbackForm' | 'taggedTagsModal' | 'settingsForm';
  formStage?: string;
  errors?: string | (string | null | undefined)[];
};

export const formError = (props: Props): Promise<void> =>
  logEvent('app:form_error', formatter(props));

export default formError;
