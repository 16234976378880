import { DraftContentState } from '#interfaces';

const helperFn = (
  contentState: DraftContentState,
  blockPoints: number[],
  totalPoints: number,
) => {
  // if content is short, display it all
  if (totalPoints <= 4) return [contentState];

  const initDisplay: DraftContentState = { blocks: [], entityMap: {} };
  const extraDisplay: DraftContentState = { blocks: [], entityMap: {} };
  let tempPoints = 0;

  for (const [i, point] of blockPoints.entries()) {
    const block = contentState.blocks[i];

    // to guarantee that the content will not too long
    tempPoints += point;

    if (tempPoints <= 3) {
      // initDisplay will display on the content card
      initDisplay.blocks = [...initDisplay.blocks, block];

      if (block.entityRanges.length) {
        for (const entityRange of block.entityRanges) {
          const k = entityRange.key.toString();
          if (k) initDisplay.entityMap[k] = contentState.entityMap[k];
        }
      }
    } else if (totalPoints <= 7) {
      // if content is long but not too long, hide it as the expandable content
      // instead of open on the new modal
      extraDisplay.blocks = [...extraDisplay.blocks, block];

      if (block.entityRanges.length) {
        for (const entityRange of block.entityRanges) {
          const k = entityRange.key.toString();
          if (k) extraDisplay.entityMap[k] = contentState.entityMap[k];
        }
      }
    }
  }

  return totalPoints <= 7 ? [initDisplay, extraDisplay] : [initDisplay];
};

/**
 * Use Block type and content to determine points, then points to decide
 * which content shold display on the card and what to do when user click on `Read More`.
 *
 * @param {string} contentStateJson Draft ContentState in a JSON format
 * @returns {string[]} preview contents
 */
const createPreviewContent = (contentStateJson: string): [string[], number] => {
  let blockPoints: number[] = [];
  let totalPoints = 0;
  let tempTextLength = 0;
  let blockCount = 0;

  const object = JSON.parse(contentStateJson);

  if (Array.isArray(object?.blocks) && object?.blocks?.length) {
    const { blocks } = object as DraftContentState;

    for (const block of blocks) {
      if (typeof block === 'object' && block !== null) {
        // if text
        if (block?.text?.trim()?.length && block.type !== 'atomic') {
          const btLen = block.text.length;
          const blockPoint = Math.floor((btLen + tempTextLength) / 240);

          if (blockPoint) {
            tempTextLength = 0;
            blockCount = 0;
            totalPoints += blockPoint;
          } else if (blockCount >= 3) {
            tempTextLength = 0;
            blockCount = 0;
            totalPoints += 1;
          } else {
            tempTextLength += btLen;
            blockCount += 1;
          }

          blockPoints = [...blockPoints, blockPoint];
        }

        // if image
        if (block.type === 'atomic' && block.entityRanges.length) {
          const atomicKey = block.entityRanges[0]?.key;
          if (
            typeof atomicKey === 'number' &&
            object.entityMap[atomicKey.toString()]
          ) {
            totalPoints += 2;
            blockPoints = [...blockPoints, 2];
          }
        }
      }
    }

    const previewContent = helperFn(
      object,
      blockPoints,
      totalPoints,
    ).map((state) => JSON.stringify(state));

    return [previewContent, totalPoints];
  }

  return [[], 0];
};

export default createPreviewContent;
