import { compose } from '@styled-system/core'; // eslint-disable-line
import * as StyledSystem from 'styled-system';

import {
  BorderStartProps as NativeBorderStartProps,
  BorderEndProps as NativeBorderEndProps,
  BorderStartColorProps as NativeBorderStartColorProps,
  BorderStartWidthProps as NativeBorderStartWidthProps,
  BorderEndColorProps as NativeBorderEndColorProps,
  BorderEndWidthProps as NativeBorderEndWidthProps,
  BorderProps as NativeBorderProps,
  BorderRadiusProps as NativeBorderRadiusProps,
  MarginProps as NativeMarginProps,
  MarginStartProps as NativeMarginStartProps,
  MarginEndProps as NativeMarginEndProps,
  PaddingProps as NativePaddingProps,
  PaddingStartProps as NativePaddingStartProps,
  PaddingEndProps as NativePaddingEndProps,
  SpaceProps as NativeSpaceProps,
} from '../../../interfaces';

import borderNative, { borderRadius as borderRadiusNative } from './border';
import nativeSpace, {
  margin as marginNative,
  padding as paddingNative,
} from './space';

export * from 'styled-system';

export type BorderProps = StyledSystem.BorderProps & NativeBorderProps;
export type BorderStartProps = NativeBorderStartProps;
export type BorderEndProps = NativeBorderEndProps;
export type BorderStartColorProps = NativeBorderStartColorProps;
export type BorderStartWidthProps = NativeBorderStartWidthProps;
export type BorderEndColorProps = NativeBorderEndColorProps;
export type BorderEndWidthProps = NativeBorderEndWidthProps;
export type BorderRadiusProps = StyledSystem.BorderRadiusProps &
  NativeBorderRadiusProps;

export type MarginProps = StyledSystem.MarginProps & NativeMarginProps;
export type MarginStartProps = NativeMarginStartProps;
export type MarginEndProps = NativeMarginEndProps;

export type PaddingProps = StyledSystem.PaddingProps & NativePaddingProps;
export type PaddingStartProps = NativePaddingStartProps;
export type PaddingEndProps = NativePaddingEndProps;

export type SpaceProps = StyledSystem.SpaceProps & NativeSpaceProps;

export { borderEnd, borderStart } from './border';
export const borderRadius = compose(
  StyledSystem.borderRadius,
  borderRadiusNative,
);
export const border = compose(StyledSystem.border, borderNative);

export const margin = compose(StyledSystem.margin, marginNative);
export const padding = compose(StyledSystem.padding, paddingNative);
export const space = compose(StyledSystem.space, nativeSpace);
