import { DeviceDetectorResult } from 'device-detector-js';

import { DeviceType } from '#enum';

const getDeviceType = (
  device?: DeviceDetectorResult['device'],
): DeviceType | undefined => {
  switch (true) {
    case device?.type === 'smartphone':
      return DeviceType.Mobile;
    case device?.type === 'tablet':
      return DeviceType.Tablet;
    case device?.type === 'desktop':
      return DeviceType.Desktop;
    default:
      return undefined;
  }
};

export default getDeviceType;
