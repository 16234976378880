import {
  AvailableLocale,
  FetchedTranslation,
  TranslatedFbts,
  TranslationInfo,
} from '#interfaces';

import AsyncStorage from './AsyncStorage';

/**
 * `AsyncStorage` abstract class for working with `fbt`, the facebook i18n framework.
 *
 * This `Fbt` should work only on client, so when on the server, all functions
 * will return `Promise<null>`.
 */
export default class Fbt {
  static storage = AsyncStorage;

  /**
   * Get the important data for `initFbt` function
   */
  static getFbtData(): Promise<{
    selectedLocale: AvailableLocale | null;
    translationInfo: TranslationInfo | null;
    translations: TranslatedFbts | null;
  } | null> {
    return this.storage.getMultiple([
      'selectedLocale',
      'translationInfo',
      'translations',
    ]);
  }

  static updateTranslations(
    locale: AvailableLocale,
    data: FetchedTranslation,
  ): Promise<void | null> {
    const translationInfo = { [locale]: { version: data.version } };
    const translations = { [locale]: data.translations };
    return this.storage.mergeMultiple([{ translations }, { translationInfo }]);
  }

  // selected locale
  static setSelectedLocale(locale: AvailableLocale): Promise<void | null> {
    return this.storage.set('selectedLocale', locale);
  }

  static getSelectedLocale(): Promise<AvailableLocale | null> {
    return this.storage.get('selectedLocale');
  }

  static resetSelectedLocale(): Promise<void | null> {
    return this.storage.set('selectedLocale', 'en_US');
  }

  // translations
  static async setTranslations(
    translations: TranslatedFbts,
  ): Promise<void | null> {
    return this.storage.set('translations', translations);
  }

  static getTranslations(): Promise<TranslatedFbts | null> {
    return this.storage.get('translations');
  }

  // translation info
  static async setTranslationInfo(
    translationInfo: TranslationInfo,
  ): Promise<void | null> {
    const storedInfo = await AsyncStorage.get('translationInfo');
    const newInfo = { ...storedInfo, ...translationInfo };
    return this.storage.set('translationInfo', newInfo);
  }

  static getTranslationInfo(): Promise<TranslationInfo | null> {
    return this.storage.get('translationInfo');
  }
}
