import * as z from 'zod';

import { CollaboratorType as ICollaboratorType } from '#enum';

const CollaboratorType = z.enum([
  ICollaboratorType.User,
  ICollaboratorType.UserTag,
]);

export default CollaboratorType;
