export const second = 1000;

export const minute = second * 60;

export const hour = minute * 60;

export const day = hour * 24;

export const week = day * 7;

export const month = day * 30;

export const year = day * 365;

export default null;
