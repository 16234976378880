/* eslint-disable @typescript-eslint/no-explicit-any */
import { IntlVariations } from 'fbt';
import FbtHooks from 'fbt/lib/FbtHooks';
import FbtTranslations from 'fbt/lib/FbtTranslations';
import { NextPage, NextPageContext } from 'next';
import React from 'react';
import { fetchQuery, graphql } from 'react-relay';

import isValidViewerId from '#database/utils/isValidViewerId';
import fetchTranslation from '#i18n/utils/fetchTranslation';
import LocaleProvider from '#i18n/utils/LocaleProvider';
import { AvailableLocale } from '#interfaces';
import initEnvironment from '#relay/utils/initEnvironment';
import { withIntlQuery } from '~/withIntlQuery.graphql';

type Props = {
  translations?: FBT.Translations;
  viewerContext?: FBT.IntlViewerContext;
};

/**
 * Provide styles to app on the ssr, these styles included `theme`, `deviceProps`, etc...
 */
const withIntl = (ComposedComponent: NextPage<Props>): NextPage<Props> =>
  class WithIntl extends React.Component<Props> {
    static displayName = `WithIntl(${ComposedComponent.displayName})`;

    static async getInitialProps(ctx: NextPageContext) {
      let composedInitialProps = {};
      if (ComposedComponent.getInitialProps) {
        composedInitialProps = await ComposedComponent.getInitialProps(ctx);
      }

      const { viewerId } = (composedInitialProps ?? {}) as any;

      const environment = initEnvironment();
      const data = await fetchQuery<withIntlQuery>(
        environment,
        graphql`
          query withIntlQuery($viewerId: MongoID, $fetchViewer: Boolean!) {
            viewer(_id: $viewerId) @include(if: $fetchViewer) {
              details {
                locale
              }
            }
          }
        `,
        { viewerId, fetchViewer: isValidViewerId(viewerId) },
      ).toPromise();
      const viewerLocale = data?.viewer?.details?.locale ?? 'en_US';
      const fetchedTranslations = await fetchTranslation(
        viewerLocale as AvailableLocale,
      );
      const translations = {
        [viewerLocale]: fetchedTranslations.translations,
      };
      const viewerContext = {
        GENDER: IntlVariations.GENDER_UNKNOWN,
        locale: viewerLocale,
      };

      FbtTranslations.registerTranslations(translations);
      FbtHooks.register({
        getViewerContext: () => viewerContext,
      });

      return {
        ...composedInitialProps,
        translations,
        viewerContext,
      };
    }

    render() {
      const { translations, viewerContext } = this.props;
      return (
        <LocaleProvider
          translations={translations}
          viewerContext={viewerContext}
        >
          <ComposedComponent {...this.props} />
        </LocaleProvider>
      );
    }
  };

export default withIntl;
