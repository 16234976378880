import { IImage } from '#database/models';
import { ImageSize } from '#enum';

const getImageBySize = (
  imageSizes: IImage | null | undefined,
  sizes: ImageSize[],
): string | undefined | null =>
  imageSizes?.sizes?.find(
    (img) => img?.key && sizes.includes(img.key as ImageSize) && img.url,
  )?.url;

export default getImageBySize;
