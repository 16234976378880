import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { IconButton } from 'react-native-paper';

import useHeaderIconSize from '#hooks/useHeaderIconSize';
import useSidebar from '#hooks/useSidebar';

const DrawerButton = (): React.ReactElement => {
  const iconSize = useHeaderIconSize();
  const { isOpen, setIsOpen } = useSidebar();

  return (
    <IconButton
      icon={(): React.ReactElement => (
        <MaterialCommunityIcons
          name={isOpen ? 'menu-open' : 'menu'}
          size={iconSize}
          color="white"
        />
      )}
      onPress={() => setIsOpen(!isOpen)}
    />
  );
};

export default DrawerButton;
