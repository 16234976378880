import { SaveFormat } from 'expo-image-manipulator';
import fetch from 'isomorphic-unfetch';

import { BucketName } from '#aws/s3/buckets';
import createImage from '#aws/s3/createImage';
import { ImageSize } from '#enum';
import { url } from '#utils';

const uploadAvatarEndpoint =
  process.env.UPLOAD_IMAGE_ENDPOINT ?? url('api/aws/s3/uploadImage');

const uploadImage = async (
  imageUri: string | null,
  sizes: ImageSize[],
  options?: {
    bucket: BucketName;
    prefix?: string;
    viewerId?: string | null;
  },
): Promise<(string | undefined)[] | undefined> => {
  if (!imageUri) return;
  const formattedImages = await createImage(imageUri, sizes, SaveFormat.JPEG);

  const image = formattedImages.reduce(
    (res, img, i) =>
      img
        ? {
            ...res,
            [sizes[i]]: {
              base64: img?.base64,
              name: img?.name,
              fileFormat: img?.fileFormat,
            },
          }
        : res,
    {} as Record<
      ImageSize,
      { base64: string; name: string; fileFormat: SaveFormat }
    >,
  );

  // Upload avatar images to S3
  const imageUrls = await fetch(uploadAvatarEndpoint, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      image,
      ...options,
    }),
  });

  return imageUrls ? imageUrls.json() : {};
};

export default uploadImage;
