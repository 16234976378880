/* eslint-disable @typescript-eslint/no-explicit-any */
import { Animated } from 'react-native';
import styled from 'styled-components/native';

export const MainContainer = styled(Animated.View)`
  position: fixed;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`;

export const Mask = styled(Animated.View)`
  width: 100%;
  height: 100%;
`;

export const DrawerContainer = styled(Animated.ScrollView)`
  position: absolute;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: center;
  max-width: 100%;
  max-height: 100%;
  background-color: ${(props: any): string => props.theme.colors.surface};
  border-end-color: ${(props: any): string => props.theme.colors.blackOpac05};
  border-end-width: 1px;
  border-start-color: ${(props: any): string => props.theme.colors.blackOpac05};
  border-start-width: 1px;
`;

export default null;
