import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { Portal } from 'react-native-paper';
import { graphql, useFragment } from 'react-relay';

import FeedbackForm from '@/FeedbackForm';
import ModalCard from '@/ModalCard';
import RoundIconButton from '@/RoundIconButton';
import useDrawer from '#hooks/useDrawer';
import useResponsive from '#hooks/useResponsive';
import useSession from '#hooks/useSession';
import useSidebar from '#hooks/useSidebar';
import useTheme from '#hooks/useTheme';
import { FeedbackButton_viewer$key } from '~/FeedbackButton_viewer.graphql';

import { Container } from './FeedbackButton.style';

const viewerFragment = graphql`
  fragment FeedbackButton_viewer on User {
    id
    ...FeedbackForm_viewer
  }
`;

type Props = {
  viewer: FeedbackButton_viewer$key;
  screenName?: string;
};

const FeedbackButton = ({
  viewer,
  screenName,
}: Props): React.ReactElement | null => {
  const viewerData = useFragment(viewerFragment, viewer);

  const { isOpen: isSidebarOpen } = useSidebar();
  const { isOpen: isDrawerOnpen } = useDrawer();
  const [session] = useSession();
  const [r, { xs }] = useResponsive();
  const { colors } = useTheme();
  const [visible, setVisible] = React.useState(false);

  const icon = React.useMemo(
    () => <MaterialIcons name="feedback" size={24} />,
    [],
  );

  const handlePress = React.useCallback(() => {
    setVisible(true);
  }, []);

  const handleDismiss = React.useCallback(() => {
    setVisible(false);
  }, []);

  return (session?.user || viewerData.id) &&
    !isSidebarOpen &&
    !isDrawerOnpen ? (
    <>
      <Portal>
        <Container
          marginEnd={xs ? '20px' : undefined}
          marginBottom={xs ? '16px' : undefined}
        >
          <RoundIconButton
            isActive
            size={xs ? 28 : 32}
            iconComponent={icon}
            color={colors?.red2}
            onPress={handlePress}
          />
        </Container>
      </Portal>
      <ModalCard
        visible={visible}
        onDismiss={handleDismiss}
        maxWidth={r(['100%', '500px'])}
      >
        <FeedbackForm screenName={screenName} viewer={viewerData} />
      </ModalCard>
    </>
  ) : null;
};

export default React.memo(
  FeedbackButton,
  (prev, next) =>
    prev.viewer[' $data']?.id === next.viewer[' $data']?.id &&
    prev.screenName === next.screenName,
);
