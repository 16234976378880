import React from 'react';
import { FlatList, StyleSheet } from 'react-native';

import { convertToRoman } from '#utils';

import { DotText, ListContainer, ListText } from './List.style';

type Props = {
  children: React.ReactElement[];
  isOrdered?: boolean;
  isRoman?: boolean;
};

const s = StyleSheet.create({
  flatList: { marginStart: 18 },
});

const List = ({ children, isOrdered, isRoman }: Props) => {
  const renderItem = React.useCallback(
    ({ item, index }) => {
      const bullet = isOrdered ? (
        <ListText>{isRoman ? convertToRoman(index) : index}.</ListText>
      ) : (
        <DotText>•</DotText>
      );

      return (
        <ListContainer>
          <ListText>
            {bullet} {item}
          </ListText>
        </ListContainer>
      );
    },
    [isOrdered, isRoman],
  );

  const keyExtractor = React.useCallback((item, i) => i.toString(), []);

  return (
    <FlatList
      data={children}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      style={s.flatList}
    />
  );
};

export { ListText };

export default React.memo(List);
