import { dequal } from 'dequal';
import { EditorState } from 'draft-js';
import fbt from 'fbt';
import React from 'react';
import { ViewStyle } from 'react-native';
import { useDebouncedCallback } from 'use-debounce';

import CenterContent from '@/CenterContent';
import Editor from '@/Editor';
import RoundButton from '@/RoundButton';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import { createEditorState } from '#lib/draft-js/converter';

import { Container, HelperContainer } from './Textarea.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  containerStyle?: ViewStyle;
  submitButton?: {
    text?: string;
    color?: string;
    backgroundColor?: string;
    disabled?: boolean;
    onPress?: () => void;
    onSubmitCallback?: () => void;
  };
  cancelButton?: {
    text?: string;
    color?: string;
    backgroundColor?: string;
    disabled?: boolean;
    onPress?: () => void;
    onCancelCallback?: () => void;
  };
} & React.ComponentProps<typeof Editor>;

const Textarea = ({
  editorState,
  onChange,
  containerStyle,
  helperToolbar,
  submitButton,
  cancelButton,
  ...editorProps
}: Props): React.ReactElement => {
  useFbt();

  const { colors, isDarkTheme } = useTheme();
  const isDarkThemeRef = React.useRef(isDarkTheme);
  const [, setCount] = React.useState(0);
  const [localEditorState, setLocalEditorState] = React.useState(editorState);

  const debouncedOnChange = useDebouncedCallback((v: EditorState) => {
    onChange?.(v);
  }, 100);

  const handleChange = React.useCallback(
    (v: EditorState) => {
      setLocalEditorState(v);
      debouncedOnChange(v);
    },
    [debouncedOnChange],
  );

  const handleSubmit = React.useCallback(() => {
    setLocalEditorState(createEditorState());
    submitButton?.onPress?.();
  }, [submitButton]);

  const handleCancel = React.useCallback(() => {
    setLocalEditorState(createEditorState());
    cancelButton?.onPress?.();
  }, [cancelButton]);

  React.useEffect(() => {
    if (isDarkTheme !== isDarkThemeRef.current) {
      isDarkThemeRef.current = isDarkTheme;
      setCount((prev) => prev + 1);
    }
  }, [isDarkTheme]);

  const helperComponent =
    helperToolbar ??
    (submitButton || cancelButton ? (
      <CenterContent>
        <HelperContainer>
          {cancelButton ? (
            <RoundButton
              disabled={cancelButton.disabled}
              color={cancelButton.color ?? colors?.text3}
              backgroundColor={cancelButton.backgroundColor}
              onPress={handleCancel}
            >
              {cancelButton.text ?? <fbt desc="button label">Cancel</fbt>}
            </RoundButton>
          ) : null}
          {submitButton ? (
            <RoundButton
              disabled={submitButton.disabled}
              color={submitButton.color}
              backgroundColor={submitButton.backgroundColor}
              onPress={handleSubmit}
            >
              {submitButton.text ?? <fbt desc="button label">Submit</fbt>}
            </RoundButton>
          ) : null}
        </HelperContainer>
      </CenterContent>
    ) : null);

  return (
    <Container style={containerStyle}>
      <Editor
        {...editorProps}
        editorState={localEditorState}
        onChange={handleChange}
        helperToolbar={helperComponent}
      />
    </Container>
  );
};

export default React.memo(
  Textarea,
  (prev, next) =>
    prev.showToolbar === next.showToolbar &&
    prev.readOnly === next.readOnly &&
    dequal(prev.editorState, next.editorState) &&
    prev.submitButton?.text === next.submitButton?.text &&
    prev.submitButton?.color === next.submitButton?.color &&
    prev.submitButton?.backgroundColor === next.submitButton?.backgroundColor &&
    prev.submitButton?.disabled === next.submitButton?.disabled &&
    prev.cancelButton?.text === next.cancelButton?.text &&
    prev.cancelButton?.color === next.cancelButton?.color &&
    prev.cancelButton?.backgroundColor === next.cancelButton?.backgroundColor &&
    prev.cancelButton?.disabled === next.cancelButton?.disabled,
);
