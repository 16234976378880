import fbt from 'fbt';
import React from 'react';

import TagCloud from '@/TagCloud';
import useFbt from '#hooks/useFbt';
import { LinkConfigs, TagItem } from '#interfaces';
import { getObjectJoinString } from '#utils';

import {
  Container,
  Message,
  MessageContainer,
  NoTagContainer,
  SelectedTagsContainer,
  SuggestedTagsLabel,
  SuggestedTagsLabelContainer,
} from './SSTagCloud.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  selectedTags?: TagItem[];
  suggestedTags?: TagItem[];
  selectedTagsMessage?: string;
  selectedTagsFooter?: React.ReactElement;
  noTagPlaceholder?: React.ReactElement;
  onSelectedTagPress?: (tag: TagItem) => void;
  onSuggestedTagPress?: (tag: TagItem) => void;
  getSelectedTagLinkProps?: (tag: TagItem) => LinkConfigs;
  getSuggestedTagLinkProps?: (tag: TagItem) => LinkConfigs;
};

const SSTagCloud = ({
  selectedTags = [],
  suggestedTags = [],
  selectedTagsMessage,
  selectedTagsFooter,
  noTagPlaceholder,
  onSelectedTagPress,
  onSuggestedTagPress,
  getSelectedTagLinkProps,
  getSuggestedTagLinkProps,
}: Props): React.ReactElement => {
  useFbt();

  const haveSelectedTags = selectedTags.length;
  const haveSuggestedTags = suggestedTags.length;

  return (
    <Container>
      {haveSelectedTags ? (
        <SelectedTagsContainer>
          <TagCloud
            tags={selectedTags}
            onTagPress={onSelectedTagPress}
            getLinkProps={getSelectedTagLinkProps}
          />
        </SelectedTagsContainer>
      ) : (
        noTagPlaceholder ?? (
          <NoTagContainer>
            <Message>
              <fbt desc="no selected tags placeholder">No tags selected</fbt>
            </Message>
          </NoTagContainer>
        )
      )}
      {selectedTagsMessage && (
        <MessageContainer>
          <Message>{selectedTagsMessage}</Message>
        </MessageContainer>
      )}
      {selectedTagsFooter}
      {haveSuggestedTags ? (
        <>
          <SuggestedTagsLabelContainer>
            <SuggestedTagsLabel>
              <fbt desc="label for suggested tags">suggested tags</fbt>
            </SuggestedTagsLabel>
          </SuggestedTagsLabelContainer>
          <TagCloud
            tags={suggestedTags}
            onTagPress={onSuggestedTagPress}
            getLinkProps={getSuggestedTagLinkProps}
          />
        </>
      ) : null}
    </Container>
  );
};

export default React.memo(
  SSTagCloud,
  (prev, next) =>
    getObjectJoinString(prev.suggestedTags, ['id']) ===
      getObjectJoinString(next.suggestedTags, ['id']) &&
    getObjectJoinString(prev.selectedTags, ['id']) ===
      getObjectJoinString(next.selectedTags, ['id']) &&
    prev.selectedTagsMessage === next.selectedTagsMessage &&
    prev.selectedTagsFooter === next.selectedTagsFooter,
);
