import {
  adjectives,
  animals,
  colors,
  Config,
  NumberDictionary,
  uniqueNamesGenerator,
} from 'unique-names-generator';

const config: Config = {
  dictionaries: [
    [...adjectives, ...colors],
    animals,
    NumberDictionary.generate(),
  ],
  separator: '.',
  length: 3,
  style: 'capital',
};

const generateUsername = (): string =>
  uniqueNamesGenerator(config).replace(/\./, '');

export default generateUsername;
