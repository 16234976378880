import { GRAPHQL_ENDPOINT } from '@env';
import fetch from 'isomorphic-unfetch';
import { GraphQLResponse, RequestParameters, Variables } from 'relay-runtime';

import { url } from '#utils';

const fetchQuery = async (
  operation: RequestParameters,
  variables: Variables,
): Promise<GraphQLResponse> => {
  const fetchUrl = url(
    process.env.GRAPHQL_ENDPOINT ?? GRAPHQL_ENDPOINT ?? '/api/graphql',
  );
  // TODO: apply the authorization to fetch header
  const res = await fetch(fetchUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }, // Add authentication and other headers here
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  });
  return res.json();
};

export default fetchQuery;
