/* eslint-disable react/destructuring-assignment */
import { ContentBlock, ContentState } from 'draft-js';
import React from 'react';

import Image from '@/Image';
import { BlockType } from '#enum';

const ImageBlock = ({ src }: { src: string }) => {
  if (src) return <Image src={src} accessibilityLabel="" />;
  return null;
};

const Media = ({
  contentState,
  block,
}: {
  contentState: ContentState;
  block: ContentBlock;
}) => {
  const entityKey = block.getEntityAt(0);

  if (entityKey) {
    const entity = contentState.getEntity(entityKey);
    const { src } = entity.getData();
    const type = entity.getType();

    if (type === 'image') return <ImageBlock src={src} />;
  }

  return null;
};

const blockRenderer = (block: ContentBlock): Record<string, unknown> | null => {
  if (block.getType() === BlockType.Atomic) {
    return {
      component: Media,
      editable: false,
    };
  }
  return null;
};

export default blockRenderer;
