/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
type KVObject = { key: string; value: any };

const isObject = (obj: any) => typeof obj === 'object' && !!obj;

/**
 * Compact the array of key/value objects by its key,
 * if the object have the same key and its value is array or object,
 * this function will merge it together, if not, replace with the latest.
 */
const compactKVArray = (arr: (KVObject | undefined | null)[]): KVObject[] => {
  const dict = {} as { [key: string]: any };

  arr.forEach((kv) => {
    if (kv)
      if (dict[kv.key]) {
        const record = dict[kv.key];

        if (Array.isArray(record) && Array.isArray(kv.value)) {
          dict[kv.key] = [...new Set([...record, ...kv.value])].filter(
            (v) => v,
          );
        } else if (isObject(record) && isObject(kv.value)) {
          dict[kv.key] = { ...record, ...kv.value };
        } else {
          dict[kv.key] = kv.value;
        }
      } else {
        dict[kv.key] = kv.value;
      }
  });

  return Object.entries(dict)
    .map(([key, value]) => ({ key, value }))
    .filter(({ value }) => value && !(Array.isArray(value) && !value.length));
};

export default compactKVArray;
