import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(ScrollView)`
  flex: 1 1 100%;
  width: 100%;
`;

export const DocumentContainer = styled.View`
  width: 100%;
  max-width: 800px;
`;

export default null;
