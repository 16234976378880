/* eslint-disable @typescript-eslint/no-explicit-any */
import { Types } from 'mongoose';
import { Disposable, Environment } from 'react-relay';

import getTagDiff from '#database/utils/getTagDiff';
import mergeTagList from '#database/utils/mergeTagList';
import { ObjectType } from '#enum';
import { CBMutationConfig, TagItem, UserTaggedAction } from '#interfaces';
import addRemoveTaggedTags, {
  ObjectOperation,
} from '#mutations/AddRemoveTaggedTags';
import createTags from '#mutations/CreateTags';
import { AddRemovePostTagsMutation } from '~/AddRemovePostTagsMutation.graphql';

export { ObjectOperation };

/**
 * Add/Remove tagged tags if all required data were provided, or else,
 * create the new tags and uses those returned data instead.
 */
const updateTaggedTags = (
  environment: Environment,
  args: {
    userId: string;
    objectType: ObjectType;
    objectId: string;
    prevTagList?: TagItem[] | null;
    newTagList?: TagItem[] | null;
    taggedAction?: UserTaggedAction;
    objectOperation?: ObjectOperation;
  },
  config?: CBMutationConfig<AddRemovePostTagsMutation>,
): Disposable | null => {
  const { validTags, invalidTags } = mergeTagList(
    args.prevTagList,
    args.newTagList,
  );

  const { addedTags, removedTags } = getTagDiff(
    args.prevTagList,
    args.newTagList,
  );

  if (
    !invalidTags.length &&
    validTags.every((tag) => tag.id) &&
    (addedTags.length || removedTags.length)
  )
    return addRemoveTaggedTags(
      environment,
      {
        ...args,
        taggedAction: args.taggedAction,
        allTags: validTags.map((tag) => tag.id as string),
        addedTags: addedTags.map((tag) => tag.id as string),
        removedTags: removedTags.map((tag) => tag.id as string),
      },
      config,
    );

  if (invalidTags.length)
    return createTags(
      environment,
      [...new Set(invalidTags)]
        .filter((tag) => typeof tag.label === 'string')
        .map((tag) => tag.label) as string[],
      {
        onCompleted: (res, err) => {
          if (res.tagForceCreateMany.length && !err) {
            const allTags = [
              ...validTags.map((tag) => tag.id),
              ...res.tagForceCreateMany.map((tag) => tag.id),
            ];

            const addedTagIds = addedTags.map((tag) =>
              tag.id && Types.ObjectId.isValid(tag.id)
                ? tag.id
                : res.tagForceCreateMany.find((t) => t.slug === tag.slug)?.id,
            ) as string[];

            const removedTagIds = removedTags.map((tag) =>
              tag.id && Types.ObjectId.isValid(tag.id)
                ? tag.id
                : res.tagForceCreateMany.find((t) => t.slug === tag.slug)?.id,
            ) as string[];

            addRemoveTaggedTags(
              environment,
              {
                userId: args.userId,
                objectType: args.objectType,
                objectId: args.objectId,
                taggedAction: args.taggedAction,
                objectOperation: args.objectOperation,
                allTags,
                addedTags: addedTagIds,
                removedTags: removedTagIds,
              },
              config,
            );
          }
        },
      },
    );

  return null;
};

export default updateTaggedTags;
