import { AvailableLocale, Locales } from '#interfaces/Locales';

const getLocaleByNameOrBcp47 = (
  locales: Locales,
  nameOrBcp47: string,
): AvailableLocale | undefined =>
  Object.keys(locales).find(
    (localeKey) =>
      localeKey === nameOrBcp47 ||
      locales[localeKey as AvailableLocale]?.bcp47 === nameOrBcp47,
  ) as AvailableLocale | undefined;

export default getLocaleByNameOrBcp47;
