/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

const DocumentParagraph = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
  font-size: 14px;
  margin-vertical: 8px;
`;

export default DocumentParagraph;
