import { Tag } from '#interfaces';

const getTagDiff = (
  prevTags?: Tag[] | null,
  newTags?: Tag[] | null,
): { addedTags: Tag[]; removedTags: Tag[] } => {
  const addedTags = (newTags ?? []).filter((t1) =>
    prevTags?.every((t2) => t1.slug !== t2.slug),
  );
  const removedTags = (prevTags ?? []).filter((t1) =>
    newTags?.every((t2) => t1.slug !== t2.slug),
  );

  return { addedTags, removedTags };
};

export default getTagDiff;
