import React from 'react';

export type ScrollContext = {
  isScrollEnabled: boolean;
  enableScrolling: () => void;
  disableScrolling: () => void;
};

export const ScrollContext = React.createContext<ScrollContext>({
  isScrollEnabled: true,
  enableScrolling: () => null,
  disableScrolling: () => null,
});

type Props = {
  children: React.ReactElement;
};

/**
 * Provide scroll related context which has the scroll state and many tools
 * to manage those state.
 */
const ScrollProvider = ({ children }: Props): React.ReactElement => {
  const [isScrollEnabled, setIsScrollEnabled] = React.useState(true);

  const enableScrolling = React.useCallback(() => setIsScrollEnabled(true), []);

  const disableScrolling = React.useCallback(
    () => setIsScrollEnabled(false),
    [],
  );

  const contextValue = React.useMemo(
    () => ({ isScrollEnabled, enableScrolling, disableScrolling }),
    [disableScrolling, enableScrolling, isScrollEnabled],
  );

  return (
    <ScrollContext.Provider value={contextValue}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
