import constate from 'constate';
import { useCallback, useMemo, useState } from 'react';

import getCommentEnum from '#database/utils/getCommentEnum';
import { SortGetCommentsCommentEnum } from '~~';

const useCommentGlobalHook = () => {
  const [rootCommentId, setRootCommentId] = useState<string | null>();
  const [parentCommentId, setParentCommentId] = useState<string | null>();
  const [collapsedCommentIds, setCollapsedCommentIds] = useState<string[]>([]);
  const [activePostId, setActivePostId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isGoingUp, setIsGoingUp] = useState(false);
  const [canGoUp, setCanGoUp] = useState(false);
  const [feedStatus, setFeedStatus] = useState<
    'noComment' | 'noActiveThread' | undefined
  >();
  const [sort, setSort] = useState<SortGetCommentsCommentEnum>(
    SortGetCommentsCommentEnum.FeaturedScore,
  );

  const collapseComment = useCallback(
    (commentId: string) => {
      if (!collapsedCommentIds.includes(commentId))
        setCollapsedCommentIds((prevValue) => [...prevValue, commentId]);
    },
    [collapsedCommentIds],
  );

  const expandComment = useCallback(
    (commentId: string) => {
      if (collapsedCommentIds.includes(commentId))
        setCollapsedCommentIds((prevValue) =>
          prevValue.filter((id) => id !== commentId),
        );
    },
    [collapsedCommentIds],
  );

  const toggleComment = useCallback(
    (commentId: string) => {
      if (collapsedCommentIds.includes(commentId)) {
        expandComment(commentId);
      } else {
        collapseComment(commentId);
      }
    },
    [collapseComment, collapsedCommentIds, expandComment],
  );

  const reset = useCallback(
    (newActivePostId: string, forceReset?: boolean) => {
      if (forceReset || activePostId !== newActivePostId) {
        setRootCommentId(undefined);
        setCollapsedCommentIds([]);
        setActivePostId(newActivePostId);
      }
    },
    [activePostId],
  );

  const contextValue = useMemo(
    () => ({
      rootCommentId,
      setRootCommentId,
      parentCommentId,
      setParentCommentId,
      activePostId,
      setActivePostId,
      sort,
      setSort,
      featuredSubCommentType: getCommentEnum(sort),
      isLoading,
      setIsLoading,
      isGoingUp,
      setIsGoingUp,
      canGoUp,
      setCanGoUp,
      feedStatus,
      setFeedStatus,
      collapsedCommentIds,
      collapseComment,
      expandComment,
      toggleComment,
      reset,
    }),
    [
      rootCommentId,
      parentCommentId,
      activePostId,
      sort,
      isLoading,
      isGoingUp,
      canGoUp,
      feedStatus,
      collapsedCommentIds,
      collapseComment,
      expandComment,
      toggleComment,
      reset,
    ],
  );

  return contextValue;
};

const [CommentGlobalProvider, useCommentGlobal] = constate(
  useCommentGlobalHook,
);

export { CommentGlobalProvider };
export default useCommentGlobal;
