import { Link } from 'expo-next-react-navigation';
import React from 'react';
import { StyleSheet } from 'react-native';

import Logo from '@/Logo';
import Text from '@/Text';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';

const s = StyleSheet.create({
  text: { paddingStart: 2 },
});

const HeaderLogo = (): React.ReactElement => {
  const [, { lteSm }] = useResponsive();
  const { colors } = useTheme();

  return (
    <Link routeName="Home" web={{ path: '/' }}>
      <Logo color={colors?.accent} size={lteSm ? 24 : undefined} />
      <Text
        color={colors?.orange}
        fontWeight="bolder"
        fontSize="10px"
        style={s.text}
      >
        BETA
      </Text>
    </Link>
  );
};

export default React.memo(HeaderLogo);
