/* eslint-disable camelcase */
module.exports = Object.freeze({
  en_US: Object.freeze({
    bcp47: 'en-US',
    displayName: 'English (US)\u200e',
    englishName: 'English (US)',
    rtl: false,
  }),
  th_TH: Object.freeze({
    bcp47: 'th',
    displayName: 'ไทย',
    englishName: 'Thai',
    rtl: false,
  }),
});
