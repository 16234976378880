/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';

import Button from '@/Button';
import { border, BorderProps, color, ColorProps } from '#styled-system';

export const ButtonsContainer = styled.View`
  flex: 0 1 auto;
  flex-direction: row;
  align-items: stretch;
  margin-top: 15px;
`;

export const SubmitButtonContainer = styled.View`
  flex: 7 1 auto;
  flex-direction: row;
  align-items: flex-start;
`;

export const CancelButtonContainer = styled.View`
  flex: 3 1 auto;
  align-items: flex-end;
`;

export const SubmitButton = styled(Button)<
  ColorProps & BorderProps & { disabled?: boolean }
>`
  background-color: ${(props: any): string =>
    props.disabled ? props.theme.colors.light3 : props.theme.colors.green};
  border-color: ${(props: any): string =>
    props.disabled ? props.theme.colors.blackOpac10 : props.theme.colors.green};
  ${color};
  ${border};
`;

export const CancelButton = styled(Button)<ColorProps & BorderProps>`
  border-color: ${(props: any): string => props.theme.colors.light};
  ${color};
  ${border};
`;

export const SubmitIndicator = styled(ActivityIndicator)`
  align-items: flex-start;
  margin-start: 8px;
  margin-vertical: auto;
`;

export default null;
