/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
`;

export const CloseButton = styled.Pressable`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Label = styled(Text)`
  padding-end: 4px;
  color: ${(props: any) => props.theme.colors.text2};
  font-size: 14px;
`;

export default null;
