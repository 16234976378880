import React from 'react';
import {
  configureFonts,
  DarkTheme,
  DefaultTheme,
  Provider as PaperProvider,
} from 'react-native-paper';
import { ThemeProvider } from 'styled-components/native';

import { userThemeChanged } from '#analytics/app/user';
import { setTheme } from '#cookies';
import { DeviceType, Theme } from '#enum';
import { ThemeProps } from '#interfaces';
import themes from '#styles/themes';
import { fonts } from '#styles/variables';

import ResponsiveProvider from './ResponsiveProvider';

// Create React Context
export type ThemeContext = {
  isDarkTheme: boolean;
  paperTheme: typeof DefaultTheme | undefined;
  toggleDarkTheme: () => void;
};

export const ThemeContext = React.createContext<ThemeContext>({
  isDarkTheme: false,
  paperTheme: undefined,
  toggleDarkTheme: () => {}, // eslint-disable-line
});

// Create React Component (Layer)
type Props = {
  children: React.ReactElement;
  theme?: Theme;
  deviceType?: DeviceType;
  viewerId?: string | null;
};

const fontConfig = {
  web: {
    regular: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    medium: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    light: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    thin: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
  },
  ios: {
    regular: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    medium: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    light: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    thin: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
  },
  android: {
    regular: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    medium: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    light: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
    thin: {
      fontFamily: fonts.based,
      fontWeight: 'normal' as const,
    },
  },
};

export const paperTheme = (
  props: ThemeProps,
  isDark = false,
): typeof DefaultTheme => {
  const theme = isDark ? DarkTheme : DefaultTheme;
  return {
    ...theme,
    animation: {
      ...theme.animation,
      scale: 0.65,
    },
    dark: isDark,
    colors: {
      ...theme.colors,
      ...props.colors,
      primary: props.colors.raisinBlack,
    },
    fonts: configureFonts(fontConfig),
  };
};

const StyleProvider = ({
  children,
  theme,
  deviceType,
  viewerId,
}: Props): React.ReactElement => {
  const [isDarkTheme, setIsDarkTheme] = React.useState(theme === Theme.Dark);
  const themeObject = React.useMemo(
    () => (isDarkTheme ? themes.dark : themes.main),
    [isDarkTheme],
  );
  const rnPaperTheme = React.useMemo(
    () => paperTheme(themeObject, isDarkTheme),
    [isDarkTheme, themeObject],
  );

  const toggleDarkTheme = React.useCallback(async () => {
    const newTheme = isDarkTheme ? Theme.Light : Theme.Dark;

    setTheme(undefined, newTheme, viewerId);
    await userThemeChanged({ newTheme });

    setIsDarkTheme(!isDarkTheme);
  }, [isDarkTheme, viewerId]);

  const contextValue = React.useMemo(
    () => ({
      isDarkTheme,
      paperTheme: rnPaperTheme,
      toggleDarkTheme,
    }),
    [isDarkTheme, rnPaperTheme, toggleDarkTheme],
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={themeObject}>
        <ResponsiveProvider deviceType={deviceType}>
          <PaperProvider theme={rnPaperTheme}>{children}</PaperProvider>
        </ResponsiveProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default StyleProvider;
