import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { CBMutationConfig } from '#interfaces';
import { DeleteTaggedPostMutation } from '~/DeleteTaggedPostMutation.graphql';

const mutation = graphql`
  mutation DeleteTaggedPostMutation($userId: MongoID!, $postId: MongoID!) {
    taggedPostRemoveOne(filter: { userId: $userId, postId: $postId }) {
      recordId
    }
  }
`;

const deleteTaggedPost = (
  environment: Environment,
  args: {
    userId: string;
    postId: string;
  },
  config?: CBMutationConfig<DeleteTaggedPostMutation>,
): Disposable => {
  const variables = args;

  return commitMutation<DeleteTaggedPostMutation>(environment, {
    ...config,
    mutation,
    variables,
  });
};

export default deleteTaggedPost;
