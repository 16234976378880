import React from 'react';

import { LogoText } from './Logo.style';

type Props = {
  color?: string;
  size?: number | string;
};

/**
 * Render Lpaydat Logo.
 */
const Logo = ({ color, size }: Props): React.ReactElement => (
  <LogoText color={color} fontSize={size}>
    lpaydat
  </LogoText>
);

export default React.memo(Logo);
