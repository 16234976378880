import { StoredSuggestedTags, StoredTags } from '#interfaces';

import AsyncStorage from './AsyncStorage';

export default class TagStorage {
  static storage = AsyncStorage;

  static setTags(payload: StoredTags): Promise<void | null> {
    return this.storage.set('storedTags', payload);
  }

  static getTags(): Promise<StoredTags | null> {
    return this.storage.get('storedTags');
  }

  static async setSuggestedTags(
    payload: StoredSuggestedTags,
    viewerId?: string,
  ): Promise<void | null> {
    if (viewerId) {
      const storedSuggestedTags = await this.getSuggestedTags();
      return this.storage.set('suggestedTags', {
        ...storedSuggestedTags,
        [viewerId]: payload,
      });
    }
  }

  static getSuggestedTags(): Promise<{
    [key: string]: StoredSuggestedTags;
  } | null> {
    return this.storage.get('suggestedTags');
  }

  static removeSuggestedTags(): Promise<void | null> {
    return this.storage.remove('suggestedTags');
  }
}
