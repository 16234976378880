import styled from 'styled-components/native';

import Text from '@/Text';
import { color, ColorProps } from '#styled-system';

export const Container = styled.View``;

export const NoTagContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-top: 8px;
`;

export const Message = styled(Text)<ColorProps>`
  color: #888;
  font-size: 13px;
  ${color};
`;

export const SelectedTagsContainer = styled.View`
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 38px;
`;

export const MessageContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 30px;
`;

export const SuggestedTagsLabelContainer = styled.View`
  margin: 20px 0 8px;
`;

export const SuggestedTagsLabel = styled(Text)`
  color: #666;
  font-weight: bold;
  font-size: 12px;
`;

export default null;
