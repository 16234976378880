import React from 'react';

import { Button, Label } from './RoundButton.style';

type Props = {
  children: React.ReactNode;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const RoundButton = ({
  children,
  color,
  backgroundColor,
  disabled,
  onPress,
}: Props): React.ReactElement => (
  <Button
    backgroundColor={backgroundColor}
    onPress={disabled ? undefined : onPress}
  >
    <Label color={color}>{children}</Label>
  </Button>
);

export default RoundButton;
