import XRegExp from 'xregexp';

const notAllowedChar = XRegExp('[^\\p{L}\\p{M}\\p{N}-]+', 'g');

export const createTagLabel = (label: string): string =>
  label.trim().toLocaleLowerCase().replace(/\s+/gm, ' ');

export const createTagSlug = (label: string): string =>
  label
    .toLocaleLowerCase()
    .replace(/[ \-_.]+/g, '-')
    .replace(notAllowedChar, '.')
    .replace(/(^[-.]+|[-.]+$)/g, '');

export default null;
