import React from 'react';
import {
  NativeSyntheticEvent,
  TextInput as NativeTextInput,
  TextInputContentSizeChangeEventData,
  TextInputFocusEventData,
  TextInputProps,
} from 'react-native';

import useTheme from '#hooks/useTheme';
import { BorderProps, ColorProps } from '#styled-system';

import getOptimalTextareaHeight, {
  getLineHeight,
} from './getOptimalTextareaHeight';
import { CustomTextInput } from './TextInput.style';

type Props = {
  size?: 's' | 'm' | 'l';
  maxLines?: number;
  focus?: boolean;
} & TextInputProps &
  BorderProps &
  ColorProps;

/**
 * `TextInput` with customized styles and auto expanding support.
 */
const TextInput = ({
  onFocus,
  onBlur,
  focus,
  style,
  ...props
}: Props): React.ReactElement => {
  const { colors } = useTheme();
  const textInputRef = React.useRef<NativeTextInput>(null);
  const [isFocused, setIsFocused] = React.useState(focus);
  const [boxHeight, setBoxHeight] = React.useState<number>();
  const initHeight = React.useRef<number>();
  const { multiline, numberOfLines = 4, maxLines = 10 } = props;

  const mergedStyle = React.useMemo(
    () => [
      focus ?? isFocused
        ? {
            borderColor: colors?.form?.input.border,
            outline: 0,
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,0.25)',
          }
        : undefined,
      multiline ? { height: boxHeight } : undefined,
      style,
    ],
    [boxHeight, colors?.form?.input.border, focus, isFocused, multiline, style],
  );

  const handleFocus = React.useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onFocus?.(e);
      setIsFocused(true);
    },
    [onFocus],
  );

  const handleBlur = React.useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onBlur?.(e);
      setIsFocused(false);
    },
    [onBlur],
  );

  const handleContentSizeChange = React.useCallback(
    ({
      nativeEvent: {
        contentSize: { height },
      },
    }: NativeSyntheticEvent<TextInputContentSizeChangeEventData>): void => {
      if (!initHeight.current && height <= numberOfLines * 30)
        initHeight.current = height;
      if (multiline && initHeight.current && initHeight.current !== height) {
        const [lineHeight, extraHeight] = !boxHeight
          ? getLineHeight(numberOfLines, initHeight.current, height)
          : [];

        const textareaHeight = getOptimalTextareaHeight(
          height,
          numberOfLines,
          maxLines,
          lineHeight,
          extraHeight,
        );
        setBoxHeight(textareaHeight);
      }
    },
    [boxHeight, maxLines, multiline, numberOfLines],
  );

  React.useEffect(() => {
    if (isFocused) textInputRef.current?.focus();
  }, [isFocused]);

  return (
    <CustomTextInput
      {...props}
      ref={textInputRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onContentSizeChange={multiline ? handleContentSizeChange : undefined}
      placeholderTextColor={colors?.placeholder}
      style={mergedStyle}
    />
  );
};

export default React.memo(TextInput);
