import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CreateNewPostButton from '@/CreateNewPostButton';
import DrawerButton from '@/DrawerButton';
import HeaderLogo from '@/HeaderLogo';
import HeaderRightSection from '@/HeaderRightSection';
import useResponsive from '#hooks/useResponsive';
import useSidebar from '#hooks/useSidebar';
import { Header_viewer$key } from '~/Header_viewer.graphql';

import { AppbarHeader, HeaderContainer, Logo } from './Header.style';

const viewerFragment = graphql`
  fragment Header_viewer on User {
    id
    ...CreateNewPostButton_viewer
    ...HeaderRightSection_viewer
  }
`;

type Props = {
  viewer: Header_viewer$key | null;
};

/**
 * Header component, uses for contain header things (logo, search box, menu, ...).
 *
 * Header will always stick on the very top position and extend to full width.
 */
const Header = ({ viewer }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  const [, { xl }] = useResponsive();
  const { hasSidebar } = useSidebar();
  const hasDrawer = !xl && hasSidebar;

  return (
    <HeaderContainer>
      <AppbarHeader>
        {hasDrawer && <DrawerButton />}
        <Logo title={<HeaderLogo />} ms={hasDrawer ? 0 : undefined} />
        {viewerData?.id && <CreateNewPostButton viewer={viewerData} />}
        <HeaderRightSection viewer={viewerData} />
      </AppbarHeader>
    </HeaderContainer>
  );
};

export default React.memo(Header);
