/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type CreateOnePostInput = {
    status?: DocumentStatusInput | null;
    version?: string | null;
    type?: string | null;
    title: string;
    content: string;
    previewContent?: Array<string | null> | null;
    plainContent?: string | null;
    featureCommentIds?: Array<string | null> | null;
    vote?: PostVoteInput | null;
    count?: PostCountInput | null;
    tagCollections?: PostTagCollectionsInput | null;
    featuredScore?: number | null;
    wilsonScore?: number | null;
    hotScore?: number | null;
    scoreInfo?: PostScoreInfoInput | null;
    createdDate?: string | null;
    createdByUserId: string;
    lastModifiedDate?: string | null;
    lastModifiedByUserId?: string | null;
    settings?: Array<KeyValueInput | null> | null;
    resource?: Array<KeyValueInput | null> | null;
    meta?: Array<KeyValueInput | null> | null;
};
export type DocumentStatusInput = {
    value?: EnumDocumentStatusValue | null;
    reason?: string | null;
    timestamp?: string | null;
};
export type PostVoteInput = {
    upvotes?: number | null;
    downvotes?: number | null;
};
export type PostCountInput = {
    shared?: number | null;
    tagged?: number | null;
    taggedTags?: number | null;
    totalComments?: number | null;
    activeRootComments?: number | null;
};
export type PostTagCollectionsInput = {
    taggedTags?: Array<TagItemInput | null> | null;
};
export type TagItemInput = {
    tagId?: string | null;
    value?: number | null;
    date?: string | null;
};
export type PostScoreInfoInput = {
    lastUpdatedDate?: string | null;
};
export type KeyValueInput = {
    key: string;
    value?: unknown | null;
};
export type CreatePostMutationVariables = {
    record: CreateOnePostInput;
};
export type CreatePostMutationResponse = {
    readonly postCreateOne: {
        readonly record: {
            readonly id: string;
            readonly title: string;
            readonly content: string;
            readonly meta: ReadonlyArray<{
                readonly key: string;
                readonly value: unknown | null;
            } | null> | null;
        } | null;
    } | null;
};
export type CreatePostMutation = {
    readonly response: CreatePostMutationResponse;
    readonly variables: CreatePostMutationVariables;
};



/*
mutation CreatePostMutation(
  $record: CreateOnePostInput!
) {
  postCreateOne(record: $record) {
    record {
      id
      title
      content
      meta {
        key
        value
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "CreateOnePostPayload",
    "kind": "LinkedField",
    "name": "postCreateOne",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValue",
            "kind": "LinkedField",
            "name": "meta",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePostMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePostMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "75e180938296191f8fa8f2e70fc94abe",
    "id": null,
    "metadata": {},
    "name": "CreatePostMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePostMutation(\n  $record: CreateOnePostInput!\n) {\n  postCreateOne(record: $record) {\n    record {\n      id\n      title\n      content\n      meta {\n        key\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b554f83696fa1f41a5162362d787ad2b';
export default node;
