/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu as PaperMenu } from 'react-native-paper';
import styled from 'styled-components/native';

export const Menu = styled(PaperMenu)`
  position: fixed;
  top: ${(props: any): string =>
    `${(props.theme.componentSizes?.headerHeight ?? 40) - 2}px`};
`;

export default null;
