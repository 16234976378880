/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  zIndex,
  ZIndexProps,
} from '#styled-system';

export const Container = styled.View<FlexboxProps & ZIndexProps & SpaceProps>`
  z-index: 1;
  margin-bottom: 16px;
  ${flexbox};
  ${zIndex};
  ${space};
`;

export const Label = styled(Text)`
  margin-bottom: 8px;
  color: ${(props: any) => props.theme.colors.text};
  font-weight: bold;
`;

export const InputContainer = styled.View`
  display: block;
`;

export const Hint = styled(Text)`
  margin-top: 4px;
  color: ${(props: any) => props.theme.colors.gray};
`;

export const Error = styled(Text)`
  margin-top: 4px;
  color: ${(props: any) => props.theme.colors.danger};
`;

export default null;
