import { commitMutation, Environment, graphql } from 'react-relay';

import { CBMutationConfig } from '#interfaces';
import {
  CreateOnePostInput,
  CreatePostMutation,
} from '~/CreatePostMutation.graphql';

const mutation = graphql`
  mutation CreatePostMutation($record: CreateOnePostInput!) {
    postCreateOne(record: $record) {
      record {
        id
        title
        content
        meta {
          key
          value
        }
      }
    }
  }
`;

const createPost = (
  environment: Environment,
  content: CreateOnePostInput,
  config?: CBMutationConfig<CreatePostMutation>,
): void => {
  const variables = { record: content };

  commitMutation<CreatePostMutation>(environment, {
    ...config,
    mutation,
    variables,
  });
};

export default createPost;
