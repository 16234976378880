import { MaterialCommunityIcons } from '@expo/vector-icons';
import fbt from 'fbt';
import React from 'react';

import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';

import { CloseButton, Container, Label } from './ModalCloseButton.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  onPress?: () => void;
};

const ModalCloseButton = ({ onPress }: Props) => {
  useFbt();

  const { colors } = useTheme();

  return (
    <Container>
      <CloseButton onPress={onPress}>
        <Label>
          <fbt desc="button label">Close</fbt>
        </Label>
        <MaterialCommunityIcons name="close" size={20} color={colors?.text2} />
      </CloseButton>
    </Container>
  );
};

export default React.memo(ModalCloseButton);
