/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { CBMutationConfig } from '#interfaces';
import {
  UpdateByIdPostInput,
  UpdatePostMutation,
} from '~/UpdatePostMutation.graphql';

const mutation = graphql`
  mutation UpdatePostMutation(
    $postId: MongoID!
    $newPost: UpdateByIdPostInput!
  ) {
    postUpdateById(_id: $postId, record: $newPost) {
      record {
        id
        version
        title
        previewContent
        content
        lastModifiedDate
        settings {
          key
          value
        }
        meta {
          key
          value
        }
      }
    }
  }
`;

const updatePost = (
  environment: Environment,
  postId: string,
  newPost: UpdateByIdPostInput,
  config?: CBMutationConfig<UpdatePostMutation>,
): Disposable => {
  const variables = {
    postId,
    newPost,
  };

  return commitMutation<UpdatePostMutation>(environment, {
    ...config,
    mutation,
    variables,
    optimisticResponse: {
      postUpdateById: {
        record: {
          id: postId,
          version: newPost.version ?? '',
          title: newPost.title ?? '',
          previewContent: newPost.previewContent ?? null,
          content: newPost.content ?? '',
          lastModifiedDate: new Date().toISOString(),
          settings: null,
          meta: null,
        },
      },
    },
  });
};

export default updatePost;
