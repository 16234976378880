import React from 'react';
import { TextStyle } from 'react-native';

import {
  ColorProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
} from '#styled-system';

import { Label, PaperButton } from './Button.style';

type PaperButtonProps = React.ComponentProps<typeof PaperButton>;

type Props = {
  labelStyle?: TextStyle & TypographyProps & LayoutProps & SpaceProps;
} & Omit<PaperButtonProps, 'labelStyle'> &
  LayoutProps &
  SpaceProps &
  ColorProps;

const Button = ({ children, labelStyle, ...props }: Props) => (
  <PaperButton {...props}>
    <Label {...labelStyle}>{children}</Label>
  </PaperButton>
);

export default React.memo(Button);
