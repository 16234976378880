export enum MetaKey {
  PreviewContentPoints = 'PreviewContentPoints',
  ContentMedia = 'ContentMedia',
  ContentLanguages = 'ContentLanguages',
}

export enum MetaContentMedia {
  Image = 'Image',
  Text = 'Text',
  Video = 'Video',
  Sound = 'Sound',
  Link = 'Link',
}

export default null;
