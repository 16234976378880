/* stylelint-disable selector-max-class */
/* stylelint-disable selector-max-type */
/* stylelint-disable selector-max-attribute */
import { css } from 'styled-components/macro';

import d from '#styles/themes/dark';
import m from '#styles/themes/main';

const draftJsBlockStyles = css`
  [data-fontsize='16'] h1 {
    font-size: 24px;
  }

  [data-fontsize='16'] h2 {
    font-size: 22px;
  }

  [data-fontsize='16'] h3 {
    font-size: 18px;
  }

  [data-fontsize='16'] h4 {
    font-size: 16px;
  }

  [data-fontsize='16'] h5 {
    font-size: 12px;
  }

  [data-fontsize='16'] h6 {
    font-size: 10px;
  }

  [data-fontsize='16'] {
    font-size: 16px;
  }

  [data-fontsize='14'] h1 {
    font-size: 24px;
  }

  [data-fontsize='14'] h2 {
    font-size: 22px;
  }

  [data-fontsize='14'] h3 {
    font-size: 18px;
  }

  [data-fontsize='14'] h4 {
    font-size: 14px;
  }

  [data-fontsize='14'] h5 {
    font-size: 12px;
  }

  [data-fontsize='14'] h6 {
    font-size: 10px;
  }

  [data-fontsize='14'] {
    font-size: 14px;
  }

  [data-draftbase='true'] .editor-default-unstyled {
    line-height: 150%;
  }

  /* Editor */
  [data-display='Editor'] .DraftEditor-editorContainer,
  [data-display='Editor'] .public-DraftEditorPlaceholder-root {
    margin: 0;
    padding: 2px 10px;
    line-height: 150%;
  }

  [data-display='Editor'] .public-DraftEditorPlaceholder-root {
    position: absolute;
  }

  [data-display='Editor'] .public-DraftEditorPlaceholder-inner {
    margin: 0;
    padding: 8px 0;
    color: ${m.colors.placeholder};
    line-height: 150%;
  }

  [data-display='Editor'] h1,
  [data-display='Editor'] h2,
  [data-display='Editor'] h3,
  [data-display='Editor'] h4,
  [data-display='Editor'] h5,
  [data-display='Editor'] h6,
  [data-display='Editor'] figure,
  [data-display='Editor'] .editor-default-unstyled {
    margin: 0;
    padding: 8px 0;
  }

  /* Content */
  [data-display='PostContent'] h1,
  [data-display='PostContent'] h2,
  [data-display='PostContent'] h3,
  [data-display='PostContent'] h4,
  [data-display='PostContent'] h5,
  [data-display='PostContent'] h6,
  [data-display='PostContent'] .editor-default-unstyled {
    margin: 16px;
  }

  [data-display='PostContent'] figure {
    margin: 16px 0 0;
  }

  [data-display='CommentContent'] h1,
  [data-display='CommentContent'] h2,
  [data-display='CommentContent'] h3,
  [data-display='CommentContent'] h4,
  [data-display='CommentContent'] h5,
  [data-display='CommentContent'] h6,
  [data-display='CommentContent'] .editor-default-unstyled {
    margin: 10px 0 8px;
  }

  [data-display='CommentContent'] figure {
    margin: 10px 0 0;
  }

  [data-display='Editor'] figure > img,
  [data-display='CommentContent'] figure > img,
  [data-display='PostContent'] figure > img {
    width: 100%;
  }
`;

export const darkDraftJSBlockStyles = css`
  [data-display='Editor'] .public-DraftEditorPlaceholder-inner {
    color: ${d.colors.text3};
  }
`;

export default draftJsBlockStyles;
