/* eslint-disable @typescript-eslint/no-explicit-any */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

import {
  AsyncStorageModel,
  AsyncStorageModelKeys,
  Distribute,
} from '#interfaces';
import { isJson, parseValue } from '#utils';

/**
 * Check for the custom `AsyncStorage` availability.
 */
export const isStorageAvailable = (): boolean =>
  Platform.OS === 'web' ? typeof window !== 'undefined' : true;

// TODO: add `opts` as `WebStorage` do
export default class Storage {
  static async get<K extends AsyncStorageModelKeys>(
    key: K,
  ): Promise<AsyncStorageModel[K] | null> {
    if (!isStorageAvailable()) return null;
    const value = await AsyncStorage.getItem(key);
    return parseValue(value);
  }

  static async set<K extends AsyncStorageModelKeys>(
    key: K,
    value: AsyncStorageModel[K],
  ): Promise<void | null> {
    if (!isStorageAvailable()) return null;
    return AsyncStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Merge data to the existing data under the same key, nothing happen if the provided data is not an object.
   */
  static async merge<K extends AsyncStorageModelKeys>(
    key: K,
    value: AsyncStorageModel[K],
  ): Promise<void | null> {
    if (!isStorageAvailable()) return null;
    const json = JSON.stringify(value);
    return isJson(json) ? AsyncStorage.mergeItem(key, json) : null;
  }

  static async remove(key: AsyncStorageModelKeys): Promise<void | null> {
    if (!isStorageAvailable()) return null;
    return AsyncStorage.removeItem(key);
  }

  static async getMultiple<K extends AsyncStorageModelKeys>(
    keys: K[],
  ): Promise<{ [k in K]: AsyncStorageModel[k] | null } | null> {
    if (!isStorageAvailable()) return null;
    const res = {} as { [k in K]: AsyncStorageModel[k] };
    let values;

    try {
      values = (await AsyncStorage.multiGet(keys)) as [string, string][];
    } catch (err) {
      throw new Error(err);
    }

    values.forEach((arr) => {
      const [k, v] = arr as [K, string];
      res[k] = parseValue(v);
    });

    return res;
  }

  static async setMultiple(
    keyValuePairs: Distribute<AsyncStorageModelKeys, AsyncStorageModel>[],
  ): Promise<void | null> {
    if (!isStorageAvailable()) return null;
    const formattedKV = keyValuePairs.map((kv) => {
      // TODO: correct below variable type
      const key = Object.keys(kv)?.[0] as never;
      return [key, JSON.stringify(kv[key])];
    });
    return AsyncStorage.multiSet(formattedKV);
  }

  static async mergeMultiple(
    keyValuePairs: Distribute<AsyncStorageModelKeys, AsyncStorageModel>[],
  ): Promise<void | null> {
    if (!isStorageAvailable()) return null;
    let formattedKV: string[][] = [];
    keyValuePairs.forEach((kv) => {
      const key = Object.keys(kv)?.[0] as never;
      const json = JSON.stringify(kv[key]);
      if (isJson(json)) formattedKV = [...formattedKV, [key, json]];
    });
    return AsyncStorage.multiMerge(formattedKV);
  }

  static async removeMultiple(
    keys: AsyncStorageModelKeys[],
  ): Promise<void | null> {
    if (!isStorageAvailable()) return null;
    return AsyncStorage.multiRemove(keys);
  }

  static async getKeys(): Promise<AsyncStorageModelKeys[] | null> {
    if (!isStorageAvailable()) return null;
    return AsyncStorage.getAllKeys() as Promise<AsyncStorageModelKeys[]>;
  }

  static async clearStorage(): Promise<void | null> {
    if (!isStorageAvailable()) return null;
    return AsyncStorage.clear();
  }
}
