import { ImageSize } from '#enum';

export const imageSize = Object.freeze({
  [ImageSize.Original]: {
    size: Infinity,
    quality: 1,
  },
  [ImageSize.Avatar]: {
    size: 200,
    quality: 0.8,
  },
  [ImageSize.Thumbnail]: {
    size: 48,
    quality: 0.6,
  },
  [ImageSize.Medium]: {
    size: 550,
    quality: 0.8,
  },
  [ImageSize.PX720]: {
    size: 720,
    quality: 0.9,
  },
  [ImageSize.PX1080]: {
    size: 1080,
    quality: 0.9,
  },
});

export default null;
