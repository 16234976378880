import React from 'react';
import isEqual from 'react-fast-compare';

import {
  Container,
  Error,
  Hint,
  InputContainer,
  Label,
} from './FormFieldBox.style';

type Props = {
  label: string;
  children: React.ReactNode;
  hint?: string;
  error?: string | React.ReactElement;
  zIndex?: number;
};

const FormFieldBox = ({
  children,
  label,
  hint,
  error,
  zIndex,
}: Props): React.ReactElement => (
  <Container zIndex={zIndex}>
    <Label>{label}</Label>
    <InputContainer>{children}</InputContainer>
    {hint && !error && <Hint>{hint}</Hint>}
    {error && <Error>{error}</Error>}
  </Container>
);

export default React.memo(FormFieldBox, (prev, next) =>
  isEqual(prev.error, next.error),
);
