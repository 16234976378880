import FbtHooks from 'fbt/lib/FbtHooks';
import FbtTranslations from 'fbt/lib/FbtTranslations';
import React from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

import { AvailableLocale } from '#interfaces/Locales';

import changeLocale from './changeLocale';
import isRTL from './isRTL';
import LocaleContext from './LocaleContext';

// TODO: add locale mutation
const LocaleProvider = ({
  children,
  translations,
  viewerContext,
}: {
  children: React.ReactElement;
  translations?: FBT.Translations;
  viewerContext?: FBT.IntlViewerContext;
}): React.ReactElement => {
  const viewerContextMemo = useDeepCompareMemo(() => viewerContext, [
    viewerContext,
  ]);
  const isFirstRender = React.useRef(true);
  const [locale, setLocaleState] = React.useState(
    viewerContextMemo?.locale as AvailableLocale | undefined,
  );
  const [gender, setGenderState] = React.useState(
    viewerContextMemo?.GENDER as FBT.IntlVariations | undefined,
  );

  if (isFirstRender.current) {
    FbtTranslations.registerTranslations(translations ?? {});
    FbtHooks.register({
      getViewerContext: () => viewerContextMemo,
    });
  }

  const setLocale = React.useCallback(
    async (newLocale?: AvailableLocale): Promise<void> => {
      if (newLocale !== locale || !locale) {
        const res = await changeLocale(newLocale);
        if (res !== locale) setLocaleState(res);
      }
    },
    [locale],
  );

  const setGender = React.useCallback(
    async (newGender?: FBT.IntlVariations): Promise<void> => {
      if (newGender !== gender || !gender) {
        FbtHooks.register({
          getViewerContext: () => ({
            GENDER: newGender,
          }),
        });
        setGenderState(newGender);
      }
    },
    [gender],
  );

  // React.useEffect(() => {
  //   // if (isRendered.current) setLocale(locale);
  //   isFirstRender.current = false;

  //   setTimeout(() => {
  //     setLocale('th_TH');
  //   }, 5000);
  // }, [locale, setLocale]);

  const contextValue = React.useMemo(
    () => ({
      viewerContextMemo,
      isRTL: locale && isRTL(locale),
      locale,
      gender,
      setLocale,
      setGender,
    }),
    [gender, locale, setGender, setLocale, viewerContextMemo],
  );

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
