import React from 'react';
import FitImage from 'react-native-fit-image';

type Props = {
  src: string;
} & Omit<React.ComponentProps<typeof FitImage>, 'source'>;

const Image = ({ src, ...props }: Props): React.ReactElement => (
  <FitImage {...props} source={{ uri: src }} />
);
export default React.memo(Image);
