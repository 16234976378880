import { AntDesign, Ionicons, MaterialIcons } from '@expo/vector-icons';
import fbt from 'fbt';
import fetch from 'isomorphic-unfetch';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { Button, ToggleButton } from 'react-native-paper';
import { graphql, useFragment } from 'react-relay';

import TextInput from '@/TextInput';
import useTheme from '#hooks/useTheme';
import { FeedbackForm_viewer$key } from '~/FeedbackForm_viewer.graphql';
import {
  slackWebhookUrlBugReports,
  slackWebhookUrlFeatureRequests,
  slackWebhookUrlFeedback,
} from '~@';

import {
  ButtonContainer,
  Container,
  Hint,
  Title,
  TypeContainer,
} from './FeedbackForm.style';

const viewerFragment = graphql`
  fragment FeedbackForm_viewer on User {
    id
    fullName
    emails {
      emailAddress
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type FormValues = {
  feedbackType: 'bugReport' | 'featureRequest' | 'generalFeedback';
  message: string;
};

type Props = {
  viewer: FeedbackForm_viewer$key;
  screenName?: string;
};

const iconSize = 20;
const defaultFeedbackType = 'featureRequest';

const FeedbackForm = ({ viewer, screenName }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  const { control, setValue, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      feedbackType: defaultFeedbackType,
      message: '',
    },
  });
  const [feedbackType, setFeedbackType] = React.useState<
    FormValues['feedbackType']
  >(defaultFeedbackType);
  const { colors, isDarkTheme } = useTheme();

  const iconColor = isDarkTheme ? colors?.text : colors?.text0;
  const typeButtonStyle = React.useMemo(
    () => ({
      width: '60px',
      height: '36px',
      borderColor: isDarkTheme ? colors?.light : colors?.blackOpac,
    }),
    [colors?.blackOpac, colors?.light, isDarkTheme],
  );

  const featureIcon = React.useCallback(
    () => <AntDesign name="star" size={iconSize} color={iconColor} />,
    [iconColor],
  );

  const bugIcon = React.useCallback(
    () => <Ionicons name="bug" size={iconSize} color={iconColor} />,
    [iconColor],
  );

  const feedbackIcon = React.useCallback(
    () => <MaterialIcons name="feedback" size={iconSize} color={iconColor} />,
    [iconColor],
  );

  const title = React.useMemo(() => {
    switch (feedbackType) {
      case 'featureRequest':
        return fbt('Feature request', 'feedback type');
      case 'bugReport':
        return fbt('Bug report', 'feedback type');
      default:
        return fbt('Feedback', 'feedback type');
    }
  }, [feedbackType]);

  const placeholder = React.useMemo(() => {
    switch (feedbackType) {
      case 'featureRequest':
        return fbt(
          'I want this features, it would be awesome to have it in Lpaydat...',
          'textinput placeholder',
        );
      case 'bugReport':
        return fbt(
          "I found a bug, I will give you it's details. Fix it...",
          'textinput placeholder',
        );
      default:
        return `${fbt(
          'I will give you some feedback, ...thanks me.',
          'textinput placeholder',
        )}\n${fbt(
          '(Thank you very much, your feedback is very important to us).',
          'textinput placeholder',
        )}`;
    }
  }, [feedbackType]);

  const s = React.useMemo(
    () =>
      StyleSheet.create({
        buttonLabelStyle: { color: colors?.white },
      }),
    [colors?.white],
  );

  const handleValueChange = React.useCallback(
    (value: string) => {
      setValue('feedbackType', value as FormValues['feedbackType']);
      setFeedbackType(value as FormValues['feedbackType']);
    },
    [setValue],
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      if (!values.message) {
        const screenInfo = `Reported from screen: ${screenName}`;
        const userId = `User ID: ${viewerData.id}`;
        const userFullName = `User full name: ${viewerData.fullName}`;
        const userEmail = `User email: ${
          viewerData.emails?.length ? viewerData.emails[0]?.emailAddress : ''
        }`;

        const userInfo = `${screenInfo}\n${userId}\n${userFullName}\n${userEmail}`;
        const payload = {
          method: 'POST',
          body: JSON.stringify({ text: `${values.message}\n\n${userInfo}` }),
        };

        if (
          slackWebhookUrlFeedback &&
          slackWebhookUrlFeatureRequests &&
          slackWebhookUrlBugReports
        )
          switch (values.feedbackType) {
            case 'featureRequest':
              await fetch(slackWebhookUrlFeatureRequests, payload);
              break;
            case 'bugReport':
              await fetch(slackWebhookUrlBugReports, payload);
              break;
            default:
              await fetch(slackWebhookUrlFeedback, payload);
              break;
          }
      }
    },
    [screenName, viewerData.emails, viewerData.fullName, viewerData.id],
  );

  return (
    <Container>
      <Controller
        control={control}
        name="feedbackType"
        render={({ value }) => (
          <TypeContainer>
            <ToggleButton.Row value={value} onValueChange={handleValueChange}>
              <ToggleButton
                icon={featureIcon}
                value="featureRequest"
                style={typeButtonStyle}
              />
              <ToggleButton
                icon={bugIcon}
                value="bugReport"
                style={typeButtonStyle}
              />
              <ToggleButton
                icon={feedbackIcon}
                value="generalFeedback"
                style={typeButtonStyle}
              />
            </ToggleButton.Row>
            <Title>{title}</Title>
          </TypeContainer>
        )}
      />
      <Controller
        control={control}
        name="message"
        render={({ onChange, onBlur, value }) => (
          <TextInput
            multiline
            placeholder={placeholder}
            numberOfLines={4}
            maxLines={10}
            value={value}
            onChangeText={onChange}
            onBlur={onBlur}
          />
        )}
      />
      <Hint>
        <fbt desc="thank you message">
          We really appreciate for your support and contribution. Every
          feedbacks, both positive and negative, are very important to us. Thank
          you so much for giving us feedback!
        </fbt>
      </Hint>
      <ButtonContainer>
        <Button
          mode="contained"
          color={colors?.blue2}
          labelStyle={s.buttonLabelStyle}
          onPress={handleSubmit(onSubmit)}
        >
          <fbt desc="send feedback">Send</fbt>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default React.memo(FeedbackForm);
