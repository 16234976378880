/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type HeaderViewerSection_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"HeaderViewerInfo_viewer">;
    readonly " $refType": "HeaderViewerSection_viewer";
};
export type HeaderViewerSection_viewer$data = HeaderViewerSection_viewer;
export type HeaderViewerSection_viewer$key = {
    readonly " $data"?: HeaderViewerSection_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"HeaderViewerSection_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderViewerSection_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderViewerInfo_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '183743910a43555c85457e0b78621a2b';
export default node;
