import { Types } from 'mongoose';

import { botId, placeholderId } from './systemObjectIds';

const isValidViewerId = (viewerId = ''): boolean =>
  Types.ObjectId.isValid(viewerId) &&
  viewerId !== botId &&
  viewerId !== placeholderId;

export default isValidViewerId;
