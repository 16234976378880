import { logEvent } from 'expo-firebase-analytics';

import { BasicPostProps } from '#interfaces/Analytics';

import formatter from '../../helpers/eventPropsFormatter';

type Props = BasicPostProps;

export const postCreated = (props: Props): Promise<void> =>
  logEvent('app:post_created', formatter(props));

export default postCreated;
