/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card } from 'react-native-paper';
import styled from 'styled-components/native';

import {
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  maxWidth,
  MaxWidthProps,
} from '#styled-system';

export const Container = styled.View<MaxWidthProps>`
  width: 100%;
  max-width: 325px;
  margin: 6px auto 6px 0;
  ${maxWidth};
`;

export const SearchResultsContainer = styled(Card)<
  BorderProps & BoxShadowProps
>`
  position: absolute;
  top: 44px;
  z-index: 99;
  width: 100%;
  padding: 6px 0;
  border-radius: 4px;
  ${border};
  ${boxShadow};
`;

export default null;
