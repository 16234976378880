import merge from 'deepmerge';
import { DarkTheme } from 'react-native-paper';

import main from './main';

const dark = merge(main, {
  colors: {
    primary: '#1a1b1c',
    accent: '#cacbcc',

    // role colors
    text0: DarkTheme.colors.text,
    text: 'rgba(255, 255, 255, 0.78)',
    text1: 'rgba(255, 255, 255, 0.6)',
    text2: 'rgba(255, 255, 255, 0.5)',
    text3: 'rgba(255, 255, 255, 0.38)',
    text4: 'rgba(255, 255, 255, 0.3)',
    placeholder: DarkTheme.colors.placeholder,
    pageBackground: '#151617',
    surface: DarkTheme.colors.surface,

    // general colors
    green: '#20602f',
    gray: '#707070',
    red2: '#cc3232',
    red3: '#ab0909',
    yellow2: '#dab941',
    light: '#3a3a3a',
    light2: '#363636',
    light3: '#121314',
    light4: '#1d1e1f',

    blackOpac: 'rgba(255, 255, 255, 0.3)',
    blackOpac25: 'rgba(255, 255, 255, 0.25)',
    blackOpac20: 'rgba(255, 255, 255, 0.20)',
    blackOpac10: 'rgba(255, 255, 255, 0.1)',
    blackOpac08: 'rgba(255, 255, 255, 0.08)',
    blackOpac05: 'rgba(255, 255, 255, 0.05)',
    blackOpac04: 'rgba(255, 255, 255, 0.04)',

    floatBox: {
      backgroundColor: '#2e2e2e',
      border: 'solid 1px #2e2e2e',
      boxShadow: undefined,
    },

    tag: {
      blue: {
        label: '#9bc1e0',
        backgroundColor: '#1c252c',
      },
    },
  },
});

export default dark;
