import { MaterialCommunityIcons } from '@expo/vector-icons';
import fbt from 'fbt';
import React from 'react';
import { Pressable } from 'react-native';
import { Appbar } from 'react-native-paper';

import useFbt from '#hooks/useFbt';
import useHeaderIconSize from '#hooks/useHeaderIconSize';
import useModals from '#hooks/useModals';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';

import { Button, ButtonContainer } from './HeaderSignUpLogin.style';

// eslint-disable-next-line no-unused-expressions
fbt;

const HeaderSignUpLogin = () => {
  useFbt();

  const { colors } = useTheme();
  const iconSize = useHeaderIconSize();
  const [, { lteSm, md }] = useResponsive();
  const { openSignUpLoginModal } = useModals();

  return lteSm ? (
    <Appbar.Action
      icon="login-variant"
      color={colors?.accent}
      onPress={openSignUpLoginModal}
    />
  ) : (
    <Pressable onPress={openSignUpLoginModal}>
      <ButtonContainer>
        <MaterialCommunityIcons
          name="login-variant"
          size={iconSize}
          color={colors?.accent}
        />
        <Button>
          {md ? (
            <fbt desc="log in to the app">LOGIN</fbt>
          ) : (
            <fbt desc="sign up or log in to the app">SIGN UP | LOGIN</fbt>
          )}
        </Button>
      </ButtonContainer>
    </Pressable>
  );
};

export default React.memo(HeaderSignUpLogin, () => true);
