import * as z from 'zod';

import { PrivacyScope as IPrivacyScope } from '#enum';

const PrivacyScope = z.enum([
  IPrivacyScope.Default,
  IPrivacyScope.Public,
  IPrivacyScope.OnlyMe,
  IPrivacyScope.Custom,
]);

export default PrivacyScope;
