import React from 'react';
import { Text } from 'react-native';

import {
  ActionsContainer,
  Container,
  ContentContainer,
  Description,
  IconContainer,
  ItemContainer,
  Label,
  MainContentContainer,
  SubContentContainer,
} from './RespondableItem.style';

type Props = {
  icon?: string;
  label: string;
  description?: string;
  actionButtons?: React.ReactElement;
  onPress?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

// TODO: create icon renderer
const RespondableItem = ({
  icon,
  label,
  description,
  actionButtons,
  onPress,
}: Props): React.ReactElement => (
  <Container>
    <ItemContainer onPress={onPress} onLongPress={onPress} delayLongPress={0}>
      {icon ? (
        <IconContainer>
          <Text>Icon</Text>
        </IconContainer>
      ) : null}
      <ContentContainer>
        <MainContentContainer>
          <Label>{label}</Label>
        </MainContentContainer>
        <SubContentContainer>
          <Description>{description}</Description>
        </SubContentContainer>
      </ContentContainer>
    </ItemContainer>
    {actionButtons ? (
      <ActionsContainer>{actionButtons}</ActionsContainer>
    ) : null}
  </Container>
);

export default React.memo(RespondableItem);
