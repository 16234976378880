/* eslint-disable @typescript-eslint/no-explicit-any */
import isJson from './isJson';

/**
 * Parse any string to the suitable type.
 */
const parseValue = (value: string | null): any => {
  if (!value) return null;
  if (isJson(value)) return JSON.parse(value);
  if (Number(value)) return Number(value);
  return value;
};

export default parseValue;
