import React from 'react';
import { GestureResponderEvent, StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';

import useTheme from '#hooks/useTheme';

import { Container, ExtraContainer } from './EditorButton.style';

type Props = {
  children?: React.ReactNode;
  disableActiveColor?: boolean;
} & React.ComponentProps<typeof IconButton>;

const style = StyleSheet.create({
  icon: {
    width: 36,
    height: 36,
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
  },
});

const EditorButton = ({
  children,
  disableActiveColor,
  onPress,
  ...props
}: Props): React.ReactElement => {
  const [showChildren, setShowChildren] = React.useState(false);
  const { colors } = useTheme();

  const handlePress = React.useCallback(
    (e: GestureResponderEvent) => {
      onPress?.(e);
      setShowChildren((prev) => !prev);
    },
    [onPress],
  );

  return (
    <Container>
      <IconButton
        {...props}
        onPress={handlePress}
        color={
          showChildren && !disableActiveColor ? colors?.orange : colors?.text2
        }
        style={style.icon}
      />
      {children && showChildren && (
        <ExtraContainer elevation={3}>{children}</ExtraContainer>
      )}
    </Container>
  );
};

export default React.memo(
  EditorButton,
  (prev, next) =>
    prev.color === next.color &&
    prev.disableActiveColor === next.disableActiveColor &&
    prev.onPress === next.onPress &&
    prev.disabled === next.disabled,
);
