import React from 'react';
import { graphql, useFragment } from 'react-relay';

import HeaderSignUpLogin from '@/HeaderSignUpLogin';
import HeaderViewerSection from '@/HeaderViewerSection';
import { HeaderRightSection_viewer$key } from '~/HeaderRightSection_viewer.graphql';

const viewerFragment = graphql`
  fragment HeaderRightSection_viewer on User {
    id
    ...HeaderViewerSection_viewer
  }
`;

type Props = {
  viewer: HeaderRightSection_viewer$key | null;
};

/**
 * Render components of the right side of the `Header`.
 *
 * This component will render based on user login status.
 */
const HeaderRightSection = ({ viewer }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  return viewerData?.id ? (
    <HeaderViewerSection viewer={viewerData} />
  ) : (
    <HeaderSignUpLogin />
  );
};

export default React.memo(HeaderRightSection);
