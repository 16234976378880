import React from 'react';
import { ViewStyle } from 'react-native';

import Drawer from '@/Drawer';
import { DrawerPosition } from '#interfaces';

export type DrawerProps = {
  content: React.ReactNode;
  width?: number;
  height?: number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  opacity?: number;
  drawerPosition?: DrawerPosition;
  drawerStyles?: ViewStyle;
  dismissable?: boolean;
  onDismiss?: () => void;
  onOpen?: () => void;
};

export type DrawerContext = {
  drawerComponent: React.ReactNode;
  isOpen?: boolean;
  openDrawer: (props: DrawerProps) => void;
  closeDrawer: () => void;
};

type Props = {
  children: React.ReactNode;
};

export const DrawerContext = React.createContext<DrawerContext>({
  drawerComponent: null,
  openDrawer: () => null,
  closeDrawer: () => null,
});

const DrawerProvider = ({ children }: Props): React.ReactElement => {
  const isOpenRef = React.useRef<boolean>();
  const [
    drawerComponent,
    setDrawerComponent,
  ] = React.useState<React.ReactNode>();

  const closeDrawer = React.useCallback(
    (callback?: () => void) => () => {
      callback?.();
      isOpenRef.current = false;
      setDrawerComponent(null);
    },
    [],
  );

  const openDrawer = React.useCallback(
    ({
      content,
      width,
      height,
      maxWidth,
      maxHeight,
      drawerPosition,
      drawerStyles,
      opacity,
      dismissable,
      onDismiss,
      onOpen,
    }: DrawerProps) => {
      isOpenRef.current = true;
      onOpen?.();

      const component = (
        <Drawer
          width={width}
          height={height}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          drawerPosition={drawerPosition}
          drawerStyles={drawerStyles}
          opacity={opacity}
          isOpen={isOpenRef.current}
          dismissable={dismissable}
          onDismiss={closeDrawer(onDismiss)}
        >
          {content}
        </Drawer>
      );
      setDrawerComponent(component);
    },
    [closeDrawer],
  );

  const contextValue = React.useMemo(
    () => ({
      drawerComponent,
      isOpen: isOpenRef.current,
      openDrawer,
      closeDrawer,
    }),
    [closeDrawer, drawerComponent, openDrawer],
  );

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
