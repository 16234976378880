import fetch from 'isomorphic-unfetch';

import { token } from '#auth/utils/basicAuth';
import { AvailableLocale, FetchedTranslation } from '#interfaces';
import { url } from '#utils';

/**
 * Create `fetchTranslation` function with the provided localesEndpoint url
 *
 * @param {string} localesEndpoint url of the `locales` api
 * @returns {Function} `fetchTranslation` function
 */
const createFetchTranslation = (localesEndpoint: string | undefined) => async (
  locale: AvailableLocale,
  version?: string,
): Promise<FetchedTranslation> => {
  const versionId = version ? `?version=${version}` : '';
  const endpointUrl = url(localesEndpoint ?? '/api/locales');
  const res = await fetch(`${endpointUrl}/${locale}${versionId}`, {
    headers: {
      accept: 'application/json',
      authorization: `Basic ${token}`,
    },
  });
  return res ? res.json() : {};
};

export default createFetchTranslation;
