import React from 'react';
import { graphql, useFragment } from 'react-relay';

import HeaderViewerInfo from '@/HeaderViewerInfo';
import HeaderViewerSettings from '@/HeaderViewerSettings';
import { HeaderViewerSection_viewer$key } from '~/HeaderViewerSection_viewer.graphql';

import { Container } from './HeaderViewerSection.style';

const viewerFragment = graphql`
  fragment HeaderViewerSection_viewer on User {
    ...HeaderViewerInfo_viewer
  }
`;

type Props = {
  viewer: HeaderViewerSection_viewer$key | null;
};

/**
 * Viewer section (logged in user) on the `Header`.
 */
const HeaderViewerSection = ({ viewer }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  return (
    <Container>
      <HeaderViewerInfo viewer={viewerData} />
      <HeaderViewerSettings />
    </Container>
  );
};

export default React.memo(HeaderViewerSection);
