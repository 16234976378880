/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumInformedConsentType = "cookies" | "dataPolicy" | "privacyPolicy" | "termsOfService" | "%future added value";
export type UpdateByIdInformedConsentInput = {
    type?: EnumInformedConsentType | null;
    version?: string | null;
    acceptedDate?: string | null;
};
export type UpdateUserDetailsMutationVariables = {
    userDetailsId: string;
    locale?: string | null;
    timezone?: string | null;
    appLanguage?: string | null;
    preferedContentLanguages?: Array<string | null> | null;
    acceptedInformedConsents?: Array<UpdateByIdInformedConsentInput | null> | null;
};
export type UpdateUserDetailsMutationResponse = {
    readonly userDetailsUpdateById: {
        readonly record: {
            readonly id: string;
            readonly locale: string | null;
            readonly timezone: string | null;
            readonly appLanguage: string | null;
            readonly preferedContentLanguages: ReadonlyArray<string | null> | null;
            readonly acceptedInformedConsents: ReadonlyArray<{
                readonly type: EnumInformedConsentType;
                readonly version: string;
            } | null> | null;
        } | null;
    } | null;
};
export type UpdateUserDetailsMutation = {
    readonly response: UpdateUserDetailsMutationResponse;
    readonly variables: UpdateUserDetailsMutationVariables;
};



/*
mutation UpdateUserDetailsMutation(
  $userDetailsId: MongoID!
  $locale: String
  $timezone: String
  $appLanguage: String
  $preferedContentLanguages: [String]
  $acceptedInformedConsents: [UpdateByIdInformedConsentInput]
) {
  userDetailsUpdateById(_id: $userDetailsId, record: {locale: $locale, timezone: $timezone, appLanguage: $appLanguage, preferedContentLanguages: $preferedContentLanguages, acceptedInformedConsents: $acceptedInformedConsents}) {
    record {
      id
      locale
      timezone
      appLanguage
      preferedContentLanguages
      acceptedInformedConsents {
        type
        version
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acceptedInformedConsents"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appLanguage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "preferedContentLanguages"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timezone"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userDetailsId"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "userDetailsId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "acceptedInformedConsents",
            "variableName": "acceptedInformedConsents"
          },
          {
            "kind": "Variable",
            "name": "appLanguage",
            "variableName": "appLanguage"
          },
          {
            "kind": "Variable",
            "name": "locale",
            "variableName": "locale"
          },
          {
            "kind": "Variable",
            "name": "preferedContentLanguages",
            "variableName": "preferedContentLanguages"
          },
          {
            "kind": "Variable",
            "name": "timezone",
            "variableName": "timezone"
          }
        ],
        "kind": "ObjectValue",
        "name": "record"
      }
    ],
    "concreteType": "UpdateByIdUserDetailsPayload",
    "kind": "LinkedField",
    "name": "userDetailsUpdateById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserDetails",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locale",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timezone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appLanguage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "preferedContentLanguages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InformedConsent",
            "kind": "LinkedField",
            "name": "acceptedInformedConsents",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserDetailsMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateUserDetailsMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "e7fb4985f469bcc51f70604ea8944733",
    "id": null,
    "metadata": {},
    "name": "UpdateUserDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserDetailsMutation(\n  $userDetailsId: MongoID!\n  $locale: String\n  $timezone: String\n  $appLanguage: String\n  $preferedContentLanguages: [String]\n  $acceptedInformedConsents: [UpdateByIdInformedConsentInput]\n) {\n  userDetailsUpdateById(_id: $userDetailsId, record: {locale: $locale, timezone: $timezone, appLanguage: $appLanguage, preferedContentLanguages: $preferedContentLanguages, acceptedInformedConsents: $acceptedInformedConsents}) {\n    record {\n      id\n      locale\n      timezone\n      appLanguage\n      preferedContentLanguages\n      acceptedInformedConsents {\n        type\n        version\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3a882adf94dbc676e991600addfb8c58';
export default node;
