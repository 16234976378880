import Color from 'color';
import React from 'react';
import { NativeSyntheticEvent, NativeTouchEvent } from 'react-native';
import { DefaultTheme, Dialog, Portal } from 'react-native-paper';
import { Except, PartialDeep } from 'type-fest';

import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import {
  BorderProps,
  ColorProps,
  LayoutProps,
  SpaceProps,
} from '#styled-system';

import {
  Container,
  Modal,
  OverflowContainer,
  Title,
  TitleContainer,
} from './ModalCard.style';

type Props = Except<React.ComponentProps<typeof Dialog>, 'onDismiss'> &
  Except<LayoutProps, 'overflow'> &
  SpaceProps &
  BorderProps &
  ColorProps & {
    children: React.ReactNode;
    visible?: boolean;
    dismissable?: boolean;
    overflow?: boolean;
    title?: string;
    noPadding?: boolean;
    borderRadius?: string;
    theme?: PartialDeep<typeof DefaultTheme>;
    onDismiss?: (ev?: NativeSyntheticEvent<NativeTouchEvent>) => void;
  };

const ModalCard = ({
  children,
  visible,
  dismissable,
  overflow,
  title,
  noPadding,
  borderRadius,
  theme,
  onDismiss,
  ...props
}: Props): React.ReactElement | null => {
  const { colors, isDarkTheme } = useTheme();
  const [r] = useResponsive();
  const [ready, setIsReady] = React.useState(false);

  const darkBg = `${Color(colors?.surface).lightness(11)}`;

  const modalPadding = noPadding ? 0 : r(['16px 12px', undefined]);
  const modalBorderRadius = borderRadius ?? r(['0', undefined]);
  const backgroundColor = isDarkTheme ? darkBg : colors?.surface;

  // This solved the rendering order issue that some components rendered over the modal component.
  React.useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      if (isMounted) setIsReady(visible);
    }, 0);

    return () => {
      isMounted = false;
    };
  }, [visible]);

  return ready ? (
    <Portal>
      <Modal
        maxWidth={r(['100%', '90%', '80%'])}
        width={r(['100%', '600px', '800px'])}
        maxHeight={r(['100%', undefined])}
        bg={backgroundColor}
        borderRadius={modalBorderRadius}
        visible={visible ?? false}
        dismissable={dismissable}
        onDismiss={onDismiss}
        theme={theme}
        {...props}
      >
        {overflow ? (
          <OverflowContainer
            padding={modalPadding}
            borderRadius={modalBorderRadius}
          >
            {title && (
              <TitleContainer>
                <Title>{title}</Title>
              </TitleContainer>
            )}
            {children}
          </OverflowContainer>
        ) : (
          <Container
            padding={modalPadding}
            borderRadius={modalBorderRadius}
            showsVerticalScrollIndicator={false}
          >
            {title && (
              <TitleContainer>
                <Title>{title}</Title>
              </TitleContainer>
            )}
            {children}
          </Container>
        )}
      </Modal>
    </Portal>
  ) : null;
};

export default ModalCard;
