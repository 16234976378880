/* eslint-disable @typescript-eslint/no-explicit-any */
import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { TagRelationship } from '#enum';
import { CBMutationConfig } from '#interfaces';
import { AddRemovePostTagsMutation } from '~/AddRemovePostTagsMutation.graphql';

// eslint-disable-next-line import/no-cycle
import { BasedArgs, ObjectOperation } from './AddRemoveTaggedTags';
import createTaggedPost from './CreateTaggedPost';
import deleteTaggedPost from './DeleteTaggedPost';

const mutation = graphql`
  mutation AddRemovePostTagsMutation(
    $userId: MongoID!
    $postId: MongoID!
    $allTags: [MongoID!]!
    $addedTags: [MongoID!]!
    $removedTags: [MongoID!]!
    $tagRelationship: String!
    $currentDate: Date
    $taggedAction: String
    $updateRelatedTags: Boolean = false
    $postAddTaggedTags: Boolean = false
    $postUpdateTaggedTags: Boolean = false
  ) {
    taggedPostAddAndRemoveTags(
      userId: $userId
      postId: $postId
      addedTags: $addedTags
      removedTags: $removedTags
    ) {
      id
      userId
      postId
      tags {
        id
        slug
        label
      }
    }
    postAddTaggedTags(_id: $postId, tags: $addedTags)
      @include(if: $postAddTaggedTags) {
      id
      tagCollections {
        taggedTags {
          tagId
        }
      }
    }
    postUpdateTaggedTags(
      _id: $postId
      addedTags: $addedTags
      removedTags: $removedTags
      taggedAction: $taggedAction
    ) @include(if: $postUpdateTaggedTags) {
      id
      tagCollections {
        taggedTags {
          tagId
        }
      }
    }
    updateRelatedTags(
      allTags: $allTags
      addedTags: $addedTags
      removedTags: $removedTags
      relationship: $tagRelationship
    ) @include(if: $updateRelatedTags) {
      id
    }
    userDetailsUpdateTaggedDate(userId: $userId, date: $currentDate) {
      _id
      lastTaggedDate
    }
  }
`;

const addRemoveTaggedTags = (
  environment: Environment,
  args: { postId: string } & BasedArgs,
  config?: CBMutationConfig<AddRemovePostTagsMutation>,
): Disposable => {
  const currentDate = new Date().toUTCString();
  const { objectOperation, taggedAction, ...mutationVariables } = args;

  const variables = {
    ...mutationVariables,
    tagRelationship: TagRelationship.TaggedTag,
    taggedAction,
    currentDate,
    updateRelatedTags:
      args.allTags.length > 1 &&
      !!(args.addedTags.length || args.removedTags.length),
    postAddTaggedTags: objectOperation === ObjectOperation.AddTaggedTags,
    postUpdateTaggedTags: objectOperation === ObjectOperation.UpdateTaggedTags,
  };

  return commitMutation<AddRemovePostTagsMutation>(environment, {
    ...config,
    mutation,
    variables,
    onCompleted: (res, err) => {
      config?.onCompleted?.(res, err);
      if (res && !err) {
        if (res.taggedPostAddAndRemoveTags?.tags.length) {
          createTaggedPost(environment, {
            userId: args.userId,
            postId: args.postId,
          });
        } else {
          deleteTaggedPost(environment, {
            userId: args.userId,
            postId: args.postId,
          });
        }
      }
    },
    updater: (store) => {
      const viewerPayload = store.get(args.userId);
      const taggedPostPayload = store.getRootField(
        'taggedPostAddAndRemoveTags',
      );

      if (viewerPayload) {
        const settings = viewerPayload.getLinkedRecord('settings');
        settings?.setValue(currentDate, 'lastTaggedDate');
      }

      if (taggedPostPayload) {
        const taggedPostPayloadId = taggedPostPayload?.getDataID();
        const newTags = taggedPostPayload.getLinkedRecords('tags');

        const taggedPostNode = store.get(taggedPostPayloadId);
        taggedPostNode?.setLinkedRecords(newTags as any, 'tags');

        // in case when it is first tag action, add taggedTags node to post
        if (taggedPostNode) {
          const postNode = store.get(args.postId);
          postNode?.setLinkedRecord(taggedPostNode, 'userTaggedTags', {
            userId: args.userId,
          });
        }
      }
    },
  });
};

export default addRemoveTaggedTags;
