/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, useRouting } from 'expo-next-react-navigation';
import React from 'react';
import { FlatList } from 'react-native';

import RespondableItem from '@/RespondableItem';
import { LinkConfigs } from '#interfaces';
import { getObjectJoinString } from '#utils';

type RespondableItem = { id: string } & React.ComponentProps<
  typeof RespondableItem
>;

type Props = {
  items?: RespondableItem[] | null;
  onItemPress?: (item: any) => void;
  getLinkProps?: (item: any) => LinkConfigs;
};

const ItemList = ({
  items,
  getLinkProps,
  onItemPress,
}: Props): React.ReactElement => {
  const { navigate } = useRouting();

  return (
    <FlatList
      keyboardShouldPersistTaps="handled"
      data={items}
      renderItem={({ item }) => {
        const handlePress = () => {
          if (item.onPress) {
            item.onPress?.();
          } else {
            onItemPress?.(item);
          }
          const navigateProps = getLinkProps?.(item);
          if (navigateProps) navigate(navigateProps);
        };
        const component = (
          <RespondableItem label={item.label} onPress={handlePress} />
        );

        return getLinkProps ? (
          <Link key={item.label} {...getLinkProps(item)} isText={false}>
            {component}
          </Link>
        ) : (
          component
        );
      }}
    />
  );
};

export default React.memo(
  ItemList,
  (prev, next) =>
    getObjectJoinString(prev.items, ['id']) ===
    getObjectJoinString(next.items, ['id']),
);
