export enum QueryParam {
  FeedQuery = 'q',
  FeedScope = 'fs',
  FeedSort = 'sort',
  FeedTags = 'tags',

  EncodedUserCommunityId = 'c',

  // for SEO purpose
  CommunityName = 'community',

  // account deletion status
  Ticket = 'ticket',
}

export default null;
