export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any;
  /** The string representation of JavaScript regexp. You may provide it with flags "/^abc.*\/i" or without flags like "^abc.*". More info about RegExp characters and flags: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
  RegExpAsString: any;
};

export type Channel = {
  __typename?: 'Channel';
  hashId: Scalars['String'];
  filters: Array<Maybe<ChannelFilter>>;
  indexedItems: Array<Maybe<ChannelIndexedItems>>;
  stats?: Maybe<Array<Maybe<KeyValue>>>;
  actionedDate?: Maybe<ChannelActionedDate>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
};

export type ChannelActionedDate = {
  __typename?: 'ChannelActionedDate';
  listUpdated?: Maybe<ChannelActionedDateListUpdated>;
  contentCleanup?: Maybe<Scalars['Date']>;
};

export type ChannelActionedDateListUpdated = {
  __typename?: 'ChannelActionedDateListUpdated';
  featuredScore?: Maybe<Scalars['Date']>;
  wilsonScore?: Maybe<Scalars['Date']>;
  hotScore?: Maybe<Scalars['Date']>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type ChannelFilter = {
  __typename?: 'ChannelFilter';
  filterObjectType: EnumChannelFilterFilterObjectType;
  filterObjectId: Scalars['MongoID'];
  condition?: Maybe<Scalars['Float']>;
};

export type ChannelFilterInput = {
  filterObjectType: EnumChannelFilterFilterObjectType;
  filterObjectId: Scalars['MongoID'];
  condition?: Maybe<Scalars['Float']>;
};

export type ChannelIndexedItems = {
  __typename?: 'ChannelIndexedItems';
  id: Scalars['MongoID'];
  type?: Maybe<Scalars['String']>;
  info?: Maybe<ChannelIndexedItemsInfo>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ChannelIndexedItemsInfo = {
  __typename?: 'ChannelIndexedItemsInfo';
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  createdDate: Scalars['Date'];
  createdByUserId: Scalars['MongoID'];
};

export type Comment = {
  __typename?: 'Comment';
  status?: Maybe<DocumentStatus>;
  postId: Scalars['MongoID'];
  communityId?: Maybe<Scalars['MongoID']>;
  parentCommentId?: Maybe<Scalars['MongoID']>;
  version?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  featuredSubCommentIds?: Maybe<CommentFeaturedSubCommentIds>;
  vote?: Maybe<CommentVote>;
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  scoreInfo?: Maybe<CommentScoreInfo>;
  count?: Maybe<CommentCount>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId: Scalars['MongoID'];
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  settings?: Maybe<Array<Maybe<KeyValue>>>;
  resource?: Maybe<Array<Maybe<KeyValue>>>;
  meta?: Maybe<Array<Maybe<KeyValue>>>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
  parentComment?: Maybe<Comment>;
  featuredSubComments: Array<Maybe<Comment>>;
  createdBy?: Maybe<User>;
  lastModifiedBy?: Maybe<User>;
  userVote?: Maybe<Vote>;
};


export type CommentFeaturedSubCommentsArgs = {
  featuredSubCommentType?: Maybe<Scalars['String']>;
};


export type CommentUserVoteArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneVoteInput>;
  userId?: Maybe<Scalars['MongoID']>;
};

export type CommentCount = {
  __typename?: 'CommentCount';
  totalSubComments?: Maybe<Scalars['Float']>;
  activeSubComments?: Maybe<Scalars['Float']>;
};

export type CommentCountInput = {
  totalSubComments?: Maybe<Scalars['Float']>;
  activeSubComments?: Maybe<Scalars['Float']>;
};

/** An edge in a connection. */
export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** The item at the end of the edge */
  node: Comment;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CommentFeaturedSubCommentIds = {
  __typename?: 'CommentFeaturedSubCommentIds';
  featured?: Maybe<Array<Maybe<FeaturedSubComment>>>;
  hot?: Maybe<Array<Maybe<FeaturedSubComment>>>;
  best?: Maybe<Array<Maybe<FeaturedSubComment>>>;
  controversial?: Maybe<Array<Maybe<FeaturedSubComment>>>;
  oldest?: Maybe<Array<Maybe<FeaturedSubComment>>>;
  newest?: Maybe<Array<Maybe<FeaturedSubComment>>>;
};

export type CommentFeaturedSubCommentIdsInput = {
  featured?: Maybe<Array<Maybe<FeaturedSubCommentInput>>>;
  hot?: Maybe<Array<Maybe<FeaturedSubCommentInput>>>;
  best?: Maybe<Array<Maybe<FeaturedSubCommentInput>>>;
  controversial?: Maybe<Array<Maybe<FeaturedSubCommentInput>>>;
  oldest?: Maybe<Array<Maybe<FeaturedSubCommentInput>>>;
  newest?: Maybe<Array<Maybe<FeaturedSubCommentInput>>>;
};

/** A connection to a list of items. */
export type CommentGetComments = {
  __typename?: 'CommentGetComments';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Information to aid in pagination. */
  edges: Array<CommentEdge>;
};

export type CommentScoreInfo = {
  __typename?: 'CommentScoreInfo';
  lastUpdatedDate?: Maybe<Scalars['Date']>;
};

export type CommentScoreInfoInput = {
  lastUpdatedDate?: Maybe<Scalars['Date']>;
};

export type CommentVote = {
  __typename?: 'CommentVote';
  upvotes?: Maybe<Scalars['Float']>;
  downvotes?: Maybe<Scalars['Float']>;
};

export type CommentVoteInput = {
  upvotes?: Maybe<Scalars['Float']>;
  downvotes?: Maybe<Scalars['Float']>;
};

export type Community = {
  __typename?: 'Community';
  status?: Maybe<DocumentStatus>;
  basedCommunityId?: Maybe<Scalars['MongoID']>;
  communityCode: Scalars['String'];
  channelId: Scalars['MongoID'];
  name: Scalars['String'];
  images?: Maybe<CommunityImages>;
  filters?: Maybe<Array<Maybe<ChannelFilter>>>;
  stats?: Maybe<Array<Maybe<KeyValue>>>;
  settings?: Maybe<Array<Maybe<KeyValue>>>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId: Scalars['MongoID'];
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  privacy?: Maybe<EnumCommunityPrivacy>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
  basedCommunity?: Maybe<Community>;
  channel?: Maybe<Community>;
  createdBy?: Maybe<Community>;
  filterObjects?: Maybe<Array<FilterObject>>;
};

export type CommunityImages = {
  __typename?: 'CommunityImages';
  cover?: Maybe<Image>;
  avatar?: Maybe<Image>;
};

export type CommunityImagesInput = {
  cover?: Maybe<ImageInput>;
  avatar?: Maybe<ImageInput>;
};

export type CreateManyTagInput = {
  type?: Maybe<EnumTagType>;
  label: Scalars['String'];
  slug: Scalars['String'];
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  relatedTags?: Maybe<Array<Maybe<TagRelatedTagsInput>>>;
};

export type CreateManyTagPayload = {
  __typename?: 'CreateManyTagPayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records?: Maybe<Array<Tag>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneCommentInput = {
  status?: Maybe<DocumentStatusInput>;
  postId: Scalars['MongoID'];
  communityId?: Maybe<Scalars['MongoID']>;
  parentCommentId?: Maybe<Scalars['MongoID']>;
  version?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  featuredSubCommentIds?: Maybe<CommentFeaturedSubCommentIdsInput>;
  vote?: Maybe<CommentVoteInput>;
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  scoreInfo?: Maybe<CommentScoreInfoInput>;
  count?: Maybe<CommentCountInput>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId: Scalars['MongoID'];
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  settings?: Maybe<Array<Maybe<KeyValueInput>>>;
  resource?: Maybe<Array<Maybe<KeyValueInput>>>;
  meta?: Maybe<Array<Maybe<KeyValueInput>>>;
};

export type CreateOneCommentPayload = {
  __typename?: 'CreateOneCommentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Comment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneMessageInput = {
  text?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
};

export type CreateOneMessagePayload = {
  __typename?: 'CreateOneMessagePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Message>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneModificationHistoryInput = {
  objectType: EnumModificationHistoryObjectType;
  objectId: Scalars['MongoID'];
  objectVersion: Scalars['String'];
  payload: Scalars['JSON'];
  payloadSchemaVersion?: Maybe<Scalars['String']>;
  modifiedDate: Scalars['Date'];
  modifiedByUserId: Scalars['MongoID'];
};

export type CreateOneModificationHistoryPayload = {
  __typename?: 'CreateOneModificationHistoryPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<ModificationHistory>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOnePostInput = {
  status?: Maybe<DocumentStatusInput>;
  version?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  content: Scalars['String'];
  previewContent?: Maybe<Array<Maybe<Scalars['String']>>>;
  plainContent?: Maybe<Scalars['String']>;
  featureCommentIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  vote?: Maybe<PostVoteInput>;
  count?: Maybe<PostCountInput>;
  tagCollections?: Maybe<PostTagCollectionsInput>;
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  scoreInfo?: Maybe<PostScoreInfoInput>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId: Scalars['MongoID'];
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  settings?: Maybe<Array<Maybe<KeyValueInput>>>;
  resource?: Maybe<Array<Maybe<KeyValueInput>>>;
  meta?: Maybe<Array<Maybe<KeyValueInput>>>;
};

export type CreateOnePostPayload = {
  __typename?: 'CreateOnePostPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Post>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneUserCommunityPayload = {
  __typename?: 'CreateOneUserCommunityPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<UserCommunity>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type CreateOneUserDetailsInput = {
  userId: Scalars['MongoID'];
  locale?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  appLanguage?: Maybe<Scalars['String']>;
  preferedContentLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastTaggedDate?: Maybe<Scalars['Date']>;
  acceptedInformedConsents?: Maybe<Array<Maybe<InformedConsentInput>>>;
};

export type CreateOneUserDetailsPayload = {
  __typename?: 'CreateOneUserDetailsPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<UserDetails>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};


export type DocumentStatus = {
  __typename?: 'DocumentStatus';
  value?: Maybe<EnumDocumentStatusValue>;
  reason?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type DocumentStatusInput = {
  value?: Maybe<EnumDocumentStatusValue>;
  reason?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type Email = {
  __typename?: 'Email';
  emailAddress: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export enum EnumChannelFilterFilterObjectType {
  Tag = 'Tag',
  User = 'User'
}

export enum EnumCommunityPrivacy {
  Default = 'Default',
  Public = 'Public',
  OnlyMe = 'OnlyMe',
  Custom = 'Custom'
}

export enum EnumDocumentStatusValue {
  Deactivated = 'Deactivated',
  Deleted = 'Deleted',
  Suspended = 'Suspended'
}

export enum EnumImageSizesKey {
  Original = 'original',
  Avatar = 'avatar',
  Thumbnail = 'thumbnail',
  Medium = 'medium',
  Px720 = 'px720',
  Px1080 = 'px1080'
}

export enum EnumInformedConsentType {
  PrivacyPolicy = 'privacyPolicy',
  DataPolicy = 'dataPolicy',
  TermsOfService = 'termsOfService',
  Cookies = 'cookies'
}

export enum EnumModificationHistoryObjectType {
  Post = 'Post',
  User = 'User',
  Comment = 'Comment'
}

export enum EnumTagRelatedTagsRelationship {
  SavedFilter = 'SavedFilter',
  TaggedTag = 'TaggedTag',
  Translation = 'Translation'
}

export enum EnumTagSuggestionQueryFor {
  Post = 'Post',
  User = 'User',
  Comment = 'Comment'
}

export enum EnumTagType {
  General = 'General',
  UserTagUser = 'UserTagUser'
}

export enum EnumUserCommunityRole {
  Member = 'Member',
  Moderator = 'Moderator',
  Admin = 'Admin'
}

export enum EnumVoteObjectType {
  Post = 'Post',
  User = 'User',
  Comment = 'Comment'
}

export enum EnumVoteVote {
  Upvote = 'Upvote',
  Downvote = 'Downvote',
  Unvote = 'Unvote'
}

export type ErrorInterface = {
  /** Generic error message */
  message?: Maybe<Scalars['String']>;
};

export type FeaturedSubComment = {
  __typename?: 'FeaturedSubComment';
  commentId: Scalars['MongoID'];
  value?: Maybe<Scalars['JSON']>;
};

export type FeaturedSubCommentInput = {
  commentId: Scalars['MongoID'];
  value?: Maybe<Scalars['JSON']>;
};

export type FilterFindManyCommentCountActiveSubCommentsOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCommentCountInput = {
  totalSubComments?: Maybe<Scalars['Float']>;
  activeSubComments?: Maybe<Scalars['Float']>;
};

export type FilterFindManyCommentCountOperatorsInput = {
  activeSubComments?: Maybe<FilterFindManyCommentCountActiveSubCommentsOperatorsInput>;
};

export type FilterFindManyCommentCreatedByUserIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCommentFeaturedScoreOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCommentFeaturedSubCommentIdsInput = {
  featured?: Maybe<Array<Maybe<FilterFindManyFeaturedSubCommentInput>>>;
  hot?: Maybe<Array<Maybe<FilterFindManyFeaturedSubCommentInput>>>;
  best?: Maybe<Array<Maybe<FilterFindManyFeaturedSubCommentInput>>>;
  controversial?: Maybe<Array<Maybe<FilterFindManyFeaturedSubCommentInput>>>;
  oldest?: Maybe<Array<Maybe<FilterFindManyFeaturedSubCommentInput>>>;
  newest?: Maybe<Array<Maybe<FilterFindManyFeaturedSubCommentInput>>>;
};

export type FilterFindManyCommentHotScoreOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCommentInput = {
  status?: Maybe<FilterFindManyDocumentStatusInput>;
  postId?: Maybe<Scalars['MongoID']>;
  communityId?: Maybe<Scalars['MongoID']>;
  parentCommentId?: Maybe<Scalars['MongoID']>;
  version?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  featuredSubCommentIds?: Maybe<FilterFindManyCommentFeaturedSubCommentIdsInput>;
  vote?: Maybe<FilterFindManyCommentVoteInput>;
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  scoreInfo?: Maybe<FilterFindManyCommentScoreInfoInput>;
  count?: Maybe<FilterFindManyCommentCountInput>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  settings?: Maybe<Array<Maybe<FilterFindManyKeyValueInput>>>;
  resource?: Maybe<Array<Maybe<FilterFindManyKeyValueInput>>>;
  meta?: Maybe<Array<Maybe<FilterFindManyKeyValueInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyCommentOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyCommentInput>>;
  AND?: Maybe<Array<FilterFindManyCommentInput>>;
};

export type FilterFindManyCommentMetaKeyOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCommentMetaOperatorsInput = {
  key?: Maybe<FilterFindManyCommentMetaKeyOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyCommentOperatorsInput = {
  postId?: Maybe<FilterFindManyCommentPostIdOperatorsInput>;
  featuredScore?: Maybe<FilterFindManyCommentFeaturedScoreOperatorsInput>;
  wilsonScore?: Maybe<FilterFindManyCommentWilsonScoreOperatorsInput>;
  hotScore?: Maybe<FilterFindManyCommentHotScoreOperatorsInput>;
  count?: Maybe<FilterFindManyCommentCountOperatorsInput>;
  createdByUserId?: Maybe<FilterFindManyCommentCreatedByUserIdOperatorsInput>;
  meta?: Maybe<FilterFindManyCommentMetaOperatorsInput>;
  _id?: Maybe<FilterFindManyComment_IdOperatorsInput>;
};

export type FilterFindManyCommentPostIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyCommentScoreInfoInput = {
  lastUpdatedDate?: Maybe<Scalars['Date']>;
};

export type FilterFindManyCommentVoteInput = {
  upvotes?: Maybe<Scalars['Float']>;
  downvotes?: Maybe<Scalars['Float']>;
};

export type FilterFindManyCommentWilsonScoreOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyComment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDocumentStatusInput = {
  value?: Maybe<EnumDocumentStatusValue>;
  reason?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type FilterFindManyFeaturedSubCommentInput = {
  commentId: Scalars['MongoID'];
  value?: Maybe<Scalars['JSON']>;
};

export type FilterFindManyKeyValueInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type FilterFindManyPostCountInput = {
  shared?: Maybe<Scalars['Float']>;
  tagged?: Maybe<Scalars['Float']>;
  taggedTags?: Maybe<Scalars['Float']>;
  totalComments?: Maybe<Scalars['Float']>;
  activeRootComments?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPostFeaturedScoreOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPostHotScoreOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPostInput = {
  status?: Maybe<FilterFindManyDocumentStatusInput>;
  version?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  previewContent?: Maybe<Array<Maybe<Scalars['String']>>>;
  plainContent?: Maybe<Scalars['String']>;
  featureCommentIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  vote?: Maybe<FilterFindManyPostVoteInput>;
  count?: Maybe<FilterFindManyPostCountInput>;
  tagCollections?: Maybe<FilterFindManyPostTagCollectionsInput>;
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  scoreInfo?: Maybe<FilterFindManyPostScoreInfoInput>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  settings?: Maybe<Array<Maybe<FilterFindManyKeyValueInput>>>;
  resource?: Maybe<Array<Maybe<FilterFindManyKeyValueInput>>>;
  meta?: Maybe<Array<Maybe<FilterFindManyKeyValueInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyPostOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyPostInput>>;
  AND?: Maybe<Array<FilterFindManyPostInput>>;
};

export type FilterFindManyPostMetaKeyOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPostMetaOperatorsInput = {
  key?: Maybe<FilterFindManyPostMetaKeyOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyPostOperatorsInput = {
  status?: Maybe<FilterFindManyPostStatusOperatorsInput>;
  tagCollections?: Maybe<FilterFindManyPostTagCollectionsOperatorsInput>;
  featuredScore?: Maybe<FilterFindManyPostFeaturedScoreOperatorsInput>;
  wilsonScore?: Maybe<FilterFindManyPostWilsonScoreOperatorsInput>;
  hotScore?: Maybe<FilterFindManyPostHotScoreOperatorsInput>;
  meta?: Maybe<FilterFindManyPostMetaOperatorsInput>;
  _id?: Maybe<FilterFindManyPost_IdOperatorsInput>;
};

export type FilterFindManyPostScoreInfoInput = {
  lastUpdatedDate?: Maybe<Scalars['Date']>;
};

export type FilterFindManyPostStatusOperatorsInput = {
  value?: Maybe<FilterFindManyPostStatusValueOperatorsInput>;
};

export type FilterFindManyPostStatusValueOperatorsInput = {
  gt?: Maybe<EnumDocumentStatusValue>;
  gte?: Maybe<EnumDocumentStatusValue>;
  lt?: Maybe<EnumDocumentStatusValue>;
  lte?: Maybe<EnumDocumentStatusValue>;
  ne?: Maybe<EnumDocumentStatusValue>;
  in?: Maybe<Array<Maybe<EnumDocumentStatusValue>>>;
  nin?: Maybe<Array<Maybe<EnumDocumentStatusValue>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPostTagCollectionsInput = {
  taggedTags?: Maybe<Array<Maybe<FilterFindManyTagItemInput>>>;
};

export type FilterFindManyPostTagCollectionsOperatorsInput = {
  taggedTags?: Maybe<FilterFindManyPostTagCollectionsTaggedTagsOperatorsInput>;
};

export type FilterFindManyPostTagCollectionsTaggedTagsOperatorsInput = {
  tagId?: Maybe<FilterFindManyPostTagCollectionsTaggedTagsTagIdOperatorsInput>;
};

export type FilterFindManyPostTagCollectionsTaggedTagsTagIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPostVoteInput = {
  upvotes?: Maybe<Scalars['Float']>;
  downvotes?: Maybe<Scalars['Float']>;
};

export type FilterFindManyPostWilsonScoreOperatorsInput = {
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyPost_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyTagInput = {
  type?: Maybe<EnumTagType>;
  label?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  relatedTags?: Maybe<Array<Maybe<FilterFindManyTagRelatedTagsInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyTagOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyTagInput>>;
  AND?: Maybe<Array<FilterFindManyTagInput>>;
};

export type FilterFindManyTagItemInput = {
  tagId?: Maybe<Scalars['MongoID']>;
  value?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyTagOperatorsInput = {
  type?: Maybe<FilterFindManyTagTypeOperatorsInput>;
  _id?: Maybe<FilterFindManyTag_IdOperatorsInput>;
};

export type FilterFindManyTagRelatedTagsInput = {
  tagId?: Maybe<Scalars['MongoID']>;
  relationship?: Maybe<EnumTagRelatedTagsRelationship>;
  connectionStrength?: Maybe<Scalars['Float']>;
  attributes?: Maybe<Array<Maybe<FilterFindManyKeyValueInput>>>;
};

export type FilterFindManyTagTypeOperatorsInput = {
  gt?: Maybe<EnumTagType>;
  gte?: Maybe<EnumTagType>;
  lt?: Maybe<EnumTagType>;
  lte?: Maybe<EnumTagType>;
  ne?: Maybe<EnumTagType>;
  in?: Maybe<Array<Maybe<EnumTagType>>>;
  nin?: Maybe<Array<Maybe<EnumTagType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyTag_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyTaggedPostInput = {
  userId?: Maybe<Scalars['MongoID']>;
  postId?: Maybe<Scalars['MongoID']>;
  tagIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  taggedDate?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyTaggedPostOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyTaggedPostInput>>;
  AND?: Maybe<Array<FilterFindManyTaggedPostInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyTaggedPostOperatorsInput = {
  userId?: Maybe<FilterFindManyTaggedPostUserIdOperatorsInput>;
  postId?: Maybe<FilterFindManyTaggedPostPostIdOperatorsInput>;
  tagIds?: Maybe<FilterFindManyTaggedPostTagIdsOperatorsInput>;
  _id?: Maybe<FilterFindManyTaggedPost_IdOperatorsInput>;
};

export type FilterFindManyTaggedPostPostIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyTaggedPostTagIdsOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyTaggedPostUserIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyTaggedPost_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyUserCommunityInput = {
  communityId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
  role?: Maybe<EnumUserCommunityRole>;
  memberSince?: Maybe<Scalars['Date']>;
  status?: Maybe<FilterFindManyDocumentStatusInput>;
  lastVisitedDate?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindManyUserCommunityOperatorsInput>;
  OR?: Maybe<Array<FilterFindManyUserCommunityInput>>;
  AND?: Maybe<Array<FilterFindManyUserCommunityInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyUserCommunityOperatorsInput = {
  userId?: Maybe<FilterFindManyUserCommunityUserIdOperatorsInput>;
  _id?: Maybe<FilterFindManyUserCommunity_IdOperatorsInput>;
};

export type FilterFindManyUserCommunityUserIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyUserCommunity_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDocumentStatusInput = {
  value?: Maybe<EnumDocumentStatusValue>;
  reason?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type FilterFindOneEmailInput = {
  emailAddress?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneImageInput = {
  sizes?: Maybe<Array<Maybe<FilterFindOneImageSizesInput>>>;
};

export type FilterFindOneImageSizesInput = {
  key?: Maybe<EnumImageSizesKey>;
  url?: Maybe<Scalars['String']>;
};

export type FilterFindOneMessageInput = {
  text?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneMessageOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneMessageInput>>;
  AND?: Maybe<Array<FilterFindOneMessageInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneMessageOperatorsInput = {
  _id?: Maybe<FilterFindOneMessage_IdOperatorsInput>;
};

export type FilterFindOneMessage_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneUserAuthIdsOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneUserCommunityInput = {
  communityId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
  role?: Maybe<EnumUserCommunityRole>;
  memberSince?: Maybe<Scalars['Date']>;
  status?: Maybe<FilterFindOneDocumentStatusInput>;
  lastVisitedDate?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneUserCommunityOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneUserCommunityInput>>;
  AND?: Maybe<Array<FilterFindOneUserCommunityInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneUserCommunityOperatorsInput = {
  userId?: Maybe<FilterFindOneUserCommunityUserIdOperatorsInput>;
  _id?: Maybe<FilterFindOneUserCommunity_IdOperatorsInput>;
};

export type FilterFindOneUserCommunityUserIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneUserCommunity_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneUserEmailsEmailAddressOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneUserEmailsOperatorsInput = {
  emailAddress?: Maybe<FilterFindOneUserEmailsEmailAddressOperatorsInput>;
};

export type FilterFindOneUserInput = {
  status?: Maybe<FilterFindOneDocumentStatusInput>;
  username?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  avatar?: Maybe<FilterFindOneImageInput>;
  emails?: Maybe<Array<Maybe<FilterFindOneEmailInput>>>;
  authIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateOfBirth?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Float']>;
  aboutMe?: Maybe<Scalars['String']>;
  memberSince?: Maybe<Scalars['Date']>;
  detailsId?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneUserOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneUserInput>>;
  AND?: Maybe<Array<FilterFindOneUserInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneUserOperatorsInput = {
  status?: Maybe<FilterFindOneUserStatusOperatorsInput>;
  username?: Maybe<FilterFindOneUserUsernameOperatorsInput>;
  emails?: Maybe<FilterFindOneUserEmailsOperatorsInput>;
  authIds?: Maybe<FilterFindOneUserAuthIdsOperatorsInput>;
  _id?: Maybe<FilterFindOneUser_IdOperatorsInput>;
};

export type FilterFindOneUserStatusOperatorsInput = {
  value?: Maybe<FilterFindOneUserStatusValueOperatorsInput>;
};

export type FilterFindOneUserStatusValueOperatorsInput = {
  gt?: Maybe<EnumDocumentStatusValue>;
  gte?: Maybe<EnumDocumentStatusValue>;
  lt?: Maybe<EnumDocumentStatusValue>;
  lte?: Maybe<EnumDocumentStatusValue>;
  ne?: Maybe<EnumDocumentStatusValue>;
  in?: Maybe<Array<Maybe<EnumDocumentStatusValue>>>;
  nin?: Maybe<Array<Maybe<EnumDocumentStatusValue>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneUserUsernameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneUser_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneVoteInput = {
  userId?: Maybe<Scalars['MongoID']>;
  objectType?: Maybe<EnumVoteObjectType>;
  objectId?: Maybe<Scalars['MongoID']>;
  vote?: Maybe<EnumVoteVote>;
  timestamp?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterFindOneVoteOperatorsInput>;
  OR?: Maybe<Array<FilterFindOneVoteInput>>;
  AND?: Maybe<Array<FilterFindOneVoteInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneVoteOperatorsInput = {
  userId?: Maybe<FilterFindOneVoteUserIdOperatorsInput>;
  _id?: Maybe<FilterFindOneVote_IdOperatorsInput>;
};

export type FilterFindOneVoteUserIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneVote_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterObject = {
  __typename?: 'FilterObject';
  type: EnumChannelFilterFilterObjectType;
  condition?: Maybe<Scalars['Int']>;
  payload?: Maybe<FilterObjectPayload>;
};

export type FilterObjectPayload = Tag | User;

export type FilterRemoveManyModificationHistoryInput = {
  objectType?: Maybe<EnumModificationHistoryObjectType>;
  objectId?: Maybe<Scalars['MongoID']>;
  objectVersion?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  payloadSchemaVersion?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['Date']>;
  modifiedByUserId?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveManyModificationHistoryOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveManyModificationHistoryInput>>;
  AND?: Maybe<Array<FilterRemoveManyModificationHistoryInput>>;
};

export type FilterRemoveManyModificationHistoryObjectTypeOperatorsInput = {
  gt?: Maybe<EnumModificationHistoryObjectType>;
  gte?: Maybe<EnumModificationHistoryObjectType>;
  lt?: Maybe<EnumModificationHistoryObjectType>;
  lte?: Maybe<EnumModificationHistoryObjectType>;
  ne?: Maybe<EnumModificationHistoryObjectType>;
  in?: Maybe<Array<Maybe<EnumModificationHistoryObjectType>>>;
  nin?: Maybe<Array<Maybe<EnumModificationHistoryObjectType>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManyModificationHistoryOperatorsInput = {
  objectType?: Maybe<FilterRemoveManyModificationHistoryObjectTypeOperatorsInput>;
  _id?: Maybe<FilterRemoveManyModificationHistory_IdOperatorsInput>;
};

export type FilterRemoveManyModificationHistory_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneMessageInput = {
  text?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneMessageOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneMessageInput>>;
  AND?: Maybe<Array<FilterRemoveOneMessageInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneMessageOperatorsInput = {
  _id?: Maybe<FilterRemoveOneMessage_IdOperatorsInput>;
};

export type FilterRemoveOneMessage_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneTaggedPostInput = {
  userId?: Maybe<Scalars['MongoID']>;
  postId?: Maybe<Scalars['MongoID']>;
  tagIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  taggedDate?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<FilterRemoveOneTaggedPostOperatorsInput>;
  OR?: Maybe<Array<FilterRemoveOneTaggedPostInput>>;
  AND?: Maybe<Array<FilterRemoveOneTaggedPostInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneTaggedPostOperatorsInput = {
  userId?: Maybe<FilterRemoveOneTaggedPostUserIdOperatorsInput>;
  postId?: Maybe<FilterRemoveOneTaggedPostPostIdOperatorsInput>;
  tagIds?: Maybe<FilterRemoveOneTaggedPostTagIdsOperatorsInput>;
  _id?: Maybe<FilterRemoveOneTaggedPost_IdOperatorsInput>;
};

export type FilterRemoveOneTaggedPostPostIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneTaggedPostTagIdsOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneTaggedPostUserIdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type FilterRemoveOneTaggedPost_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type Image = {
  __typename?: 'Image';
  sizes?: Maybe<Array<Maybe<ImageSizes>>>;
};

export type ImageInput = {
  sizes?: Maybe<Array<Maybe<ImageSizesInput>>>;
};

export type ImageSizes = {
  __typename?: 'ImageSizes';
  key?: Maybe<EnumImageSizesKey>;
  url?: Maybe<Scalars['String']>;
};

export type ImageSizesInput = {
  key?: Maybe<EnumImageSizesKey>;
  url?: Maybe<Scalars['String']>;
};

export type InformedConsent = {
  __typename?: 'InformedConsent';
  type: EnumInformedConsentType;
  version: Scalars['String'];
  acceptedDate?: Maybe<Scalars['Date']>;
};

export type InformedConsentInput = {
  type: EnumInformedConsentType;
  version: Scalars['String'];
  acceptedDate?: Maybe<Scalars['Date']>;
};


export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type KeyValueInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type Message = {
  __typename?: 'Message';
  text?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
};

export type ModificationHistory = {
  __typename?: 'ModificationHistory';
  objectType: EnumModificationHistoryObjectType;
  objectId: Scalars['MongoID'];
  objectVersion: Scalars['String'];
  payload: Scalars['JSON'];
  payloadSchemaVersion?: Maybe<Scalars['String']>;
  modifiedDate: Scalars['Date'];
  modifiedByUserId: Scalars['MongoID'];
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
};

export type MongoError = ErrorInterface & {
  __typename?: 'MongoError';
  /** MongoDB error message */
  message?: Maybe<Scalars['String']>;
  /** MongoDB error code */
  code?: Maybe<Scalars['Int']>;
};


export type Mutation = {
  __typename?: 'Mutation';
  /** Create one document with mongoose defaults, setters, hooks and validation */
  addMessage?: Maybe<CreateOneMessagePayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  deleteMessage?: Maybe<RemoveOneMessagePayload>;
  userCreate?: Maybe<User>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  userUpdateById?: Maybe<UpdateByIdUserPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  userDetailsCreateOne?: Maybe<CreateOneUserDetailsPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  userDetailsUpdateById?: Maybe<UpdateByIdUserDetailsPayload>;
  userDetailsUpdateTaggedDate?: Maybe<UserDetails>;
  channelGetOne?: Maybe<Channel>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  communityUpdateById?: Maybe<UpdateByIdCommunityPayload>;
  communityUpdate?: Maybe<UpdateByIdCommunityPayload>;
  communityDelete?: Maybe<UpdateByIdCommunityPayload>;
  userCommunityCreateCommunity?: Maybe<CreateOneUserCommunityPayload>;
  userCommunityDeleteCommunity?: Maybe<RemoveByIdUserCommunityPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  userCommunityUpdateById?: Maybe<UpdateByIdUserCommunityPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  postUpdate?: Maybe<UpdateByIdPostPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  postCreateOne?: Maybe<CreateOnePostPayload>;
  postUpdateById?: Maybe<UpdateByIdPostPayload>;
  postAddTaggedTags: Post;
  postUpdateTaggedTags: Post;
  postVote: Post;
  postUpdateScore: Post;
  postRemoveById?: Maybe<RemoveByIdPostPayload>;
  commentCreateOne?: Maybe<CreateOneCommentPayload>;
  commentUpdateById?: Maybe<UpdateByIdCommentPayload>;
  /** Update target comment scores and its rank among its featured comment peers */
  commentVote: Comment;
  /** Update target comment scores and its rank among its featured comment peers */
  commentUpdateScore: Comment;
  commentRemoveById?: Maybe<RemoveByIdCommentPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  modificationHistoryCreateOne?: Maybe<CreateOneModificationHistoryPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  modificationHistoryRemoveMany?: Maybe<RemoveManyModificationHistoryPayload>;
  voteUpsert?: Maybe<Vote>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  tagCreateMany?: Maybe<CreateManyTagPayload>;
  tagForceCreateMany: Array<Tag>;
  updateRelatedTags?: Maybe<Array<Tag>>;
  taggedPostCreateOne?: Maybe<TaggedPost>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  taggedPostRemoveOne?: Maybe<RemoveOneTaggedPostPayload>;
  taggedPostAddAndRemoveTags?: Maybe<TaggedPost>;
};


export type MutationAddMessageArgs = {
  record: CreateOneMessageInput;
};


export type MutationDeleteMessageArgs = {
  filter?: Maybe<FilterRemoveOneMessageInput>;
  sort?: Maybe<SortRemoveOneMessageInput>;
};


export type MutationUserCreateArgs = {
  fullName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  appLanguage?: Maybe<Scalars['String']>;
  preferedContentLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  acceptedInformedConsents?: Maybe<Array<Maybe<UpdateByIdInformedConsentInput>>>;
};


export type MutationUserUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdUserInput;
};


export type MutationUserDetailsCreateOneArgs = {
  record: CreateOneUserDetailsInput;
};


export type MutationUserDetailsUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdUserDetailsInput;
};


export type MutationUserDetailsUpdateTaggedDateArgs = {
  userId: Scalars['MongoID'];
  date?: Maybe<Scalars['Date']>;
};


export type MutationChannelGetOneArgs = {
  hashId?: Maybe<Scalars['String']>;
  filters: Array<ChannelFilterInput>;
};


export type MutationCommunityUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdCommunityInput;
};


export type MutationCommunityUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdCommunityInput;
  viewerId: Scalars['MongoID'];
};


export type MutationCommunityDeleteArgs = {
  _id: Scalars['MongoID'];
};


export type MutationUserCommunityCreateCommunityArgs = {
  userId: Scalars['MongoID'];
  name: Scalars['String'];
  images?: Maybe<CommunityImagesInput>;
  filters: Array<ChannelFilterInput>;
  settings?: Maybe<Array<Maybe<KeyValueInput>>>;
};


export type MutationUserCommunityDeleteCommunityArgs = {
  userCommunityId: Scalars['MongoID'];
};


export type MutationUserCommunityUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdUserCommunityInput;
};


export type MutationPostUpdateArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdPostInput;
};


export type MutationPostCreateOneArgs = {
  record: CreateOnePostInput;
};


export type MutationPostUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdPostInput;
};


export type MutationPostAddTaggedTagsArgs = {
  _id: Scalars['MongoID'];
  tags: Array<Scalars['MongoID']>;
};


export type MutationPostUpdateTaggedTagsArgs = {
  _id: Scalars['MongoID'];
  addedTags?: Maybe<Array<Scalars['MongoID']>>;
  removedTags?: Maybe<Array<Scalars['MongoID']>>;
  taggedAction?: Maybe<Scalars['String']>;
};


export type MutationPostVoteArgs = {
  _id: Scalars['MongoID'];
  incUpvote?: Maybe<Scalars['Int']>;
  incDownvote?: Maybe<Scalars['Int']>;
};


export type MutationPostUpdateScoreArgs = {
  _id: Scalars['MongoID'];
};


export type MutationPostRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCommentCreateOneArgs = {
  record: CreateOneCommentInput;
};


export type MutationCommentUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: UpdateByIdCommentInput;
};


export type MutationCommentVoteArgs = {
  _id: Scalars['MongoID'];
  incUpvote?: Maybe<Scalars['Int']>;
  incDownvote?: Maybe<Scalars['Int']>;
};


export type MutationCommentUpdateScoreArgs = {
  _id: Scalars['MongoID'];
  parentCommentId?: Maybe<Scalars['MongoID']>;
};


export type MutationCommentRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  postId: Scalars['MongoID'];
  parentCommentId?: Maybe<Scalars['MongoID']>;
};


export type MutationModificationHistoryCreateOneArgs = {
  record: CreateOneModificationHistoryInput;
};


export type MutationModificationHistoryRemoveManyArgs = {
  filter: FilterRemoveManyModificationHistoryInput;
  limit?: Maybe<Scalars['Int']>;
};


export type MutationVoteUpsertArgs = {
  userId: Scalars['MongoID'];
  objectType: Scalars['String'];
  objectId: Scalars['MongoID'];
  vote: Scalars['String'];
};


export type MutationTagCreateManyArgs = {
  records: Array<CreateManyTagInput>;
};


export type MutationTagForceCreateManyArgs = {
  labels: Array<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
};


export type MutationUpdateRelatedTagsArgs = {
  allTags?: Maybe<Array<Scalars['MongoID']>>;
  addedTags?: Maybe<Array<Scalars['MongoID']>>;
  removedTags?: Maybe<Array<Scalars['MongoID']>>;
  relationship: Scalars['String'];
};


export type MutationTaggedPostCreateOneArgs = {
  userId: Scalars['MongoID'];
  postId: Scalars['MongoID'];
};


export type MutationTaggedPostRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneTaggedPostInput>;
  sort?: Maybe<SortRemoveOneTaggedPostInput>;
};


export type MutationTaggedPostAddAndRemoveTagsArgs = {
  userId: Scalars['MongoID'];
  postId: Scalars['MongoID'];
  addedTags: Array<Scalars['MongoID']>;
  removedTags: Array<Scalars['MongoID']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type Post = {
  __typename?: 'Post';
  status?: Maybe<DocumentStatus>;
  version?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  content: Scalars['String'];
  previewContent?: Maybe<Array<Maybe<Scalars['String']>>>;
  plainContent?: Maybe<Scalars['String']>;
  featureCommentIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  vote?: Maybe<PostVote>;
  count?: Maybe<PostCount>;
  tagCollections?: Maybe<PostTagCollections>;
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  scoreInfo?: Maybe<PostScoreInfo>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId: Scalars['MongoID'];
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  settings?: Maybe<Array<Maybe<KeyValue>>>;
  resource?: Maybe<Array<Maybe<KeyValue>>>;
  meta?: Maybe<Array<Maybe<KeyValue>>>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
  createdBy?: Maybe<User>;
  lastModifiedBy?: Maybe<User>;
  userVote?: Maybe<Vote>;
  userTaggedTags?: Maybe<TaggedPost>;
  featureComments: Array<Maybe<Comment>>;
};


export type PostUserVoteArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneVoteInput>;
  userId?: Maybe<Scalars['MongoID']>;
};


export type PostUserTaggedTagsArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneTaggedPostInput>;
  userId?: Maybe<Scalars['MongoID']>;
};

export type PostCount = {
  __typename?: 'PostCount';
  shared?: Maybe<Scalars['Float']>;
  tagged?: Maybe<Scalars['Float']>;
  taggedTags?: Maybe<Scalars['Float']>;
  totalComments?: Maybe<Scalars['Float']>;
  activeRootComments?: Maybe<Scalars['Float']>;
};

export type PostCountInput = {
  shared?: Maybe<Scalars['Float']>;
  tagged?: Maybe<Scalars['Float']>;
  taggedTags?: Maybe<Scalars['Float']>;
  totalComments?: Maybe<Scalars['Float']>;
  activeRootComments?: Maybe<Scalars['Float']>;
};

/** An edge in a connection. */
export type PostEdge = {
  __typename?: 'PostEdge';
  /** The item at the end of the edge */
  node: Post;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type PostFeed = {
  __typename?: 'PostFeed';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Information to aid in pagination. */
  edges: Array<PostEdge>;
};

export type PostScoreInfo = {
  __typename?: 'PostScoreInfo';
  lastUpdatedDate?: Maybe<Scalars['Date']>;
};

export type PostScoreInfoInput = {
  lastUpdatedDate?: Maybe<Scalars['Date']>;
};

export type PostTagCollections = {
  __typename?: 'PostTagCollections';
  taggedTags?: Maybe<Array<Maybe<TagItem>>>;
};

export type PostTagCollectionsInput = {
  taggedTags?: Maybe<Array<Maybe<TagItemInput>>>;
};

export type PostVote = {
  __typename?: 'PostVote';
  upvotes?: Maybe<Scalars['Float']>;
  downvotes?: Maybe<Scalars['Float']>;
};

export type PostVoteInput = {
  upvotes?: Maybe<Scalars['Float']>;
  downvotes?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  viewer?: Maybe<User>;
  userFindById?: Maybe<User>;
  userFindOne?: Maybe<User>;
  userFindByEmailAuthId?: Maybe<User>;
  message?: Maybe<Message>;
  channelGetPosts?: Maybe<Array<Maybe<Post>>>;
  channelFeed?: Maybe<PostFeed>;
  userCommunityFindMany: Array<UserCommunity>;
  userCommunity?: Maybe<UserCommunity>;
  postFindById?: Maybe<Post>;
  postFeed: PostFeed;
  taggedPostFeed?: Maybe<TaggedPostFeed>;
  comments?: Maybe<CommentGetComments>;
  userVote?: Maybe<Vote>;
  tags: Array<Tag>;
  tagsByIds: Array<Tag>;
  searchTags: Array<Tag>;
  postTaggedTags?: Maybe<Array<TaggedPost>>;
  getSuggestedTags?: Maybe<TagSuggestion>;
};


export type QueryViewerArgs = {
  _id?: Maybe<Scalars['MongoID']>;
};


export type QueryUserFindByIdArgs = {
  _id: Scalars['MongoID'];
};


export type QueryUserFindOneArgs = {
  filter?: Maybe<FilterFindOneUserInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneUserInput>;
};


export type QueryUserFindByEmailAuthIdArgs = {
  email?: Maybe<Scalars['String']>;
  authId?: Maybe<Scalars['String']>;
};


export type QueryMessageArgs = {
  filter?: Maybe<FilterFindOneMessageInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneMessageInput>;
};


export type QueryChannelGetPostsArgs = {
  channelId: Scalars['MongoID'];
  sort: SortFeedPostEnum;
  excludeIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryChannelFeedArgs = {
  userCommunityId?: Maybe<Scalars['MongoID']>;
  channelId?: Maybe<Scalars['MongoID']>;
  sort?: Maybe<SortFeedPostEnum>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryUserCommunityFindManyArgs = {
  filter?: Maybe<FilterFindManyUserCommunityInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyUserCommunityInput>;
};


export type QueryUserCommunityArgs = {
  filter?: Maybe<FilterFindOneUserCommunityInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneUserCommunityInput>;
};


export type QueryPostFindByIdArgs = {
  _id: Scalars['MongoID'];
};


export type QueryPostFeedArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyPostInput>;
  sort?: Maybe<SortFeedPostEnum>;
};


export type QueryTaggedPostFeedArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyTaggedPostInput>;
  sort?: Maybe<SortFeedTaggedPostEnum>;
};


export type QueryCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterFindManyCommentInput>;
  sort?: Maybe<SortGetCommentsCommentEnum>;
};


export type QueryUserVoteArgs = {
  filter?: Maybe<FilterFindOneVoteInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneVoteInput>;
};


export type QueryTagsArgs = {
  filter?: Maybe<FilterFindManyTagInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyTagInput>;
};


export type QueryTagsByIdsArgs = {
  tagIds?: Maybe<Array<Scalars['MongoID']>>;
};


export type QuerySearchTagsArgs = {
  text?: Maybe<Scalars['String']>;
  tagType?: Maybe<Scalars['String']>;
};


export type QueryPostTaggedTagsArgs = {
  userIds: Array<Scalars['MongoID']>;
  postId: Scalars['MongoID'];
};


export type QueryGetSuggestedTagsArgs = {
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  queryFor: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  useUnion?: Maybe<Scalars['Boolean']>;
};


export type RemoveByIdCommentPayload = {
  __typename?: 'RemoveByIdCommentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Comment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdPostPayload = {
  __typename?: 'RemoveByIdPostPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Post>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveByIdUserCommunityPayload = {
  __typename?: 'RemoveByIdUserCommunityPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<UserCommunity>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveManyModificationHistoryPayload = {
  __typename?: 'RemoveManyModificationHistoryPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneMessagePayload = {
  __typename?: 'RemoveOneMessagePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Message>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RemoveOneTaggedPostPayload = {
  __typename?: 'RemoveOneTaggedPostPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<TaggedPost>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type RuntimeError = ErrorInterface & {
  __typename?: 'RuntimeError';
  /** Runtime error message */
  message?: Maybe<Scalars['String']>;
};

export enum SortFeedPostEnum {
  FeaturedScore = 'FEATURED_SCORE',
  WilsonScore = 'WILSON_SCORE',
  HotScore = 'HOT_SCORE',
  Newest = 'NEWEST'
}

export enum SortFeedTaggedPostEnum {
  TaggedDateDesc = 'TAGGED_DATE_DESC',
  TaggedDateAsc = 'TAGGED_DATE_ASC',
  NewestContent = 'NEWEST_CONTENT'
}

export enum SortFindManyTagInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  TypeSlugAsc = 'TYPE__SLUG_ASC',
  TypeSlugDesc = 'TYPE__SLUG_DESC',
  RelationshipAsc = 'RELATIONSHIP_ASC',
  RelationshipDesc = 'RELATIONSHIP_DESC',
  RelationshipRelatedtagsTagidAsc = 'RELATIONSHIP__RELATEDTAGS__TAGID_ASC',
  RelationshipRelatedtagsTagidDesc = 'RELATIONSHIP__RELATEDTAGS__TAGID_DESC'
}

export enum SortFindManyUserCommunityInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  UseridCommunityidAsc = 'USERID__COMMUNITYID_ASC',
  UseridCommunityidDesc = 'USERID__COMMUNITYID_DESC',
  UseridCommunityidStatusAsc = 'USERID__COMMUNITYID__STATUS_ASC',
  UseridCommunityidStatusDesc = 'USERID__COMMUNITYID__STATUS_DESC'
}

export enum SortFindOneMessageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneTaggedPostInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  PostidDesc = 'POSTID_DESC',
  PostidAsc = 'POSTID_ASC',
  PostidIdDesc = 'POSTID___ID_DESC',
  PostidIdAsc = 'POSTID___ID_ASC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  UseridPostidAsc = 'USERID__POSTID_ASC',
  UseridPostidDesc = 'USERID__POSTID_DESC',
  TagidsAsc = 'TAGIDS_ASC',
  TagidsDesc = 'TAGIDS_DESC'
}

export enum SortFindOneUserCommunityInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  UseridCommunityidAsc = 'USERID__COMMUNITYID_ASC',
  UseridCommunityidDesc = 'USERID__COMMUNITYID_DESC',
  UseridCommunityidStatusAsc = 'USERID__COMMUNITYID__STATUS_ASC',
  UseridCommunityidStatusDesc = 'USERID__COMMUNITYID__STATUS_DESC'
}

export enum SortFindOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  EmailsEmailaddressAsc = 'EMAILS__EMAILADDRESS_ASC',
  EmailsEmailaddressDesc = 'EMAILS__EMAILADDRESS_DESC',
  EmailsEmailaddressEmailsIsverifiedAsc = 'EMAILS__EMAILADDRESS__EMAILS__ISVERIFIED_ASC',
  EmailsEmailaddressEmailsIsverifiedDesc = 'EMAILS__EMAILADDRESS__EMAILS__ISVERIFIED_DESC',
  AuthidsAsc = 'AUTHIDS_ASC',
  AuthidsDesc = 'AUTHIDS_DESC',
  StatusValueAsc = 'STATUS__VALUE_ASC',
  StatusValueDesc = 'STATUS__VALUE_DESC',
  StatusValueStatusTimestampAsc = 'STATUS__VALUE__STATUS__TIMESTAMP_ASC',
  StatusValueStatusTimestampDesc = 'STATUS__VALUE__STATUS__TIMESTAMP_DESC'
}

export enum SortFindOneVoteInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  UseridObjecttypeAsc = 'USERID__OBJECTTYPE_ASC',
  UseridObjecttypeDesc = 'USERID__OBJECTTYPE_DESC',
  UseridObjecttypeObjectidAsc = 'USERID__OBJECTTYPE__OBJECTID_ASC',
  UseridObjecttypeObjectidDesc = 'USERID__OBJECTTYPE__OBJECTID_DESC'
}

export enum SortGetCommentsCommentEnum {
  FeaturedScore = 'FEATURED_SCORE',
  WilsonScore = 'WILSON_SCORE',
  HotScore = 'HOT_SCORE',
  Newest = 'NEWEST',
  Oldest = 'OLDEST'
}

export enum SortRemoveOneMessageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortRemoveOneTaggedPostInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  PostidDesc = 'POSTID_DESC',
  PostidAsc = 'POSTID_ASC',
  PostidIdDesc = 'POSTID___ID_DESC',
  PostidIdAsc = 'POSTID___ID_ASC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  UseridPostidAsc = 'USERID__POSTID_ASC',
  UseridPostidDesc = 'USERID__POSTID_DESC',
  TagidsAsc = 'TAGIDS_ASC',
  TagidsDesc = 'TAGIDS_DESC'
}

export type Tag = {
  __typename?: 'Tag';
  type?: Maybe<EnumTagType>;
  label: Scalars['String'];
  slug: Scalars['String'];
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  relatedTags?: Maybe<Array<Maybe<TagRelatedTags>>>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
};

export type TagItem = {
  __typename?: 'TagItem';
  tagId?: Maybe<Scalars['MongoID']>;
  value?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
};

export type TagItemInput = {
  tagId?: Maybe<Scalars['MongoID']>;
  value?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
};

export type TagRelatedTags = {
  __typename?: 'TagRelatedTags';
  tagId: Scalars['MongoID'];
  relationship: EnumTagRelatedTagsRelationship;
  connectionStrength?: Maybe<Scalars['Float']>;
  attributes?: Maybe<Array<Maybe<KeyValue>>>;
  tag?: Maybe<Tag>;
};

export type TagRelatedTagsInput = {
  tagId: Scalars['MongoID'];
  relationship: EnumTagRelatedTagsRelationship;
  connectionStrength?: Maybe<Scalars['Float']>;
  attributes?: Maybe<Array<Maybe<KeyValueInput>>>;
};

export type TagSuggestion = {
  __typename?: 'TagSuggestion';
  hashQuery?: Maybe<Scalars['String']>;
  queryCount?: Maybe<Scalars['Float']>;
  queryFor: EnumTagSuggestionQueryFor;
  selectedTags?: Maybe<Array<Maybe<TagSuggestionSelectedTags>>>;
  suggestedTags?: Maybe<Array<Maybe<TagSuggestionSuggestedTags>>>;
  createdAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
};

export type TagSuggestionSelectedTags = {
  __typename?: 'TagSuggestionSelectedTags';
  tagId: Scalars['MongoID'];
  slug: Scalars['String'];
  label: Scalars['String'];
};

export type TagSuggestionSuggestedTags = {
  __typename?: 'TagSuggestionSuggestedTags';
  tag?: Maybe<TagSuggestionSuggestedTagsTag>;
  stats?: Maybe<TagSuggestionSuggestedTagsStats>;
};

export type TagSuggestionSuggestedTagsStats = {
  __typename?: 'TagSuggestionSuggestedTagsStats';
  appearance?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type TagSuggestionSuggestedTagsTag = {
  __typename?: 'TagSuggestionSuggestedTagsTag';
  tagId: Scalars['MongoID'];
  slug: Scalars['String'];
  label: Scalars['String'];
};

export type TaggedPost = {
  __typename?: 'TaggedPost';
  userId: Scalars['MongoID'];
  postId: Scalars['MongoID'];
  tagIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  taggedDate?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
  user?: Maybe<User>;
  post?: Maybe<Post>;
  tags: Array<Maybe<Tag>>;
};

/** An edge in a connection. */
export type TaggedPostEdge = {
  __typename?: 'TaggedPostEdge';
  /** The item at the end of the edge */
  node: TaggedPost;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type TaggedPostFeed = {
  __typename?: 'TaggedPostFeed';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Information to aid in pagination. */
  edges: Array<TaggedPostEdge>;
};

export type UpdateByIdChannelFilterInput = {
  filterObjectType?: Maybe<EnumChannelFilterFilterObjectType>;
  filterObjectId?: Maybe<Scalars['MongoID']>;
  condition?: Maybe<Scalars['Float']>;
};

export type UpdateByIdCommentCountInput = {
  totalSubComments?: Maybe<Scalars['Float']>;
  activeSubComments?: Maybe<Scalars['Float']>;
};

export type UpdateByIdCommentFeaturedSubCommentIdsInput = {
  featured?: Maybe<Array<Maybe<UpdateByIdFeaturedSubCommentInput>>>;
  hot?: Maybe<Array<Maybe<UpdateByIdFeaturedSubCommentInput>>>;
  best?: Maybe<Array<Maybe<UpdateByIdFeaturedSubCommentInput>>>;
  controversial?: Maybe<Array<Maybe<UpdateByIdFeaturedSubCommentInput>>>;
  oldest?: Maybe<Array<Maybe<UpdateByIdFeaturedSubCommentInput>>>;
  newest?: Maybe<Array<Maybe<UpdateByIdFeaturedSubCommentInput>>>;
};

export type UpdateByIdCommentInput = {
  status?: Maybe<UpdateByIdDocumentStatusInput>;
  postId?: Maybe<Scalars['MongoID']>;
  communityId?: Maybe<Scalars['MongoID']>;
  parentCommentId?: Maybe<Scalars['MongoID']>;
  version?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  featuredSubCommentIds?: Maybe<UpdateByIdCommentFeaturedSubCommentIdsInput>;
  vote?: Maybe<UpdateByIdCommentVoteInput>;
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  scoreInfo?: Maybe<UpdateByIdCommentScoreInfoInput>;
  count?: Maybe<UpdateByIdCommentCountInput>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  settings?: Maybe<Array<Maybe<UpdateByIdKeyValueInput>>>;
  resource?: Maybe<Array<Maybe<UpdateByIdKeyValueInput>>>;
  meta?: Maybe<Array<Maybe<UpdateByIdKeyValueInput>>>;
};

export type UpdateByIdCommentPayload = {
  __typename?: 'UpdateByIdCommentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Comment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdCommentScoreInfoInput = {
  lastUpdatedDate?: Maybe<Scalars['Date']>;
};

export type UpdateByIdCommentVoteInput = {
  upvotes?: Maybe<Scalars['Float']>;
  downvotes?: Maybe<Scalars['Float']>;
};

export type UpdateByIdCommunityImagesInput = {
  cover?: Maybe<UpdateByIdImageInput>;
  avatar?: Maybe<UpdateByIdImageInput>;
};

export type UpdateByIdCommunityInput = {
  status?: Maybe<UpdateByIdDocumentStatusInput>;
  basedCommunityId?: Maybe<Scalars['MongoID']>;
  communityCode?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  images?: Maybe<UpdateByIdCommunityImagesInput>;
  filters?: Maybe<Array<Maybe<UpdateByIdChannelFilterInput>>>;
  stats?: Maybe<Array<Maybe<UpdateByIdKeyValueInput>>>;
  settings?: Maybe<Array<Maybe<UpdateByIdKeyValueInput>>>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  privacy?: Maybe<EnumCommunityPrivacy>;
};

export type UpdateByIdCommunityPayload = {
  __typename?: 'UpdateByIdCommunityPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Community>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdDocumentStatusInput = {
  value?: Maybe<EnumDocumentStatusValue>;
  reason?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type UpdateByIdEmailInput = {
  emailAddress?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type UpdateByIdFeaturedSubCommentInput = {
  commentId: Scalars['MongoID'];
  value?: Maybe<Scalars['JSON']>;
};

export type UpdateByIdImageInput = {
  sizes?: Maybe<Array<Maybe<UpdateByIdImageSizesInput>>>;
};

export type UpdateByIdImageSizesInput = {
  key?: Maybe<EnumImageSizesKey>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateByIdInformedConsentInput = {
  type?: Maybe<EnumInformedConsentType>;
  version?: Maybe<Scalars['String']>;
  acceptedDate?: Maybe<Scalars['Date']>;
};

export type UpdateByIdKeyValueInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type UpdateByIdPostCountInput = {
  shared?: Maybe<Scalars['Float']>;
  tagged?: Maybe<Scalars['Float']>;
  taggedTags?: Maybe<Scalars['Float']>;
  totalComments?: Maybe<Scalars['Float']>;
  activeRootComments?: Maybe<Scalars['Float']>;
};

export type UpdateByIdPostInput = {
  status?: Maybe<UpdateByIdDocumentStatusInput>;
  version?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  previewContent?: Maybe<Array<Maybe<Scalars['String']>>>;
  plainContent?: Maybe<Scalars['String']>;
  featureCommentIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  vote?: Maybe<UpdateByIdPostVoteInput>;
  count?: Maybe<UpdateByIdPostCountInput>;
  tagCollections?: Maybe<UpdateByIdPostTagCollectionsInput>;
  featuredScore?: Maybe<Scalars['Float']>;
  wilsonScore?: Maybe<Scalars['Float']>;
  hotScore?: Maybe<Scalars['Float']>;
  scoreInfo?: Maybe<UpdateByIdPostScoreInfoInput>;
  createdDate?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  lastModifiedDate?: Maybe<Scalars['Date']>;
  lastModifiedByUserId?: Maybe<Scalars['MongoID']>;
  settings?: Maybe<Array<Maybe<UpdateByIdKeyValueInput>>>;
  resource?: Maybe<Array<Maybe<UpdateByIdKeyValueInput>>>;
  meta?: Maybe<Array<Maybe<UpdateByIdKeyValueInput>>>;
};

export type UpdateByIdPostPayload = {
  __typename?: 'UpdateByIdPostPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Post>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdPostScoreInfoInput = {
  lastUpdatedDate?: Maybe<Scalars['Date']>;
};

export type UpdateByIdPostTagCollectionsInput = {
  taggedTags?: Maybe<Array<Maybe<UpdateByIdTagItemInput>>>;
};

export type UpdateByIdPostVoteInput = {
  upvotes?: Maybe<Scalars['Float']>;
  downvotes?: Maybe<Scalars['Float']>;
};

export type UpdateByIdTagItemInput = {
  tagId?: Maybe<Scalars['MongoID']>;
  value?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
};

export type UpdateByIdUserCommunityInput = {
  communityId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
  role?: Maybe<EnumUserCommunityRole>;
  memberSince?: Maybe<Scalars['Date']>;
  status?: Maybe<UpdateByIdDocumentStatusInput>;
  lastVisitedDate?: Maybe<Scalars['Date']>;
};

export type UpdateByIdUserCommunityPayload = {
  __typename?: 'UpdateByIdUserCommunityPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<UserCommunity>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdUserDetailsInput = {
  userId?: Maybe<Scalars['MongoID']>;
  locale?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  appLanguage?: Maybe<Scalars['String']>;
  preferedContentLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastTaggedDate?: Maybe<Scalars['Date']>;
  acceptedInformedConsents?: Maybe<Array<Maybe<UpdateByIdInformedConsentInput>>>;
};

export type UpdateByIdUserDetailsPayload = {
  __typename?: 'UpdateByIdUserDetailsPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<UserDetails>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type UpdateByIdUserInput = {
  status?: Maybe<UpdateByIdDocumentStatusInput>;
  username?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  avatar?: Maybe<UpdateByIdImageInput>;
  emails?: Maybe<Array<Maybe<UpdateByIdEmailInput>>>;
  authIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateOfBirth?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Float']>;
  aboutMe?: Maybe<Scalars['String']>;
  memberSince?: Maybe<Scalars['Date']>;
  detailsId?: Maybe<Scalars['MongoID']>;
};

export type UpdateByIdUserPayload = {
  __typename?: 'UpdateByIdUserPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<User>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
};

export type User = {
  __typename?: 'User';
  status?: Maybe<DocumentStatus>;
  username: Scalars['String'];
  fullName: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  avatar?: Maybe<Image>;
  emails?: Maybe<Array<Maybe<Email>>>;
  authIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateOfBirth?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Float']>;
  aboutMe?: Maybe<Scalars['String']>;
  memberSince?: Maybe<Scalars['Date']>;
  detailsId?: Maybe<Scalars['MongoID']>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
  details?: Maybe<UserDetails>;
};

export type UserCommunity = {
  __typename?: 'UserCommunity';
  communityId: Scalars['MongoID'];
  userId: Scalars['MongoID'];
  role?: Maybe<EnumUserCommunityRole>;
  memberSince?: Maybe<Scalars['Date']>;
  status?: Maybe<DocumentStatus>;
  lastVisitedDate?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
  community?: Maybe<Community>;
  user?: Maybe<User>;
};

export type UserDetails = {
  __typename?: 'UserDetails';
  userId: Scalars['MongoID'];
  locale?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  appLanguage?: Maybe<Scalars['String']>;
  preferedContentLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastTaggedDate?: Maybe<Scalars['Date']>;
  acceptedInformedConsents?: Maybe<Array<Maybe<InformedConsent>>>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
};

export type ValidationError = ErrorInterface & {
  __typename?: 'ValidationError';
  /** Combined error message from all validators */
  message?: Maybe<Scalars['String']>;
  /** List of validator errors */
  errors?: Maybe<Array<ValidatorError>>;
};

export type ValidatorError = {
  __typename?: 'ValidatorError';
  /** Validation error message */
  message?: Maybe<Scalars['String']>;
  /** Source of the validation error from the model path */
  path?: Maybe<Scalars['String']>;
  /** Field value which occurs the validation error */
  value?: Maybe<Scalars['JSON']>;
  /** Input record idx in array which occurs the validation error. This `idx` is useful for createMany operation. For singular operations it always be 0. For *Many operations `idx` represents record index in array received from user. */
  idx: Scalars['Int'];
};

export type Vote = {
  __typename?: 'Vote';
  userId: Scalars['MongoID'];
  objectType: EnumVoteObjectType;
  objectId: Scalars['MongoID'];
  vote: EnumVoteVote;
  timestamp?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  id: Scalars['MongoID'];
};
