const getInputMaxWidth = (size?: 's' | 'm' | 'l'): string => {
  switch (size) {
    case 's':
      return '240px';
    case 'm':
      return '320px';
    case 'l':
      return '440px';
    default:
      return '100%';
  }
};

export default getInputMaxWidth;
