/* eslint-disable @typescript-eslint/no-explicit-any, react/static-property-placement */
import { NextPage, NextPageContext } from 'next';
import { getSession } from 'next-auth/client';
import React from 'react';

import { SessionUser } from '#interfaces';

/**
 * If the user need to access to the protected page, they need to authenticate first,
 * which will redirect user to the homepage (landing page) to login then redirect back to initial page.
 */
const withAuth = (
  ComposedComponent: NextPage<any>,
  requiredLogin?: boolean,
): NextPage<any> => {
  const WithAuth: NextPage<any> = (dataProps: any) => (
    <ComposedComponent {...dataProps} />
  );

  WithAuth.displayName = `WithAuth(${ComposedComponent.displayName})`;

  WithAuth.getInitialProps = async (ctx: NextPageContext) => {
    // Evaluate the composed component's getInitialProps()
    let composedInitialProps = {};
    if (ComposedComponent.getInitialProps)
      composedInitialProps = await ComposedComponent.getInitialProps(ctx);

    let viewerId: string | undefined;

    if (typeof window === 'undefined' && ctx.req && ctx.req) {
      const session = await getSession(ctx);
      const viewer = session?.user as SessionUser;

      viewerId = viewer?.id;

      if (
        requiredLogin &&
        !viewerId &&
        typeof ctx.res?.writeHead === 'function'
      ) {
        ctx.res.writeHead(302, {
          Location: `/?redirect-url=${ctx.pathname}`,
        });
        ctx.res.end();
        return {};
      }
    }

    return { ...composedInitialProps, viewerId };
  };

  return WithAuth;
};

export default withAuth;
