import merge from 'deepmerge';

type PropValue =
  | string
  | number
  | (string | number | null | undefined)[]
  | Date
  | null
  | undefined;

const transformer = (value: PropValue): string | number => {
  const v = typeof value === 'string' ? value.trim() : value;
  if (!v) return '';
  if (Array.isArray(v)) return v.filter((x) => x).join(' ');
  if (v instanceof Date) return v.toUTCString();
  return v;
};
const eventPropsFormmater = <
  T extends { [key: string]: PropValue } = {
    [key: string]: PropValue;
  }
>(
  props: T,
  defaultProps: Partial<T> = {},
): { [key: string]: string | number } => {
  const newProps = {} as { [key: string]: string | number };
  const mergedProps = merge(defaultProps, props);

  Object.keys(mergedProps).forEach((key) => {
    newProps[key] = transformer(props[key]);
  });

  return newProps;
};

export default eventPropsFormmater;
