// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isJson = (value: unknown, returnParsed?: boolean): any => {
  if (typeof value !== 'string') return false;
  try {
    const res = JSON.parse(value);
    return returnParsed ? res : true;
  } catch (e) {
    return false;
  }
};

export default isJson;
