import useResponsive from './useResponsive';

/**
 * Get the icon size for `Header` icon based on the screen size.
 */
const useHeaderIconSize = (isImage?: boolean | string | null): number => {
  const [, { ltLg }] = useResponsive();
  return ltLg || isImage ? 24 : 20; // use bigger size for mobile or image
};

export default useHeaderIconSize;
