import { CSSProperties, useCallback, useMemo } from 'react';
import { MediaQueryMatchers, useMediaQuery } from 'react-responsive';

type ScreenSize =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'ltSm'
  | 'ltMd'
  | 'ltLg'
  | 'ltXl'
  | 'gtXs'
  | 'gtSm'
  | 'gtMd'
  | 'gtLg'
  | 'lteSm'
  | 'lteMd'
  | 'lteLg'
  | 'lteXl'
  | 'gteXs'
  | 'gteSm'
  | 'gteMd'
  | 'gteLg';

export type BreakpointContext = [
  <K extends keyof CSSProperties>(
    values: CSSProperties[K][],
  ) => CSSProperties[K],
  Record<ScreenSize, boolean>,
];

// breakpoints
const bps = [576, 768, 992, 1200];

/**
 * A react hook that return a function uses to pick a value from the array
 * by using the breakpoints array as the reference.
 *
 * The secord value of the return tuple is the object that contain the breakpoints
 * checker.
 *
 * Currently, this function can only support up to 4 breakpoints.
 * Which means you can provided up to 5 values to the return function.
 *
 * @param {MediaQueryMatchers} device device props you need to pass to `useMediaQuery`
 * @returns {[Function, object]} tuple of value picker function and breakpoint checkers object
 * @example
 * // value picker function
 * const breakpoints = [421, 768, 1080]
 * const [r] = useBreakpoints()
 * r([300, 480, 680])
 * // current width < 421, r should return 300
 * // current width >= 421 and < 768, r should return 480
 * // current width >= 768, r should return 680
 * // NOTE: the 1080 breakpoint have no used in this case
 */
const useBreakpoints = (device?: MediaQueryMatchers): BreakpointContext => {
  const xs = useMediaQuery({ minWidth: 0 }, device);
  const sm = useMediaQuery({ minWidth: bps[0] }, device);
  const md = useMediaQuery({ minWidth: bps[1] }, device);
  const lg = useMediaQuery({ minWidth: bps[2] }, device);
  const xl = useMediaQuery({ minWidth: bps[3] }, device);
  const bp = [xs, sm, md, lg, xl];
  const bpi = xl ? 4 : bp.indexOf(false) - 1; // return index of the last `true`

  /**
   * Return a value of an array by using the index of current breakpoint.
   *
   * @default const defaultBreakpoints = [600, 960, 1280, 1960];
   *
   * @param {Array<string | number>} values array of values
   * @returns {string | number | undefined} value of current breakpoint
   */
  const r = useCallback(
    <K extends keyof CSSProperties>(
      values: CSSProperties[K][],
    ): CSSProperties[K] => {
      const i = Math.min(bpi, values.length - 1);
      return values[i];
    },
    [bpi],
  );

  const contextValue = useMemo(
    () =>
      [
        r,
        {
          xs: bpi === 0,
          sm: bpi === 1,
          md: bpi === 2,
          lg: bpi === 3,
          xl: bpi === 4,
          ltSm: bpi < 1,
          ltMd: bpi < 2,
          ltLg: bpi < 3,
          ltXl: bpi < 4,
          gtXs: bpi > 0,
          gtSm: bpi > 1,
          gtMd: bpi > 2,
          gtLg: bpi > 3,
          lteSm: bpi <= 1,
          lteMd: bpi <= 2,
          lteLg: bpi <= 3,
          lteXl: bpi <= 4,
          gteXs: bpi >= 0,
          gteSm: bpi >= 1,
          gteMd: bpi >= 2,
          gteLg: bpi >= 3,
        },
      ] as BreakpointContext,
    [bpi, r],
  );

  return contextValue;
};

export default useBreakpoints;
