import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { CBMutationConfig } from '#interfaces';
import { CreateTaggedPostMutation } from '~/CreateTaggedPostMutation.graphql';

const mutation = graphql`
  mutation CreateTaggedPostMutation($userId: MongoID!, $postId: MongoID!) {
    taggedPostCreateOne(userId: $userId, postId: $postId) {
      id
    }
  }
`;

const createTaggedPost = (
  environment: Environment,
  args: {
    userId: string;
    postId: string;
  },
  config?: CBMutationConfig<CreateTaggedPostMutation>,
): Disposable =>
  commitMutation<CreateTaggedPostMutation>(environment, {
    ...config,
    mutation,
    variables: args,
  });

export default createTaggedPost;
