/* eslint-disable @typescript-eslint/no-explicit-any */
import { NextPage } from 'next';
import React from 'react';

import withAuth from './withAuth';
import withData, { WithDataOptions } from './withData';
import withIntl from './withIntl';
import withStyle from './withStyle';

/**
 * Higher order component for Next.js pages.
 */
const withHoc = (
  ComposedComponent: NextPage<any>,
  options: { requiredLogin?: boolean } & WithDataOptions,
): React.ReactNode => {
  const { requiredLogin, query } = options ?? {};
  let page = ComposedComponent;

  // order are important, the above HOCs will provided its props to the HOCs below it.
  page = withAuth(page, requiredLogin);
  page = withStyle(page);
  page = withIntl(page);
  page = query ? withData(page, options) : page;

  return page;
};

export default withHoc;
