export enum PrivacyScope {
  Default = 'Default',
  Public = 'Public',
  OnlyMe = 'OnlyMe',
  Custom = 'Custom',
}

export enum CollaboratorType {
  User = 'User',
  UserTag = 'UserTag',
}

export default null;
