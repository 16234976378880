import { ContentBlock } from 'draft-js';

import { BlockType } from '#enum';

const blockStyle = (defaultBlockStyle?: string) => (
  block: ContentBlock,
): string => {
  const type = block.getType();
  if (type === BlockType.Unstyled)
    return defaultBlockStyle ?? 'editor-default-unstyled';
  return '';
};

export default blockStyle;
