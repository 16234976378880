/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateTaggedPostMutationVariables = {
    userId: string;
    postId: string;
};
export type CreateTaggedPostMutationResponse = {
    readonly taggedPostCreateOne: {
        readonly id: string;
    } | null;
};
export type CreateTaggedPostMutation = {
    readonly response: CreateTaggedPostMutationResponse;
    readonly variables: CreateTaggedPostMutationVariables;
};



/*
mutation CreateTaggedPostMutation(
  $userId: MongoID!
  $postId: MongoID!
) {
  taggedPostCreateOne(userId: $userId, postId: $postId) {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "postId",
        "variableName": "postId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "TaggedPost",
    "kind": "LinkedField",
    "name": "taggedPostCreateOne",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTaggedPostMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateTaggedPostMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "289d7912846d193bb6c894f50ae476a8",
    "id": null,
    "metadata": {},
    "name": "CreateTaggedPostMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTaggedPostMutation(\n  $userId: MongoID!\n  $postId: MongoID!\n) {\n  taggedPostCreateOne(userId: $userId, postId: $postId) {\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '83792e9c1faa822cdf7f1c9033caf9fd';
export default node;
