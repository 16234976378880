import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import fetchQuery from './fetchQuery';

/**
 * Steal from https://github.com/vercel/next.js/blob/canary/examples/with-relay-modern/lib/relay.js.
 */
const createEnvironment = (initialRecords = {}): Environment =>
  new Environment({
    // Create a network layer from the fetch function
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource(initialRecords)),
  });

export default createEnvironment;
