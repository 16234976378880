/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog } from 'react-native-paper';
import ViewOverflow from 'react-native-view-overflow';
import styled from 'styled-components/native';

import Text from '@/Text';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from '#styled-system';

export const Modal = styled(Dialog)<
  LayoutProps & SpaceProps & BorderProps & ColorProps
>`
  align-self: center;
  max-width: 880px;
  max-height: 90%;
  background-color: ${(props: any) => props.theme.colors.surface};
  border-radius: 2px;
  ${layout};
  ${space};
  ${border};
  ${color};
`;

export const Container = styled.ScrollView<
  LayoutProps & SpaceProps & BorderProps
>`
  width: 100%;
  max-width: 990px;
  padding: 20px;
  border-radius: 2px;
  ${layout};
  ${space};
  ${border};
`;

export const OverflowContainer = styled(ViewOverflow)<
  LayoutProps & SpaceProps & BorderProps
>`
  width: 100%;
  max-width: 990px;
  padding: 20px;
  background-color: ${(props: any) => props.theme.colors.surface};
  border-radius: 2px;
  ${layout};
  ${space};
  ${border};
`;

export const TitleContainer = styled.View`
  padding-bottom: 20px;
`;

export const Title = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
  font-weight: 600;
  font-size: 18px;
`;

export default null;
