/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

export const Container = styled.View`
  flex-direction: row;
  width: 192px;
`;

export const LabelContainer = styled.View`
  flex: 7;
  justify-content: center;
`;

export const SwitchContainer = styled.View`
  flex: 2;
  justify-content: center;
  min-height: ${(props: any): string =>
    props.theme.componentSizes?.menuItemHeight};
`;
