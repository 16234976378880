import { logEvent } from 'expo-firebase-analytics';

import { FullPostProps } from '#interfaces/Analytics';

import formatter from '../../helpers/eventPropsFormatter';

type Props = FullPostProps & {
  createdDate: Date | string | null;
};

export const postDeleted = (props: Props): Promise<void> =>
  logEvent('app:post_deleted', formatter(props));

export default postDeleted;
