/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { height, HeightProps } from '#styled-system';

export const Container = styled.ScrollView<HeightProps>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 320px;
  height: 100%;
  padding: 8px;
  ${height};
`;

export default null;
