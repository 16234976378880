/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const ButtonContainer = styled.View`
  margin: 2px;
`;

export const HeaderContainer = styled.View`
  padding-bottom: 16px;
`;

export const HeaderText = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
  font-weight: 600;
  font-size: 16px;
`;

export const FooterContainer = styled.View`
  padding-top: 16px;
`;

export const FooterText = styled(Text)`
  color: ${(props: any) => props.theme.colors.text3};
  font-size: 14px;
`;

export const Link = styled.Pressable`
  color: ${(props: any) => props.theme.colors.blue};
`;

export default null;
