import { AvailableLocale, Locales } from '#interfaces';

import availableLocales from '../locales';
import getLocaleByNameOrBcp47 from './getLocaleByNameOrBcp47';

/**
 * Return the best available locale based on possible locales and user prefer locales.
 */
const findBestAvailableLocale = (
  preferedLocales: readonly string[],
  locales: Locales = availableLocales,
): AvailableLocale | undefined => {
  const filtered = preferedLocales.filter(
    (locale) => typeof locale === 'string',
  );
  const localeList = [
    ...filtered.map((locale) => locale.replace('-', '_')),
    ...filtered.map((locale) => locale.slice(0, 2)),
  ];

  return localeList.find((locale) =>
    getLocaleByNameOrBcp47(locales, locale),
  ) as AvailableLocale | undefined;
};

export const findBestAvailableLocales = (
  preferedLocales: readonly string[],
  locales: Locales = availableLocales,
): AvailableLocale[] | undefined => {
  const filtered = preferedLocales.filter(
    (locale) => typeof locale === 'string',
  );
  const localeList = [
    ...filtered.map((locale) => locale.replace('-', '_')),
    ...filtered.map((locale) => locale.slice(0, 2)),
  ];

  const matches = localeList.map((locale) =>
    getLocaleByNameOrBcp47(locales, locale),
  ) as AvailableLocale[] | undefined;

  return matches?.reduce((res, lo) => {
    if (lo in res) return res;
    return [...res, lo];
  }, [] as AvailableLocale[]);
};

export default findBestAvailableLocale;
