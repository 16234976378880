import { dequal } from 'dequal';
import { Disposable, Environment } from 'react-relay';
import { v1 as uuidv1 } from 'uuid';

import createPreviewContent from '#database/utils/createPreviewContent';
import { ImageSize, MetaKey, ObjectType } from '#enum';
import { TagItem, UserTaggedAction } from '#interfaces';
import {
  convertEditorStateToJSONWithAssets,
  getPlainText,
} from '#lib/draft-js/converter';
import updatePost from '#mutations/UpdatePost';
import updateTaggedTags, { ObjectOperation } from '#mutations/UpdateTaggedTags';
import { compactKVArray, removeExtraSpaces } from '#utils';
import { PostForm_post } from '~/PostForm_post.graphql';
import { UpdatePostMutationResponse } from '~/UpdatePostMutation.graphql';

import { FormValues } from './helpers';

// TODO update fetchPostData ref to the new post
const updatePostFn = async (
  environment: Environment,
  viewerId: string,
  values: FormValues,
  postRef: PostForm_post | null | undefined,
  userTaggedAction?: UserTaggedAction,
  onCompleted?: (res: UpdatePostMutationResponse) => void,
  onError?: (err?: Error) => void,
): Promise<Disposable | undefined> => {
  if (!values.title || !values.content) {
    onError?.();
    return undefined;
  }

  const version = uuidv1();
  const title = removeExtraSpaces(values.title);

  const prevPost = {
    version: postRef?.version,
    title: postRef?.title,
    content: postRef?.content,
  };

  const [
    content,
    metaAttr,
    resource,
  ] = await convertEditorStateToJSONWithAssets(values.content, {
    imageSizes: [ImageSize.Medium, ImageSize.PX1080],
  });
  const [previewContent, previewPoints] = createPreviewContent(content);
  const meta = compactKVArray([
    ...[...(postRef?.meta ?? []), ...(metaAttr ?? [])],
    {
      key: MetaKey.PreviewContentPoints,
      value: previewPoints,
    },
  ]);

  const newPost = {
    title,
    content,
    previewContent,
    plainContent: getPlainText(values.content),
  };
  const isContentChanged =
    !dequal(prevPost.title, newPost.title) ||
    !dequal(prevPost?.content, newPost.content);

  const newDateTime = new Date().toUTCString();
  const dataInfo = isContentChanged
    ? {
        version,
        lastModifiedDate: newDateTime,
        lastModifiedByUserId: viewerId,
      }
    : {
        version: values.version ?? version,
      };

  if (values.id)
    return updatePost(
      environment,
      values.id,
      {
        ...newPost,
        ...dataInfo,
        resource,
        meta,
      },
      {
        onCompleted: (res, err) => {
          onCompleted?.(res);

          if (res.postUpdateById?.record && !err)
            updateTaggedTags(environment, {
              userId: viewerId,
              objectType: ObjectType.Post,
              objectId: res.postUpdateById.record.id,
              prevTagList: postRef?.userTaggedTags?.tags as TagItem[],
              newTagList: values.tags?.selectedTags,
              objectOperation: ObjectOperation.UpdateTaggedTags,
            });
        },
        onError: (err: Error) => {
          onError?.(err);
        },
      },
    );
};

export default updatePostFn;
