import fbt from 'fbt';
import React from 'react';

import SSTagCloud from '@/SSTagCloud';
import TagSearch from '@/TagSearch';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import { getObjectJoinString } from '#utils';

import {
  Container,
  SearchBoxContainer,
  SearchButtonContainer,
} from './TaggedTagsBox.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = React.ComponentProps<typeof SSTagCloud> &
  React.ComponentProps<typeof TagSearch> & {
    searchButtonComponent?: React.ReactElement;
    selectedTagsLimit?: number;
  };

const TaggedTagsBox = ({
  autoFocus,
  suggestedTags,
  selectedTags,
  selectedTagsFooter,
  searchButtonComponent,
  noTagPlaceholder,
  selectedTagsLimit,
  onNewTagPress,
  onSuggestedTagPress,
  onSelectedTagPress,
  getSuggestedTagLinkProps,
  getSelectedTagLinkProps,
  ...props
}: Props): React.ReactElement => {
  useFbt();

  const { colors, isDarkTheme } = useTheme();

  const hadReachLimit =
    !!selectedTagsLimit && (selectedTags?.length ?? 0) >= selectedTagsLimit;

  const disabledStyles = React.useMemo(() => {
    if (!hadReachLimit) return {};
    return isDarkTheme
      ? {
          borderColor: colors?.blackOpac08,
        }
      : {
          bg: colors?.blackOpac04,
        };
  }, [colors?.blackOpac04, colors?.blackOpac08, hadReachLimit, isDarkTheme]);

  const filteredSuggestedTags = React.useMemo(
    () =>
      suggestedTags?.filter?.((t1) =>
        selectedTags?.every((t2) => t1?.id !== t2?.id),
      ),
    [selectedTags, suggestedTags],
  );

  const style = React.useMemo(() => ({ paddingEnd: 38 }), []);

  return (
    <Container>
      <SearchBoxContainer>
        <TagSearch
          disabled={hadReachLimit}
          autoFocus={autoFocus}
          placeholder={fbt('Search tags...', 'input placeholder')}
          getLinkProps={getSuggestedTagLinkProps}
          onTagPress={onSuggestedTagPress}
          onNewTagPress={onNewTagPress}
          style={searchButtonComponent ? style : undefined}
          {...disabledStyles}
          {...props}
        />
        {searchButtonComponent && (
          <SearchButtonContainer>{searchButtonComponent}</SearchButtonContainer>
        )}
      </SearchBoxContainer>
      <SSTagCloud
        selectedTags={selectedTags}
        suggestedTags={filteredSuggestedTags}
        selectedTagsMessage={
          hadReachLimit
            ? fbt('You have reach maximum limit.', 'warning message')
            : undefined
        }
        selectedTagsFooter={selectedTagsFooter}
        noTagPlaceholder={noTagPlaceholder}
        onSelectedTagPress={onSelectedTagPress}
        onSuggestedTagPress={onSuggestedTagPress}
        getSelectedTagLinkProps={getSelectedTagLinkProps}
        getSuggestedTagLinkProps={getSuggestedTagLinkProps}
      />
    </Container>
  );
};

export default React.memo(
  TaggedTagsBox,
  (prev, next) =>
    getObjectJoinString(prev.suggestedTags, ['id']) ===
      getObjectJoinString(next.suggestedTags, ['id']) &&
    getObjectJoinString(prev.selectedTags, ['id']) ===
      getObjectJoinString(next.selectedTags, ['id']) &&
    prev.searchButtonComponent === next.searchButtonComponent &&
    prev.selectedTagsFooter === next.selectedTagsFooter,
);
