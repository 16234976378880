import React from 'react';
import { Switch, Text } from 'react-native';

import {
  Container,
  LabelContainer,
  SwitchContainer,
} from './LabelSwitch.style';

type Props = {
  label: string;
  value?: boolean;
  onValueChange?: (value: boolean) => void;
};

/**
 * Render a label with `Switch` button.
 */
const LabelSwitch = ({
  label,
  value,
  onValueChange,
}: Props): React.ReactElement => (
  <Container>
    <LabelContainer>
      <Text>{label}</Text>
    </LabelContainer>
    <SwitchContainer>
      <Switch value={value} onValueChange={onValueChange} />
    </SwitchContainer>
  </Container>
);

export default LabelSwitch;
