/* eslint-disable @typescript-eslint/no-explicit-any */
import { NextPage, NextPageContext } from 'next';
import React from 'react';

import { getTheme } from '#cookies';
import deviceDetector from '#database/utils/deviceDetector';
import getDeviceType from '#database/utils/getDeviceType';
import { DeviceType, Theme } from '#enum';
import useSession from '#hooks/useSession';
import StyleProvider from '#layers/StyleProvider';

type Props = {
  viewerId?: string | null;
  theme?: Theme;
  deviceType?: DeviceType;
};

const withStyle = (ComposedComponent: NextPage<Props>): NextPage<Props> => {
  const WithStyle: NextPage<Props> = (dataProps: Props) => {
    const { viewerId: ssrViewerId, theme: ssrTheme, deviceType } = dataProps;

    const [session] = useSession();
    const viewerId = session?.user?.id ?? ssrViewerId;
    const theme = ssrTheme ?? (getTheme(null, viewerId) as Theme | undefined);

    return (
      <StyleProvider viewerId={viewerId} theme={theme} deviceType={deviceType}>
        <ComposedComponent {...dataProps} />
      </StyleProvider>
    );
  };

  WithStyle.displayName = `WithStyle(${ComposedComponent.displayName})`;

  WithStyle.getInitialProps = async (ctx: NextPageContext) => {
    let composedInitialProps = {};
    if (ComposedComponent.getInitialProps) {
      composedInitialProps = await ComposedComponent.getInitialProps(ctx);
    }

    const { viewerId } = (composedInitialProps ?? {}) as any;

    const theme = getTheme(ctx, viewerId) as Theme | undefined;
    const userAgent = ctx.req?.headers['user-agent'] ?? undefined;
    const device = userAgent ? deviceDetector.parse(userAgent) : undefined;
    const deviceType = getDeviceType(device?.device);

    return {
      ...composedInitialProps,
      viewerId,
      theme,
      deviceType,
    };
  };

  return WithStyle;
};

export default withStyle;
