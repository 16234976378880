import { FontAwesome } from '@expo/vector-icons';
import { generateFromString } from 'generate-avatar';
import React from 'react';
import { Image } from 'react-native';

import { Icon } from './Avatar.style';

type Props = {
  userId?: string | null;
  imageUri?: string | null;
  size?: number;
  color?: string;
  borderRadius?: number;
};

const Avatar = ({
  userId,
  imageUri,
  size,
  color,
  borderRadius,
}: Props): React.ReactElement => {
  const source = {
    uri:
      (imageUri ||
        (userId && `data:image/svg+xml;utf8,${generateFromString(userId)}`)) ??
      '',
  };

  return source.uri ? (
    <Image
      source={source}
      style={{
        borderRadius: borderRadius ?? (imageUri ? 1000 : (size ?? 20) / 5),
        width: size,
        height: size,
      }}
    />
  ) : (
    <Icon
      icon={(): React.ReactElement => (
        <FontAwesome name="user-circle" size={size} color={color} />
      )}
      size={size}
    />
  );
};

export default React.memo(Avatar);
