import * as Localization from 'expo-localization';
import React from 'react';
import { Portal } from 'react-native-paper';
import { createFragmentContainer, graphql, RelayProp } from 'react-relay';

import InformedConsentForm from '@/InformedConsentForm';
import ModalCard from '@/ModalCard';
import { IInformedConsent } from '#database/models';
import { InformedConsentType } from '#enum';
import useResponsive from '#hooks/useResponsive';
import useSession from '#hooks/useSession';
import findBestAvailableLocale, {
  findBestAvailableLocales,
} from '#i18n/utils/findBestAvailableLocale';
import updateUserDetails from '#mutations/UpdateUserDetails';
import { AuthProvider_viewer } from '~/AuthProvider_viewer.graphql';

type Props = {
  children: React.ReactNode;
  viewer?: AuthProvider_viewer | null;
  relay: RelayProp;
};

const requiredInformedConsents = [
  { type: InformedConsentType.TermsOfService, version: '2021/03/23' },
  { type: InformedConsentType.PrivacyPolicy, version: '2021/03/23' },
];

const AuthProvider = ({
  children,
  viewer,
  relay,
}: Props): React.ReactElement => {
  const [session] = useSession();

  const isUserLoggedIn = !!session?.user;
  const isDataUptoDate =
    session?.user &&
    viewer?.details?.acceptedInformedConsents &&
    requiredInformedConsents.every((a) =>
      viewer.details?.acceptedInformedConsents?.find(
        (b) => a.type === b?.type && a.version === b.version,
      ),
    );

  const [r] = useResponsive();
  const isVisibleRef = React.useRef(session?.user && !isDataUptoDate);
  const [isVisible, setIsVisible] = React.useState<boolean>();

  const handleConfirm = React.useCallback(async () => {
    if (viewer?.detailsId && !isDataUptoDate) {
      const updatedInformedConsents = [
        ...(viewer.details?.acceptedInformedConsents ?? []),
        ...requiredInformedConsents,
      ];
      const { timezone, locale, locales } = Localization;
      const initUserDetails = viewer.details?.acceptedInformedConsents?.length
        ? {}
        : {
            timezone: viewer.details?.timezone ?? timezone,
            appLanguage:
              viewer.details?.appLanguage ?? findBestAvailableLocale([locale]),
            preferedContentLanguages: [
              ...((viewer.details?.preferedContentLanguages ?? []) as string[]),
              ...(findBestAvailableLocales(locales) ?? []),
            ],
          };

      updateUserDetails(relay.environment, {
        userDetailsId: viewer.detailsId,
        acceptedInformedConsents: updatedInformedConsents as IInformedConsent[],
        ...initUserDetails,
      });
      isVisibleRef.current = false;
      setIsVisible(false);
    }
  }, [
    isDataUptoDate,
    relay.environment,
    viewer?.details?.acceptedInformedConsents,
    viewer?.details?.appLanguage,
    viewer?.details?.preferedContentLanguages,
    viewer?.details?.timezone,
    viewer?.detailsId,
  ]);

  React.useEffect(() => {
    if (
      isUserLoggedIn &&
      typeof isVisibleRef.current === 'undefined' &&
      !isDataUptoDate
    ) {
      setIsVisible(true);
      isVisibleRef.current = true;
    } else if (
      isUserLoggedIn &&
      typeof isVisible === 'boolean' &&
      typeof isVisibleRef.current === 'boolean' &&
      isVisibleRef.current !== isVisible
    )
      setIsVisible(isVisibleRef.current);
  }, [isDataUptoDate, isUserLoggedIn, isVisible]);

  return (
    <>
      {isVisibleRef.current && (
        <Portal>
          <ModalCard
            dismissable={false}
            visible={isVisibleRef.current}
            maxWidth={r(['100%', '550px'])}
          >
            <InformedConsentForm onConfirm={handleConfirm} />
          </ModalCard>
        </Portal>
      )}
      {children}
    </>
  );
};

export default createFragmentContainer(AuthProvider, {
  viewer: graphql`
    fragment AuthProvider_viewer on User {
      detailsId
      details {
        timezone
        appLanguage
        preferedContentLanguages
        acceptedInformedConsents {
          type
          version
        }
      }
    }
  `,
});
