import AsyncStorage from './AsyncStorage';

/**
 * Storage for store current viewer data.
 */
export default class Viewer {
  static storage = AsyncStorage;

  static storeId(id: string): Promise<void | null> {
    return this.storage.set('viewer:id', id);
  }

  static async getId(): Promise<string | null> {
    return this.storage.get('viewer:id');
  }

  static removeId(): Promise<void | null> {
    return this.storage.remove('viewer:id');
  }
}
