import styled from 'styled-components/native';

import { margin, MarginProps } from '#styled-system';

export const Container = styled.View<MarginProps>`
  align-items: flex-end;
  margin-top: auto;
  margin-end: 24px;
  margin-bottom: 12px;
  margin-start: auto;
  border-radius: 1000px;
  ${margin};
`;

export default null;
