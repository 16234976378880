import {
  BlockquoteButton,
  BoldButton,
  CodeButton,
  DraftJsStyleButtonProps,
  ItalicButton,
  OrderedListButton,
  SubButton,
  SupButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import createImagePlugin from '@draft-js-plugins/image';
import { EditorProps, EditorState } from 'draft-js';
import * as ImagePicker from 'expo-image-picker';
import React from 'react';
import { Platform, View } from 'react-native';

import EditorButton from '@/EditorButton';

import { ButtonContainer } from './EditorToolbar.style';

type Props = {
  editorState: EditorState;
  onChange?: EditorProps['onChange'];
} & React.PropsWithChildren<DraftJsStyleButtonProps>;

const buttonWidth = 36; // get this value by inspecting in developer tools
export const imagePlugin = createImagePlugin();

const EditorToolbar = ({ editorState, onChange, ...props }: Props) => {
  const containerRef = React.useRef<View>(null);
  const [maxButtonNumber, setMaxButtonNumber] = React.useState<number>();

  const { getEditorState, setEditorState } = props;

  const insertImage = React.useCallback(
    (es: EditorState) => async () => {
      if (Platform.OS !== 'web') {
        const {
          status,
        } = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (status !== 'granted') {
          // eslint-disable-next-line no-alert
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }

      const image = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 1,
      });

      if (!image.cancelled) {
        const newEditorState = imagePlugin.addImage(es, image.uri, {});
        onChange?.(newEditorState);
      }
    },
    [onChange],
  );

  const buttons = React.useMemo<React.ReactElement[]>(
    () => [
      <BoldButton key="bold" {...props} />,
      <ItalicButton key="italic" {...props} />,
      <UnderlineButton key="underline" {...props} />,
      <UnorderedListButton key="unordered-list" {...props} />,
      <OrderedListButton key="ordered-list" {...props} />,
      <BlockquoteButton key="blockquote" {...props} />,
      <CodeButton key="code" {...props} />,
      <SupButton key="sup" {...props} />,
      <SubButton key="sub" {...props} />,
      <EditorButton
        key="image"
        icon="image"
        disableActiveColor
        onPress={insertImage(editorState)}
      />,
    ],
    [editorState, insertImage, props],
  );

  const [visibleButtons, ellipsisButtons] = React.useMemo(() => {
    if (!maxButtonNumber) return [];

    const buttonLength = buttons.length;
    const visibleNumber =
      Math.min(maxButtonNumber, buttonLength) -
      (maxButtonNumber < buttonLength ? 1 : 0);

    return [buttons.slice(0, visibleNumber), buttons.slice(visibleNumber)];
  }, [buttons, maxButtonNumber]);

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted)
      containerRef.current?.measure((_x, _y, _w) => {
        const n = Math.floor(_w / buttonWidth);
        setMaxButtonNumber(n);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return typeof getEditorState === 'function' &&
    typeof setEditorState === 'function' ? (
    <ButtonContainer ref={containerRef}>
      <EditorButton
        key="image"
        icon="image"
        disableActiveColor
        onPress={insertImage(editorState)}
      />
      {visibleButtons?.map((button) => button)}
      {ellipsisButtons?.length ? (
        <EditorButton icon="dots-horizontal">
          {ellipsisButtons.map((button) => button)}
        </EditorButton>
      ) : null}
    </ButtonContainer>
  ) : null;
};

export default React.memo(EditorToolbar);
