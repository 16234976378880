import * as SecureStore from 'expo-secure-store';

import { SecureStorageModel, SecureStorageModelKeys } from '#interfaces';
import { parseValue } from '#utils';

/**
 * The custom expo based `SecureStore` with type checking.
 */
export default class Storage {
  static async get<K extends SecureStorageModelKeys>(
    key: K,
  ): Promise<SecureStorageModel[K] | null> {
    const value = await SecureStore.getItemAsync(key);
    return parseValue(value);
  }

  static async set<K extends SecureStorageModelKeys>(
    key: K,
    value: SecureStorageModel[K],
  ): Promise<void> {
    return SecureStore.setItemAsync(key, JSON.stringify(value));
  }

  static async remove(key: SecureStorageModelKeys): Promise<void> {
    return SecureStore.deleteItemAsync(key);
  }
}
