import { Types } from 'mongoose';

import { Tag } from '#interfaces';

type ValidTag = Required<Tag>;

const mergeTagList = (
  aList?: Tag[] | null,
  bList?: Tag[] | null,
): {
  validTags: ValidTag[];
  invalidTags: Tag[];
} => {
  const a = (aList ?? []).reduce(
    ({ valid, invalid }, tag) =>
      tag?.id && tag?.label && tag?.slug && Types.ObjectId.isValid(tag?.id)
        ? { valid: [...valid, tag] as ValidTag[], invalid }
        : { valid, invalid: [...invalid, tag] },
    { valid: [], invalid: [] } as { valid: ValidTag[]; invalid: Tag[] },
  );
  const b = (bList ?? []).reduce(
    ({ valid, invalid }, tag) =>
      tag?.id && tag?.label && tag?.slug && Types.ObjectId.isValid(tag?.id)
        ? { valid: [...valid, tag] as ValidTag[], invalid }
        : { valid, invalid: [...invalid, tag] },
    { valid: [], invalid: [] } as { valid: ValidTag[]; invalid: Tag[] },
  );

  // get validTags by remove tags with duplicated id
  const validTags = [
    ...a.valid,
    ...b.valid.filter((t1) => a.valid.every((t2) => t1.id !== t2.id)),
  ];

  return {
    validTags,
    invalidTags: [
      ...a.invalid.filter((t1) => validTags.every((t2) => t1.slug !== t2.slug)),
      ...b.invalid.filter((t1) =>
        [...a.invalid, ...validTags].every((t2) => t1.slug !== t2.slug),
      ),
    ],
  };
};

export default mergeTagList;
