/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { color, ColorProps, typography, TypographyProps } from '#styled-system';

export const LogoText = styled.Text<ColorProps & TypographyProps>`
  font-size: 26px;
  font-family: '${(props: any) => props.theme.fonts.logo}';
  ${color};
  ${typography};
`;

export default null;
