import React from 'react';

import SignUpLoginModal from '@/SignUpLoginModal';

export type ModalContext = {
  openSignUpLoginModal: () => void;
  closeSignUpLoginModal: () => void;
};

export const ModalContext = React.createContext<ModalContext>({
  openSignUpLoginModal: () => null,
  closeSignUpLoginModal: () => null,
});

type Props = {
  children: React.ReactElement;
};

const ModalProvider = ({ children }: Props): React.ReactElement => {
  const [isSignUpLoginVisible, setIsSignUpLoginVisible] = React.useState(false);

  const openSignUpLoginModal = React.useCallback(() => {
    setIsSignUpLoginVisible(true);
  }, []);

  const closeSignUpLoginModal = React.useCallback(() => {
    setIsSignUpLoginVisible(false);
  }, []);

  const contextValue = React.useMemo(
    () => ({
      openSignUpLoginModal,
      closeSignUpLoginModal,
    }),
    [closeSignUpLoginModal, openSignUpLoginModal],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <SignUpLoginModal
        visible={isSignUpLoginVisible}
        onDismiss={closeSignUpLoginModal}
      />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
