import { DefaultTheme } from 'react-native-paper';

import { fonts } from '#styles/variables';

export default {
  fonts: {
    logo: fonts.logo,
    regular: fonts.based,
    officinaSans: `${fonts.OfficinaSans}`,
    roboto: `${fonts.Roboto}`,
  },
  colors: {
    // primary: '#24292e', // black raisin
    // primary: '#113f94', // '#153f63',
    // primary: '#054f8e',
    primary: '#11519e', // '#154c90',
    accent: '#fdfff5',
    brand: '#11519e', // '#154c90',

    // role colors
    text0: DefaultTheme.colors.text,
    text: '#232323',
    text1: '#333',
    text2: 'rgba(0, 0, 0, 0.6)',
    text3: 'rgba(0, 0, 0, 0.38)',
    text4: 'rgba(0, 0, 0, 0.3)',
    placeholder: DefaultTheme.colors.placeholder,
    pageBackground: '#f1f2f3',
    surface: DefaultTheme.colors.surface,
    backdrop: 'rgba(0, 0, 0, 0.6)',

    // general colors
    raisinBlack: '#24292e',
    blue: '#007bff',
    blue2: '#4996ec',
    indigo: '#6610f2',
    purple: '#6d49cb',
    pink: '#e83e8c',
    red: '#dc3545',
    red2: '#ff5555',
    orange: '#fd7e14',
    yellow: '#ffc107',
    yellow2: '#ffbe00',
    green: '#28a745',
    teal: '#20c997',
    cyan: '#17a2b8',
    black: '#000',
    white: '#fff',
    gray: '#5e5e5e',
    'gray-dark': '#404040',
    success: '#108548',
    info: '#1f75cb',
    warning: '#fc9403',
    danger: '#dd2b0e',
    light: '#dbdbdb',
    light2: '#eee',
    light3: '#f1f2f3',
    light4: '#f7f8f9',
    dark: '#404040',

    blackOpac: 'rgba(0, 0, 0, 0.2)',
    blackOpac25: 'rgba(0, 0, 0, 0.25)',
    blackOpac20: 'rgba(0, 0, 0, 0.20)',
    blackOpac10: 'rgba(0, 0, 0, 0.1)',
    blackOpac08: 'rgba(0, 0, 0, 0.08)',
    blackOpac05: 'rgba(0, 0, 0, 0.05)',
    blackOpac04: 'rgba(0, 0, 0, 0.04)',

    floatBox: {
      backgroundColor: '#fff',
      border: 'solid 1px #f4f4f4',
      boxShadow: '0 2px 5px rgba(64, 60, 67, 0.16)',
    },

    form: {
      input: {
        border: '#80dbff',
      },
    },

    tag: {
      blue: {
        label: '#2565ad',
        backgroundColor: '#e8f5ff',
      },
    },
  },
  componentSizes: {
    headerHeight: 40,
    menuItemHeight: '24px',
  },
};
