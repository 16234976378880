import { logEvent } from 'expo-firebase-analytics';

import formatter from '../../helpers/eventPropsFormatter';

type Props = {
  prevLanguages?: string | (string | null)[] | null;
  newLanguages?: string | (string | null)[] | null;
};

export const userContentLanguagesUpdated = (props: Props): Promise<void> =>
  logEvent('app:user_contentLanguagesUpdated', formatter(props));

export default userContentLanguagesUpdated;
