import { Children, isValidElement, ReactNode } from 'react';

const countReactChildren = (
  children: ReactNode,
  countEmpty?: boolean,
): number => {
  if (countEmpty) return Children.count(children);

  return Children.toArray(children).filter((child) => isValidElement(child))
    .length;
};

export default countReactChildren;
