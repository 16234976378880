/* eslint-disable @typescript-eslint/no-explicit-any */
/* stylelint-disable selector-max-type */
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-max-id */
/* stylelint-disable selector-pseudo-class-whitelist */
import { css } from 'styled-components';

import d from '#styles/themes/dark';
import l from '#styles/themes/main';

const themeBuilder = (theme: 'light' | 'dark'): any => {
  const t = theme === 'dark' ? d : l;

  return css`
    body {
      .duet-date__input {
        height: 35px;
        padding: 5px 10px;
        color: ${t.colors.text};
        font-size: 14px;
        background-color: ${t.colors.surface};
        border: solid 1px ${t.colors.light};
        border-radius: 4px;
      }

      .duet-date__input::placeholder {
        color: ${t.colors.placeholder};
      }

      .duet-date__toggle {
        width: 36px;
        box-shadow: none;
      }

      .duet-date__toggle-icon {
        color: ${t.colors.text2};

        svg {
          height: 22px;
        }
      }

      .duet-date__dialog-content {
        color: ${t.colors.text2};
        background-color: ${t.colors.floatBox.backgroundColor};
        border: ${t.colors.floatBox.border};
        border-radius: 4px;
        box-shadow: ${t.colors.floatBox.boxShadow};
      }
    }
  `;
};

export default themeBuilder;
