/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReadonlyDeep } from 'type-fest';

import { BasicPostProps } from '#interfaces';

type PropKeys = keyof BasicPostProps;

const propKeys = [
  'contentMedia',
  'contentLanguages',
  'creatorLocale',
  'creatorTimezone',
];

const getBasicPostProps = (
  contentMeta:
    | ReadonlyDeep<({ key: string; value: any } | null)[]>
    | undefined
    | null,
): BasicPostProps | undefined => {
  if (!contentMeta) return undefined;

  const res = {} as BasicPostProps;
  contentMeta.forEach((meta) => {
    if (meta && propKeys.includes(meta.key))
      res[meta.key as PropKeys] = meta.value;
  });
  return res;
};

export default getBasicPostProps;
