import { xxHash32 } from 'js-xxhash';

import { ObjectType } from '#enum';

const getTagsHash = (
  tagIds: string[] | undefined,
  queryFor: ObjectType,
  userId?: string,
): string =>
  xxHash32(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    `${userId}::${queryFor}::${tagIds?.sort().join(',') || '-'}`,
    0xc0ffee,
  ).toString(16);

export default getTagsHash;
