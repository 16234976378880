import React from 'react';
import { Chip } from 'react-native-paper';

import useTheme from '#hooks/useTheme';

type Props = {
  color?: 'blue';
} & React.ComponentProps<typeof Chip>;

const Tag = ({ color, ...props }: Props): React.ReactElement => {
  const { colors } = useTheme();

  const tagColor = color ?? 'blue';

  return (
    <Chip
      {...props}
      style={{
        backgroundColor: colors?.tag?.[tagColor].backgroundColor,
        borderWidth: 0,
        borderRadius: 4,
        marginVertical: 4,
        marginStart: 2,
        marginEnd: 4,
      }}
      textStyle={{
        color: colors?.tag?.blue.label,
        marginEnd: 4,
        marginStart: 4,
        marginVertical: 2,
      }}
    />
  );
};

export default React.memo(Tag, () => true);
