import decay from 'decay';

import { day, hour, minute, month, week } from './milliseconds';

const wilsonScore = decay.wilsonScore();
const redditHot = decay.redditHot();
const redditHotWeek = decay.redditHot(week / 1000);

export const getScore = (
  upvotes: number,
  downvotes: number,
  createdDate: Date,
): { featuredScore: number; wilsonScore: number; hotScore: number } => {
  const bestScore = wilsonScore(upvotes, downvotes);
  const hotScore = redditHotWeek(upvotes, downvotes, createdDate);

  return {
    featuredScore: Math.max(bestScore, hotScore),
    wilsonScore: bestScore,
    hotScore: redditHot(upvotes, downvotes, createdDate),
  };
};

const scoreUpdateThreshold = (ageMs: number): number => {
  switch (true) {
    case ageMs < hour:
      return minute * 5;
    case ageMs < day:
      return hour;
    case ageMs < week:
      return hour * 4;
    case ageMs < month:
      return hour * 12;
    default:
      return day;
  }
};

export const shouldUpdateScore = (
  createdDate: Date,
  lastUpdatedDate?: Date | null,
): boolean => {
  if (!lastUpdatedDate) return true;

  const now = new Date().getTime();
  const ageMs = now - createdDate.getTime();

  return now - lastUpdatedDate.getTime() > scoreUpdateThreshold(ageMs);
};

export default null;
