/* eslint-disable @typescript-eslint/no-explicit-any, no-restricted-globals */
import { get, system, compose } from '@styled-system/core'; // eslint-disable-line

import { Config, Scale } from '#interfaces/StyledSystem';

const defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

const isNumber = (n: any): boolean => typeof n === 'number' && !isNaN(n);

const getMargin = (n: any, scale?: Scale): any => {
  if (!isNumber(n)) {
    return get(scale, n, n);
  }

  const isNegative = n < 0;
  const absolute = Math.abs(n);
  const value = get(scale, absolute, absolute);
  if (!isNumber(value)) {
    return isNegative ? `-${value}` : value;
  }
  return value * (isNegative ? -1 : 1);
};

const configs: { [key: string]: Config } = {};
configs.margin = {
  marginStart: {
    property: 'marginStart',
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
  marginEnd: {
    property: 'marginEnd',
    scale: 'space',
    transform: getMargin,
    defaultScale: defaults.space,
  },
};
configs.margin.ms = configs.margin.marginStart;
configs.margin.me = configs.margin.marginEnd;

configs.padding = {
  paddingStart: {
    property: 'paddingStart',
    scale: 'space',
    defaultScale: defaults.space,
  },
  paddingEnd: {
    property: 'paddingEnd',
    scale: 'space',
    defaultScale: defaults.space,
  },
};
configs.padding.ps = configs.padding.paddingStart;
configs.padding.pe = configs.padding.paddingEnd;

export const margin = system(configs.margin);
export const padding = system(configs.padding);
export const space = compose(margin, padding);

export default space;
