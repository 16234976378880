export enum FeedSort {
  Newest = 'NEWEST',
  HotScore = 'HOT_SCORE',
  FeaturedScore = 'FEATURED_SCORE',
  WilsonScore = 'WILSON_SCORE',
}

export enum TaggedFeedSort {
  Newest = 'NEWEST',
  TaggedDateAsc = 'TAGGED_DATE_ASC',
  TaggedDateDesc = 'TAGGED_DATE_DESC',
}

export enum FeedScope {
  Global = 'global',
  Tagged = 'tagged',
}

export enum CommentFeedSort {
  FeaturedScore = 'FEATURED_SCORE',
  Newest = 'NEWEST',
  Oldest = 'OLDEST',
  HotScore = 'HOT_SCORE',
  WilsonScore = 'WILSON_SCORE',
  Controversial = 'CONTROVERSIAL',
}

export enum FeaturedSubCommentType {
  Featured = 'featured',
  Best = 'best',
  Hot = 'hot',
  Newest = 'newest',
  Oldest = 'oldest',
  Controversial = 'controversial',
}

export default null;
