/* stylelint-disable selector-max-type */
/* stylelint-disable react-native/css-property-no-unknown */
import { DraftJsStyleButtonProps } from '@draft-js-plugins/buttons';
import DraftJSEditor from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import { css } from '@emotion/css';
import { EditorProps, EditorState } from 'draft-js';
import React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import EditorToolbar, { imagePlugin } from '@/EditorToolbar/EditorToolbar';
import useTheme from '#hooks/useTheme';
import ds from '#lib/draft-js/dataSets';
import moveFocusToEnd from '#lib/draft-js/moveFocusToEnd';

import blockRenderer from './blockRenderer';
import blockStyle from './blockStyle';
import {
  EditorContainer,
  EditorFiller,
  FirstContainer,
  FooterContainer,
  SecondContainer,
} from './Editor.style';

type Props = Omit<EditorProps, 'onChange'> & {
  onChange?: EditorProps['onChange']; // to make it optional
  /**
   * This props allow you to target a specific draft editor in css.
   */
  dataSet?: Record<string, string | number>;
  style?: StyleProp<TextStyle & ViewStyle>;
  showToolbar?: boolean;
  autoFocus?: boolean;
  blurOnEnter?: boolean;
  defaultBlockStyle?: string;
  helperToolbar?: React.ReactNode;
};

/**
 * Draft.js was quiet troublesome when it's come to styling.
 *
 * To add the style to this component, please use `style` props.
 */
const Editor = ({
  dataSet,
  style,
  showToolbar,
  autoFocus,
  blurOnEnter,
  defaultBlockStyle,
  helperToolbar,
  editorState,
  onChange,
  onFocus,
  onBlur,
  handleKeyCommand,
  keyBindingFn,
  ...rest
}: Props): React.ReactElement => {
  const { colors } = useTheme();
  const isFocused = React.useRef(false);
  const editorRef = React.useRef<DraftJSEditor | null>(null);
  const getEditorStateRef = React.useRef<() => EditorState>();
  const setEditorStateRef = React.useRef<(editorState: EditorState) => void>();

  const staticToolbarPlugin = React.useMemo(
    () =>
      createToolbarPlugin({
        theme: {
          toolbarStyles: {
            toolbar: css`
              z-index: 2;
              display: inline-flex;
              flex: 1 1 auto;
              flex-flow: row nowrap;
              padding: 3px 0 2px;
              border: none;

              div {
                flex-flow: row nowrap;
              }
            `,
          },
          buttonStyles: {
            active: css`
              z-index: 2;
              color: ${colors?.orange} !important;

              svg {
                fill: ${colors?.orange} !important;
              }
            `,
            button: css`
              z-index: 2;
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 36px;
              height: 36px;
              margin: 0;
              padding: 0;
              color: ${colors?.text2};
              background-color: transparent;
              border: none;
              border-radius: 4px;

              svg {
                fill: ${colors?.text2};
              }
            `,
            buttonWrapper: css`
              z-index: 2;
              display: inline-block;
              border: 0;
            `,
          },
        },
      }),
    [colors?.orange, colors?.text2],
  );
  const { Toolbar } = React.useMemo(() => staticToolbarPlugin, [
    staticToolbarPlugin,
  ]);
  const plugins = React.useMemo(() => [staticToolbarPlugin, imagePlugin], [
    staticToolbarPlugin,
  ]);
  const customStyleMap = React.useMemo(
    () => ({
      SUPERSCRIPT: {
        fontSize: 10,
        verticalAlign: 'super',
      },
      SUBSCRIPT: {
        fontSize: 10,
        verticalAlign: 'sub',
      },
    }),
    [],
  );

  const focusEditor = React.useCallback(() => {
    editorRef.current?.focus();
    onChange?.(moveFocusToEnd(editorState));
  }, [editorState, onChange]);

  const handleChange = React.useCallback(() => null, []);

  const editorToolbar = React.useCallback(
    (externalProps: React.PropsWithChildren<DraftJsStyleButtonProps>) => {
      if (
        typeof externalProps.getEditorState === 'function' &&
        typeof externalProps.setEditorState === 'function'
      ) {
        getEditorStateRef.current = externalProps.getEditorState;
        setEditorStateRef.current = externalProps.setEditorState;
      }

      return getEditorStateRef.current && setEditorStateRef.current ? (
        <EditorToolbar
          editorState={editorState}
          onChange={onChange}
          {...externalProps}
          getEditorState={getEditorStateRef.current}
          setEditorState={setEditorStateRef.current}
        />
      ) : null;
    },
    [editorState, onChange],
  );

  const handleKeyBindingFn = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (!e.shiftKey && e.key === 'Enter' && blurOnEnter !== false)
        editorRef.current?.blur();
      return keyBindingFn?.(e);
    },
    [blurOnEnter, keyBindingFn],
  );

  const handleBlockStyle = React.useMemo(() => blockStyle(defaultBlockStyle), [
    defaultBlockStyle,
  ]);

  React.useEffect(() => {
    if (autoFocus && !isFocused.current) {
      isFocused.current = true;
      focusEditor();
    }
  }, [autoFocus, focusEditor]);

  return (
    <>
      <EditorContainer
        style={style}
        dataSet={{ ...(dataSet ?? ds.Editor), ...ds.Based }}
      >
        <DraftJSEditor
          ref={(element) => {
            editorRef.current = element;
          }}
          blockStyleFn={handleBlockStyle}
          blockRendererFn={blockRenderer}
          customStyleMap={customStyleMap}
          editorState={editorState}
          onChange={onChange ?? handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          keyBindingFn={handleKeyBindingFn as any}
          handleKeyCommand={handleKeyCommand}
          plugins={plugins}
          readOnly={rest.readOnly}
          placeholder={rest.placeholder}
        />
        <EditorFiller onPress={focusEditor} />
      </EditorContainer>
      {showToolbar ? (
        <FooterContainer>
          {editorToolbar ? (
            <FirstContainer>
              <Toolbar>{editorToolbar}</Toolbar>
            </FirstContainer>
          ) : null}
          {helperToolbar ? (
            <SecondContainer>{helperToolbar}</SecondContainer>
          ) : null}
        </FooterContainer>
      ) : null}
    </>
  );
};

export default Editor;
