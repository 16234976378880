import { Link } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { Platform } from 'react-native';

import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';

import {
  ButtonsContainer,
  CancelButton,
  CancelButtonContainer,
  SubmitButton,
  SubmitButtonContainer,
  SubmitIndicator,
} from './FormFooterButtons.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  submitText?: string;
  cancelText?: string;
  submitColor?: { text?: string; bg?: string; border?: string };
  cancelColor?: { text?: string; bg?: string; border?: string };
  onSubmit: () => void;
  onCancel?: () => void;
  noCancel?: boolean;
  disabled?: boolean;
  isSubmitting?: boolean;
};

const FormFooterButtons = ({
  submitText,
  cancelText,
  submitColor,
  cancelColor,
  onSubmit,
  onCancel,
  noCancel,
  disabled = false,
  isSubmitting = false,
}: Props): React.ReactElement => {
  useFbt();

  const { colors, isDarkTheme } = useTheme();
  const uppercase = Platform.OS !== 'web';
  const disableSubmitButton = disabled || !!isSubmitting;

  const cancelButton = React.useMemo(
    () => (
      <CancelButton
        mode="outlined"
        uppercase={uppercase}
        color={cancelColor?.text ?? colors?.text}
        bg={cancelColor?.bg ?? (isDarkTheme ? colors?.light : undefined)}
        borderColor={cancelColor?.border}
        onPress={onCancel}
        disabled={isSubmitting}
      >
        {cancelText ?? <fbt desc="button to cancel form">Cancel</fbt>}
      </CancelButton>
    ),
    [
      cancelColor?.bg,
      cancelColor?.border,
      cancelColor?.text,
      cancelText,
      colors?.light,
      colors?.text,
      isDarkTheme,
      isSubmitting,
      onCancel,
      uppercase,
    ],
  );

  const labelStyle = React.useMemo(
    () => ({
      color: disableSubmitButton ? colors?.text3 : colors?.white,
    }),
    [colors?.text3, colors?.white, disableSubmitButton],
  );

  const cancelLinkWeb = React.useMemo(() => ({ path: '/' }), []);

  return (
    <ButtonsContainer>
      <SubmitButtonContainer>
        <SubmitButton
          mode="outlined"
          uppercase={uppercase}
          color={submitColor?.text}
          bg={submitColor?.bg}
          borderColor={submitColor?.border}
          onPress={onSubmit}
          disabled={disableSubmitButton}
          labelStyle={labelStyle}
        >
          {submitText ?? <fbt desc="button to submit form">Submit</fbt>}
        </SubmitButton>
        {isSubmitting && <SubmitIndicator />}
      </SubmitButtonContainer>
      {!noCancel && (
        <CancelButtonContainer>
          {onCancel ? (
            cancelButton
          ) : (
            <Link routeName="Home" web={cancelLinkWeb}>
              {cancelButton}
            </Link>
          )}
        </CancelButtonContainer>
      )}
    </ButtonsContainer>
  );
};

export default React.memo(
  FormFooterButtons,
  (prev, next) =>
    prev.disabled === next.disabled &&
    prev.isSubmitting === next.isSubmitting &&
    prev.onSubmit === next.onSubmit,
);
