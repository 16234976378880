/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { dequal } from 'dequal';
import fbt from 'fbt';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { Mutable, PartialDeep } from 'type-fest';

import ControlledTaggedTagsBox from '@/ControlledTaggedTagsBox';
import {
  ControlledTaggedTags,
  TaggedTags,
} from '@/ControlledTaggedTagsBox/ControlledTaggedTagsBox';
import FormFieldBox from '@/FormFieldBox';
import FormFooterButtons from '@/FormFooterButtons';
import ModalCard from '@/ModalCard';
import Textarea from '@/Textarea';
import TextInput from '@/TextInput';
import { formError } from '#analytics/app/form';
import { contentEdited, postCreated } from '#analytics/app/post';
import getBasicPostProps from '#analytics/helpers/getBasicPostProps';
import useFbt from '#hooks/useFbt';
import { TagItem } from '#interfaces';
import { createEditorState } from '#lib/draft-js/converter';
import ds from '#lib/draft-js/dataSets';
import { PostForm_post, PostForm_post$key } from '~/PostForm_post.graphql';

import createPost from './createPost';
import { FormValues, getUserTaggedAction } from './helpers';
import updatePost from './updatePost';
import { schema } from './z';

const postFragment = graphql`
  fragment PostForm_post on Post
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    id
    version
    title
    content
    createdByUserId
    userTaggedTags(userId: $viewerId) {
      id
      tags {
        id
        label
        slug
      }
    }
    meta {
      key
      value
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewerId: string;
  post?: PostForm_post$key | null;
  visible: boolean;
  onDismiss?: () => void;
};

const PostForm = ({
  viewerId,
  post,
  visible = false,
  onDismiss,
}: Props): React.ReactElement => {
  const environment = useRelayEnvironment();
  const postData = useFragment(postFragment, post ?? null);

  useFbt();

  const postDataRef = React.useRef<PartialDeep<Mutable<PostForm_post>>>({
    ...postData,
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const s = React.useMemo(
    () =>
      StyleSheet.create({
        textarea: { minHeight: 136 },
      }),
    [],
  );
  const defaultValues = React.useMemo(
    () => ({
      ...postData,
      title: postData?.title ?? '',
      content: createEditorState(postData?.content),
      tags: {
        selectedTags: (postData?.userTaggedTags?.tags as TagItem[]) ?? [],
      },
    }),
    [postData],
  );
  const {
    watch,
    control,
    register,
    setValue,
    errors,
    clearErrors,
    reset,
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues,
    resolver: zodResolver(schema),
  });
  const tags = watch('tags', {});
  const isError = !!Object.keys(errors).length;

  // update form field `selectedTags` value
  const handleTagChange = React.useCallback(
    (state: ControlledTaggedTags) => {
      if (!dequal(tags?.selectedTags, state.selectedTags))
        setValue('tags.selectedTags', state.selectedTags);
    },
    [setValue, tags?.selectedTags],
  );

  const handleCancel = React.useCallback(() => {
    reset(defaultValues);
    onDismiss?.();
  }, [defaultValues, onDismiss, reset]);

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      if (environment) {
        setIsSubmitting(true);
        if (!postData?.id) {
          createPost(
            environment,
            viewerId,
            values,
            async (res) => {
              if (postDataRef.current && res.postCreateOne?.record) {
                postDataRef.current.title = res.postCreateOne.record.title;
                postDataRef.current.content =
                  res.postCreateOne?.record?.content;
              }

              const basicPostProps = getBasicPostProps(
                res.postCreateOne?.record?.meta,
              );

              if (basicPostProps) await postCreated(basicPostProps);

              setIsSubmitting(false);
              handleCancel();
            },
            async (err?: Error) => {
              await formError({
                formName: 'postForm',
                formStage: 'createPost',
                errors: `${err?.name}: ${err?.message}`,
              });
              setIsSubmitting(false);
            },
          );
        } else if (postDataRef.current.id) {
          const currentTagCount =
            postDataRef.current?.userTaggedTags?.tags?.length;
          const newTagCount = values.tags?.selectedTags?.length;
          const userTaggedAction = getUserTaggedAction(
            currentTagCount,
            newTagCount,
          );
          updatePost(
            environment,
            viewerId,
            values,
            postDataRef.current as PostForm_post,
            userTaggedAction,
            async (res) => {
              if (postDataRef.current && res.postUpdateById?.record) {
                postDataRef.current.title = res.postUpdateById.record.title;
                postDataRef.current.content =
                  res.postUpdateById?.record?.content;
              }

              await contentEdited();
              reset(values);
              setIsSubmitting(false);
              onDismiss?.();
            },
            async (err?: Error) => {
              await formError({
                formName: 'postForm',
                formStage: 'editContent',
                errors: [err?.name, err?.message],
              });
              formError({ formName: 'postForm' });
              setIsSubmitting(false);
            },
          );
        }
      }
    },
    [handleCancel, onDismiss, postData?.id, environment, reset, viewerId],
  );

  const formTitle = postData?.id
    ? fbt('Edit post', 'field label')
    : fbt('Create new post', 'field label');

  React.useEffect(() => {
    register('id');
    register('createdByUserId');
    register('tags.selectedTags');
    register('tags.suggestedTags');

    return () => {
      if (Object.keys(errors).length) clearErrors();
    };
  }, [clearErrors, errors, register]);

  React.useEffect(() => {
    if (
      postData &&
      postDataRef.current &&
      !dequal(
        postDataRef.current.userTaggedTags?.tags,
        postData.userTaggedTags?.tags,
      )
    )
      postDataRef.current.userTaggedTags = postData.userTaggedTags;
  }, [postData]);

  return (
    <ModalCard
      visible={visible}
      title={formTitle}
      width="750px"
      maxHeight="100%"
      onDismiss={handleCancel}
    >
      <FormFieldBox
        label={fbt('Title', 'field label')}
        error={errors.title && <ErrorMessage name="title" errors={errors} />}
      >
        <Controller
          control={control}
          name="title"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              autoFocus
              placeholder={fbt('Awesome post topic...', 'input placeholder')}
              value={value}
              onChangeText={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </FormFieldBox>
      <FormFieldBox
        label={fbt('Content', 'field label')}
        error={
          errors.content && <ErrorMessage name="content" errors={errors} />
        }
      >
        <Controller
          control={control}
          name="content"
          render={({ value, onChange, onBlur }) => (
            <Textarea
              dataSet={ds.Editor}
              showToolbar
              placeholder={fbt(
                'Write something...',
                'post content placeholder',
              )}
              editorState={value}
              onChange={onChange}
              onBlur={onBlur}
              style={s.textarea}
            />
          )}
        />
      </FormFieldBox>
      <FormFieldBox label={fbt('Tags', 'field label')}>
        <ControlledTaggedTagsBox
          canCreateNewTag
          selectedTagsLimit={10}
          initSelectedTags={postData?.userTaggedTags?.tags as TaggedTags}
          suggestedTags={tags?.suggestedTags}
          onTagChange={handleTagChange}
        />
      </FormFieldBox>
      <FormFooterButtons
        submitText={fbt('Submit', 'button label')}
        cancelText={fbt('Discard', 'button labl')}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={handleCancel}
        disabled={isError}
        isSubmitting={isSubmitting}
      />
    </ModalCard>
  );
};

export default PostForm;
