import React from 'react';

import Document from '@/Document';
import Paragraph from '@/DocumentParagraph';
import HeaderText from '@/HeaderText';
import Link from '@/Link';
import List, { ListText } from '@/List/List';

type Props = {
  noDocMargin?: boolean;
};

const TermsOfService = ({ noDocMargin }: Props): React.ReactElement => (
  <Document>
    <HeaderText size={2} mt={noDocMargin ? 0 : undefined}>
      Terms of Service
    </HeaderText>
    <Paragraph>
      These Terms of Service govern your use of the website located at{' '}
      <Link routeName="Home" web={{ path: '/', as: 'https://lpaydat.com' }}>
        https://lpaydat.com
      </Link>{' '}
      and any related services provided by Lpaydat.com.
    </Paragraph>
    <Paragraph>
      By accessing{' '}
      <Link routeName="Home" web={{ path: '/', as: 'https://lpaydat.com' }}>
        https://lpaydat.com
      </Link>
      , you agree to abide by these Terms of Service and to comply with all
      applicable laws and regulations. If you do not agree with these Terms of
      Service, you are prohibited from using or accessing this website or using
      any other services provided by Lpaydat.com.
    </Paragraph>
    <Paragraph>
      We, Lpaydat.com, reserve the right to review and amend any of these Terms
      of Service at our sole discretion. Upon doing so, we will update this
      page. Any changes to these Terms of Service will take effect immediately
      from the date of publication.
    </Paragraph>
    <Paragraph>
      These Terms of Service were last updated on 13 June 2021.
    </Paragraph>
    <HeaderText size={3}>Limitations of Use</HeaderText>
    <Paragraph>
      By using this website, you warrant on behalf of yourself, your users, and
      other parties you represent that you will not:
    </Paragraph>
    <List isOrdered>
      <ListText>
        modify, copy, prepare derivative works of, decompile, or reverse
        engineer any materials and software contained on this website;
      </ListText>
      <ListText>
        remove any copyright or other proprietary notations from any materials
        and software on this website;
      </ListText>
      <ListText>
        transfer the materials to another person or “mirror” the materials on
        any other server;
      </ListText>
      <ListText>
        knowingly or negligently use this website or any of its associated
        services in a way that abuses or disrupts our networks or any other
        service Lpaydat.com provides;
      </ListText>
      <ListText>
        use this website or its associated services to transmit or publish any
        harassing, indecent, obscene, fraudulent, or unlawful material;
      </ListText>
      <ListText>
        use this website or its associated services in violation of any
        applicable laws or regulations;
      </ListText>
      <ListText>
        use this website in conjunction with sending unauthorized advertising or
        spam;
      </ListText>
      <ListText>
        harvest, collect, or gather user data without the user’s consent; or
      </ListText>
      <ListText>
        use this website or its associated services in such a way that may
        infringe the privacy, intellectual property rights, or other rights of
        third parties.
      </ListText>
    </List>
    <HeaderText size={3}>Intellectual Property</HeaderText>
    <Paragraph>
      The intellectual property in the materials contained in this website are
      owned by or licensed to Lpaydat.com and are protected by applicable
      copyright and trademark law. We grant our users permission to download one
      copy of the materials for personal, non-commercial transitory use.
    </Paragraph>
    <Paragraph>
      This constitutes the grant of a license, not a transfer of title. This
      license shall automatically terminate if you violate any of these
      restrictions or the Terms of Service, and may be terminated by Lpaydat.com
      at any time.
    </Paragraph>
    <HeaderText size={3}>User-Generated Content</HeaderText>
    <Paragraph>
      You retain your intellectual property ownership rights over content you
      submit to us for publication on our website. We will never claim ownership
      of your content, but we do require a license from you in order to use it.
    </Paragraph>
    <Paragraph>
      When you use our website or its associated services to post, upload,
      share, or otherwise transmit content covered by intellectual property
      rights, you grant to us a non-exclusive, royalty-free, transferable,
      sub-licensable, worldwide license to use, distribute, modify, run, copy,
      publicly display, translate, or otherwise create derivative works of your
      content in a manner that is consistent with your privacy preferences and
      our Privacy Policy.
    </Paragraph>
    <Paragraph>
      The license you grant us can be terminated at any time by deleting your
      content or account. However, to the extent that we (or our partners) have
      used your content in connection with commercial or sponsored content, the
      license will continue until the relevant commercial or post has been
      discontinued by us.
    </Paragraph>
    <Paragraph>
      You give us permission to use your username and other identifying
      information associated with your account in a manner that is consistent
      with your privacy preferences, and our Privacy Policy.
    </Paragraph>
    <HeaderText size={3}>Liability</HeaderText>
    <Paragraph>
      Our website and the materials on our website are provided on an &apos;as
      is&apos; basis. To the extent permitted by law, Lpaydat.com makes no
      warranties, expressed or implied, and hereby disclaims and negates all
      other warranties including, without limitation, implied warranties or
      conditions of merchantability, fitness for a particular purpose, or
      non-infringement of intellectual property, or other violation of rights.
    </Paragraph>
    <Paragraph>
      In no event shall Lpaydat.com or its suppliers be liable for any
      consequential loss suffered or incurred by you or any third party arising
      from the use or inability to use this website or the materials on this
      website, even if Lpaydat.com or an authorized representative has been
      notified, orally or in writing, of the possibility of such damage.
    </Paragraph>
    <Paragraph>
      In the context of this agreement, &ldquo;consequential loss&rdquo;
      includes any consequential loss, indirect loss, real or anticipated loss
      of profit, loss of benefit, loss of revenue, loss of business, loss of
      goodwill, loss of opportunity, loss of savings, loss of reputation, loss
      of use and/or loss or corruption of data, whether under statute, contract,
      equity, tort (including negligence), indemnity, or otherwise.
    </Paragraph>
    <Paragraph>
      Because some jurisdictions do not allow limitations on implied warranties,
      or limitations of liability for consequential or incidental damages, these
      limitations may not apply to you.
    </Paragraph>
    <HeaderText size={3}>Accuracy of Materials</HeaderText>
    <Paragraph>
      The materials appearing on our website are not comprehensive and are for
      general information purposes only. Lpaydat.com does not warrant or make
      any representations concerning the accuracy, likely results, or
      reliability of the use of the materials on this website, or otherwise
      relating to such materials or on any resources linked to this website.
    </Paragraph>
    <HeaderText size={3}>Links</HeaderText>
    <Paragraph>
      Lpaydat.com has not reviewed all of the sites linked to its website and is
      not responsible for the contents of any such linked site. The inclusion of
      any link does not imply endorsement, approval, or control by Lpaydat.com
      of the site. Use of any such linked site is at your own risk and we
      strongly advise you make your own investigations with respect to the
      suitability of those sites.
    </Paragraph>
    <HeaderText size={3}>Right to Terminate</HeaderText>
    <Paragraph>
      We may suspend or terminate your right to use our website and terminate
      these Terms of Service immediately upon written notice to you for any
      breach of these Terms of Service.
    </Paragraph>
    <HeaderText size={3}>Severance</HeaderText>
    <Paragraph>
      Any term of these Terms of Service which is wholly or partially void or
      unenforceable is severed to the extent that it is void or unenforceable.
      The validity of the remainder of these Terms of Service is not affected.
    </Paragraph>
    <HeaderText size={3}>Governing Law</HeaderText>
    <Paragraph>
      These Terms of Service are governed by and construed in accordance with
      the laws of Thailand. You irrevocably submit to the exclusive jurisdiction
      of the courts in that State or location.
    </Paragraph>
  </Document>
);

export default TermsOfService;
