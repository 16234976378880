import { MaterialIcons } from '@expo/vector-icons';
import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import Button from '@/Button';
import PostForm from '@/PostForm';
import useFbt from '#hooks/useFbt';
import useHeaderIconSize from '#hooks/useHeaderIconSize';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import { CreateNewPostButton_viewer$key } from '~/CreateNewPostButton_viewer.graphql';

const viewerFragment = graphql`
  fragment CreateNewPostButton_viewer on User {
    id
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CreateNewPostButton_viewer$key;
};

const CreateNewPostButton = ({ viewer }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const [, { xs, sm }] = useResponsive();
  const iconSize = useHeaderIconSize();
  const [isFormOpen, setIsFormOpen] = React.useState(false);
  const { colors } = useTheme();

  const openForm = React.useCallback(() => setIsFormOpen(true), []);
  const closeForm = React.useCallback(() => setIsFormOpen(false), []);

  return (
    <>
      <PostForm
        viewerId={viewerData.id}
        post={null}
        visible={isFormOpen}
        onDismiss={closeForm}
      />
      <Button
        compact
        icon={() => (
          <MaterialIcons
            name="post-add"
            size={iconSize + 2}
            color={colors?.accent}
          />
        )}
        uppercase={false}
        onPress={openForm}
        color={colors?.accent}
      >
        {xs && ''}
        {sm && <fbt desc="button label">Post</fbt>}
        {!xs && !sm && <fbt desc="button label">New Post</fbt>}
      </Button>
    </>
  );
};

export default React.memo(
  CreateNewPostButton,
  (prev, next) => prev.viewer[' $data']?.id === next.viewer[' $data']?.id,
);
