/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { space, SpaceProps } from '#styled-system';

export const Header = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.25px;
`;

export const HeaderContainer = styled.View<SpaceProps>`
  display: flex;
  ${space};
`;

export default null;
