/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
`;

export const ItemContainer = styled.Pressable`
  flex: 9 7 auto;
  flex-direction: row;
  padding: 12px 8px;
`;

export const IconContainer = styled.View`
  flex: 1 0 auto;
`;

export const ContentContainer = styled.View`
  flex: 9 0 auto;
  flex-direction: column;
`;

export const MainContentContainer = styled.View`
  flex: 2 0 auto;
  margin: auto 4px;
`;

export const SubContentContainer = styled.View`
  flex: 1 0 auto;
`;

export const Label = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
  font-size: 14px;
`;

export const Description = styled(Text)`
  font-size: 11px;
`;

export const ActionsContainer = styled.View`
  flex: 3 0 auto;
  margin-end: 8px;
  margin-vertical: auto;
`;
