import { Environment } from 'react-relay';
import { v1 as uuidv1 } from 'uuid';

import createPreviewContent from '#database/utils/createPreviewContent';
import { ImageSize, MetaKey, ObjectType } from '#enum';
import {
  convertEditorStateToJSONWithAssets,
  getPlainText,
} from '#lib/draft-js/converter';
import { ObjectOperation } from '#mutations/AddRemoveTaggedTags';
import createPost from '#mutations/CreatePost';
import updateTaggedTags from '#mutations/UpdateTaggedTags';
import { compactKVArray, removeExtraSpaces } from '#utils';
import { CreatePostMutationResponse } from '~/CreatePostMutation.graphql';

import { FormValues } from './helpers';

const createPostFn = async (
  environment: Environment,
  viewerId: string,
  values: FormValues,
  onCompleted?: (res: CreatePostMutationResponse) => void,
  onError?: (err?: Error) => void,
): Promise<void> => {
  const title = removeExtraSpaces(values.title);
  const version = uuidv1();

  if (title && values.content) {
    const [
      content,
      metaAttr,
      resource,
    ] = await convertEditorStateToJSONWithAssets(values.content, {
      imageSizes: [ImageSize.Medium, ImageSize.PX1080],
    });

    const [previewContent, previewPoints] = createPreviewContent(content);
    const meta = compactKVArray([
      ...(metaAttr ?? []),
      {
        key: MetaKey.PreviewContentPoints,
        value: previewPoints,
      },
    ]);

    createPost(
      environment,
      {
        version,
        title,
        content,
        previewContent,
        plainContent: getPlainText(values.content),
        vote: { upvotes: 1, downvotes: 0 },
        createdDate: new Date().toUTCString(),
        createdByUserId: viewerId,
        resource,
        meta,
      },
      {
        onCompleted: (res, err) => {
          onCompleted?.(res);

          if (
            res.postCreateOne?.record &&
            values.tags?.selectedTags?.length &&
            !err
          )
            updateTaggedTags(environment, {
              userId: viewerId,
              objectType: ObjectType.Post,
              objectId: res.postCreateOne.record.id,
              prevTagList: [],
              newTagList: values.tags.selectedTags,
              taggedAction: 'tagged',
              objectOperation: ObjectOperation.AddTaggedTags,
            });
        },
        onError: (err) => {
          onError?.(err);
        },
      },
    );
  }
};

export default createPostFn;
