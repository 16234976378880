import merge from 'deepmerge';
import React from 'react';
import { DefaultTheme, useTheme as useRNPTheme } from 'react-native-paper';
import { ThemeContext as StyledThemeContext } from 'styled-components/native';

import { Merge, ThemeProps } from '#interfaces';
import { ThemeContext as CustomThemeContext } from '#layers/StyleProvider';

/**
 * A hook that return an merged object of the `styled-components` theme context
 * and `react-native-paper` theme object.
 */
const useTheme = (): Merge<
  Merge<ThemeProps, typeof DefaultTheme>,
  CustomThemeContext
> => {
  const paperTheme = useRNPTheme();
  const styledTheme = React.useContext<StyledThemeContext>(StyledThemeContext);
  const themeContext = React.useContext(CustomThemeContext);

  const mergedObject = React.useMemo(
    () => merge(merge(styledTheme, paperTheme), themeContext),
    [styledTheme, paperTheme, themeContext],
  );

  return mergedObject;
};

export default useTheme;
