import * as Localization from 'expo-localization';
import { IntlViewerContext } from 'fbt';
import { Platform } from 'react-native';

import { AvailableLocale, FetchedTranslation } from '#interfaces';
import { Fbt } from '#storage';

import locales from '../locales';
import fetchTranslation from './fetchTranslation';
import findBestAvailableLocale from './findBestAvailableLocale';
import setAppLocale from './setAppLocale';

/**
 * A function that do everything to make `fbt` work!
 *
 * It also fetch target translations from server if it do not have already
 * or if it outdated and store it in the `AsyncStorage`.
 *
 * This function should use to change the App locale at runtime.
 *
 * _NOTE: This function cannot use without a proper `fbtInit` call
 * because it is a promise function._
 *
 * @param {AvailableLocale} locale target locale you need to change to
 * @returns {AvailableLocale} the final locale that was setted to `fbt`
 */
const changeLocale = async (
  locale?: AvailableLocale,
): Promise<AvailableLocale> => {
  const fbtData = await Fbt.getFbtData();
  const { selectedLocale: storedLocale } = fbtData ?? {};
  let { translations } = fbtData ?? {};

  const preferredLocale: AvailableLocale =
    findBestAvailableLocale([
      locale ?? 'en_US',
      storedLocale ?? 'en_US',
      IntlViewerContext?.locale,
      ...Localization.locale,
    ]) ?? 'en_US';
  const selectedLocale = locales[preferredLocale];

  const fetchedTranslation: FetchedTranslation = await fetchTranslation(
    preferredLocale,
  );

  if (fetchedTranslation?.translations && fetchedTranslation?.version) {
    translations = {
      ...translations,
      [preferredLocale]: fetchedTranslation.translations,
    };

    Fbt.updateTranslations(preferredLocale, fetchedTranslation);
  }

  const doReload =
    (Platform.OS === 'android' || Platform.OS === 'ios') &&
    storedLocale &&
    locales[storedLocale]?.rtl !== selectedLocale?.rtl;

  setAppLocale(preferredLocale, translations, !!doReload);

  return preferredLocale;
};

export default changeLocale;
