import React from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  TextInputFocusEventData,
  TextInputKeyPressEventData,
} from 'react-native';

import ItemList from '@/ItemList';
import TextInput from '@/TextInput';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import { getObjectJoinString } from '#utils';

import { Container, SearchResultsContainer } from './SearchBox.style';

type Props = React.ComponentProps<typeof ItemList> &
  React.ComponentProps<typeof TextInput> & {
    disabled?: boolean;
    initFocus?: boolean;
  };

const SearchBox = ({
  disabled,
  editable,
  items,
  initFocus,
  onItemPress,
  getLinkProps,
  value,
  onFocus,
  onBlur,
  ...props
}: Props): React.ReactElement => {
  const [r] = useResponsive();
  const { colors, isDarkTheme } = useTheme();
  const [isFocused, setIsFocused] = React.useState(!disabled && initFocus);
  const [focusedItemIndex, setFocusedItemIndex] = React.useState(0);

  const boxShadowStyle =
    isDarkTheme || Platform.OS === 'android' ? {} : colors?.floatBox;
  const disableSearch = disabled ?? editable === false;

  const handleFocus = React.useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (!disableSearch) {
        onFocus?.(e);
        setIsFocused(true);
      }
    },
    [disableSearch, onFocus],
  );

  const handleBlur = React.useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onBlur?.(e);
      setTimeout(() => setIsFocused(false), 75);
    },
    [onBlur],
  );

  const handleKeyPress = React.useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (items?.length) {
        const lastIndex = (items?.length ?? 0) - 1;
        if (e.nativeEvent.key === 'ArrowDown') {
          const newIndex = focusedItemIndex + 1;
          setFocusedItemIndex(newIndex > lastIndex ? 0 : newIndex);
        }
        if (e.nativeEvent.key === 'ArrowUp') {
          const newIndex = focusedItemIndex - 1;
          setFocusedItemIndex(newIndex < 0 ? lastIndex : newIndex);
        }
      }
    },
    [focusedItemIndex, items?.length],
  );

  const handleSubmitEditing = React.useCallback(() => {
    const selectedItem = items?.[focusedItemIndex];
    if (selectedItem && !disableSearch) onItemPress?.(selectedItem);
  }, [disableSearch, focusedItemIndex, items, onItemPress]);

  React.useEffect(() => {
    if (!items?.length && !disableSearch) setFocusedItemIndex(0);
  }, [disableSearch, items?.length]);

  return (
    <Container maxWidth={r(['100%', '325px'])}>
      <TextInput
        {...props}
        editable={!disableSearch}
        value={value}
        focus={isFocused}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onSubmitEditing={handleSubmitEditing}
      />
      {isFocused && !disableSearch && value && items?.length ? (
        <SearchResultsContainer elevation={2} {...boxShadowStyle}>
          <ItemList
            items={items}
            onItemPress={onItemPress}
            getLinkProps={getLinkProps}
          />
        </SearchResultsContainer>
      ) : null}
    </Container>
  );
};

export default React.memo(
  SearchBox,
  (prev, next) =>
    prev.value === next.value &&
    prev.disabled === next.disabled &&
    prev.editable === next.editable &&
    prev.bg === next.bg &&
    prev.backgroundColor === next.backgroundColor &&
    prev.borderColor === next.borderColor &&
    getObjectJoinString(prev.items, ['id']) ===
      getObjectJoinString(next.items, ['id']),
);
